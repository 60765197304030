import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updatePlaying } from '../../redux/modules/uiReducer';
import { logStatus } from '../../redux/modules/userReducer';
import styles from './StartButton.module.scss';

class StartButton extends Component {
  componentDidMount() {
    const { updatePlaying, logStatus, device } = this.props;
    if (device.usesPairing || device.isFiretv) {
      updatePlaying(true);
      const statusMess = { code: 'playBackStarted', shouldListen: true };
      logStatus(statusMess);
    }
  }
  handleClick() {
    const { updatePlaying, logStatus } = this.props;

    updatePlaying(true);
    const statusMess = { code: 'playBackStarted', shouldListen: true };
    logStatus(statusMess);
  }

  render() {
    const { style = {}, width = '100%', viewBox = '0 0 210 210', isPlaying } = this.props;

    return (
      <div onClick={() => this.handleClick()}>
        <svg
          width={width}
          style={style}
          height={width}
          viewBox={viewBox}
          xmlns="http://www.w3.org/2000/svg"
          className={styles.startButton}
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <g id="play_large" transform="translate(-425 -52)">
            <g id="Group_1670" data-name="Group 1670" transform="translate(425 52)">
              <g id="Ellipse_312" className={styles.clsOne} data-name="Ellipse 312">
                <circle cx="105" cy="105" r="105" className={styles.clsThree} />
                <circle cx="105" cy="105" r="104.5" className={styles.clsFour} />
              </g>
            </g>
            {!isPlaying && (
              <g id="triangle" transform="translate(508.891 120.701)">
                <path id="Path_1281" d="M90.115 36.076L27.8.5v70.9z" className={styles.clsTwo} data-name="Path 1281" transform="translate(-27.8 -.5)" />
              </g>
            )}
            {isPlaying && (
              <g id="pause" transform="translate(-237.423 -394.203)">
                <g id="lines" transform="translate(244.453 403.108)">
                  <g id="lines-2" data-name="lines" transform="translate(-5.53 -7.405)">
                    <g id="Group_1049" data-name="Group 1049">
                      <g id="Group_958" data-name="Group 958">
                        <path id="Rectangle_1928" d="M0 0h10.087v37.203H0z" className={styles.clsTwo} data-name="Rectangle 1928" />
                      </g>
                      <path
                        id="Rectangle_1929"
                        d="M0 0h10.087v37.203H0z"
                        className={styles.clsTwo}
                        data-name="Rectangle 1929"
                        transform="translate(17.797 .136)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            )}
          </g>
        </svg>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  bookmarkPos: state.cueItems.bookmarkPos,
  isPlaying: state.ui.isPlaying,
  device: state.device,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updatePlaying,
      logStatus,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StartButton);
