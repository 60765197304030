import React, { Component } from 'react';

import { Branding, Button } from '../../components';
import remote from '../../assets/images/fiosremote.svg';
import styles from './FirstPlay.module.scss';

class FirstPlay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isButtonClicked: false,
    };
    this.handleKey = this.handleKey.bind(this);
  }
  componentDidMount() {
    window.addEventListener('keydown', this.handleKey);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKey);
  }

  handleKey(e) {
    switch (e.key) {
      case 'Enter':
        this.setState({ isButtonClicked: true }, () => {
          setTimeout(() => {
            this.handleContinue();
          }, 500);
        });

        break;
      default:
        break;
    }
  }

  handleContinue() {
    const { history, match } = this.props;
    console.log(match);
    history.push(`/mediascape/${match.params.resourceid}`);
  }

  render() {
    return (
      <div className={styles.firstPlay}>
        <Branding />
        <div className={styles.textBlock}>
          Read the story out loud with your fios remote microphone.
          <br />
          Speech bubbles will appear throughout the story when it’s your turn to talk.
        </div>

        <div className={styles.textContainer}>
          <div className={styles.readingWords}>This is what the speech bubble looks like.</div>
        </div>

        <div className={styles.textBlock}>The light at the top of your fios remote indicates that the mic is on. </div>

        <div>
          <img className={styles.remoteImage} src={remote} alt="fios remote" />
        </div>

        <div>
          <Button raised={this.state.isButtonClicked} theme="primary" inverse={true} onClick={() => this.handleContinue()}>
            CONTINUE
          </Button>
        </div>
      </div>
    );
  }
}

export default FirstPlay;
