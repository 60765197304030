import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { updateAudioItems } from '../../redux/modules/resourcesReducer';
import { updateCueAudioItems } from '../../redux/modules/cueReducer';
import { AudioTools } from '../../helpers';
import { AUDIO_EVENTS } from '../../constants';
import styles from './AudioManager.module.scss';

class AudioManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audioItems: [],
      areAudioCueItemsReady: false,
      isAudioPreloaded: false,
    };

    this.audioBuffers = null;
    this.audioCueItems = null;
    this.audioController = null;
    this.mediascapeItemsFromBookmark = this.mediascapeItemsFromBookmark.bind(this);
    this.updateAudioEvent = this.updateAudioEvent.bind(this);
  }

  componentDidMount() {
    this.audioController = new AudioTools();
  }
  componentWillUnmount() {
    this.audioController.stopAllAudio();
    this.audioController = null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { volumeLevel, cueItems, resources } = this.props;

    if (volumeLevel !== prevProps.volumeLevel) {
      const newLevel = volumeLevel * 0.01;
      if (this.audioCueItems) {
        this.audioCueItems.forEach(item => {
          if (item.isPlaying) {
            item.volume = newLevel;
            this.audioController.setVolume(item, newLevel);
          }
        });
      }
    }

    if (resources.audioItems && Object.keys(resources.audioItems).length > 0 && !this.state.isAudioPreloaded) {
      const audioFiles = { ...resources.audioItems };
      this.setState({ isAudioPreloaded: true }, this.setupAudioPreloading(audioFiles, cueItems.audioItems));
    }

    // if (resources.audioItems && Object.keys(resources.audioItems).length > 0 && cueItems.audioItems.length > 0) {
    //   if (!this.state.areAudioCueItemsReady) {
    //     const audioFiles = { ...resources.audioItems };
    //   }
    // }
  }

  componentWillReceiveProps(nextProps) {
    const { cueItems, shouldListen } = this.props;

    if (cueItems.bookmarkPos !== nextProps.cueItems.bookmarkPos && shouldListen) {
      this.mediascapeItemsFromBookmark(nextProps.cueItems.bookmarkPos);
    }

    if (!nextProps.shouldListen && shouldListen) {
      if (cueItems.audioItems) {
        cueItems.audioItems.forEach(item => {
          item.fade_out = 0.5;
          this.updateAudioEvent(item, false);
        });
      }
    }
  }

  setupAudioPreloading(audioFiles, audioCues) {
    this.audioController.preLoadAllAudio(audioFiles).then(updatedAudio => {
      Promise.all(updatedAudio).then(values => {
        let audioResourcesWithBuffers = {};
        values.forEach(item => {
          audioResourcesWithBuffers[item.filename] = { ...item };
        });

        this.audioBuffers = { ...audioResourcesWithBuffers };
      });

      this.audioCueItems = this.setupAudioCueItems(audioCues, audioFiles);
    });
  }

  setupAudioCueItems(audioItems, audioFiles) {
    const { resources, volumeLevel } = this.props;
    const audioCueItems = audioItems.map(cueItem => {
      const cueAudioId = cueItem.event_data ? cueItem.event_data : cueItem.name;
      if (audioFiles[cueAudioId]) {
        const readyForAudio = {
          id: cueAudioId,
          path: resources.audioItems[cueAudioId].downloadPath,
          isLoop: !!cueItem.isLoop,
          isRangedCue: !!cueItem.isRangedCue,
          fadeIn: parseFloat(cueItem.fade_in),
          fadeOut: parseFloat(cueItem.fade_out),
          start: parseInt(cueItem.start),
          stop: parseInt(cueItem.stop),
          volume: volumeLevel * 0.01,
          isPlaying: false,
          type: cueItem.type,
        };
        return readyForAudio;
      } else {
        return cueItem;
      }
    });
    this.setState({ areAudioCueItemsReady: true });
    return audioCueItems;
  }

  updateAudioEvent(cue, shouldPlay, buffer = null) {
    const { cueItems, updateCueAudioItems, volumeLevel } = this.props;

    const index = cueItems.audioItems.findIndex(x => x.event_data === cue.id || x.name === cue.id);
    cue.volume = volumeLevel * 0.01;
    if (shouldPlay) {
      if (cueItems.audioItems[index].status !== AUDIO_EVENTS.PLAYING) {
        if (buffer || cue.audioSource) {
          this.audioController.playPreBufferedTrack(cue, buffer);
        } else {
          this.audioController.playTrack(cue);
        }
        if (cueItems.audioItems[index]) {
          cueItems.audioItems[index].status = AUDIO_EVENTS.PLAYING;
        }
      }
    } else {
      if (cueItems.audioItems[index] && (cueItems.audioItems[index].isLoop || cueItems.audioItems[index].isRangedCue)) {
        this.audioController.fadeOutAudio(cue);
      }
      if (cueItems.audioItems[index]) {
        cueItems.audioItems[index].status = AUDIO_EVENTS.STOP;
      }
    }
    const payload = {
      audioItems: cueItems.audioItems,
    };

    updateCueAudioItems(payload);
  }

  mediascapeItemsFromBookmark(bookmarkPos) {
    let playQueue = [];
    let stopQueue = [];

    if (this.audioCueItems) {
      this.audioCueItems.forEach(item => {
        const startVal = item.start;
        const stopVal = item.stop;
        if (item.path) {
          if (!item.isPlaying && startVal <= bookmarkPos && stopVal > bookmarkPos) {
            playQueue.push(item);
            item.isPlaying = true;
          } else if (stopVal < bookmarkPos || bookmarkPos < startVal) {
            stopQueue.push(item);
            item.isPlaying = false;
          }
        }
      });
    }

    playQueue.forEach(item => {
      const tempAudioBuffers = Object.assign({ ...this.audioBuffers });
      const immutableAudioBuffers = Map(tempAudioBuffers);

      let bufferItem = immutableAudioBuffers.get(item.id);
      // let bufferItem = tempAudioBuffers[item.id];
      try {
        const buffer = bufferItem.buffer;

        this.updateAudioEvent(item, true, buffer);
      } catch (err) {
        console.warn(err);
      }
    });

    stopQueue.forEach(item => {
      this.updateAudioEvent(item, false);
    });
  }

  render() {
    return <div className={styles.audioManager} />;
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateAudioItems,
      updateCueAudioItems,
    },
    dispatch,
  );

const mapStateToProps = state => ({
  resources: state.resources,
  cueItems: state.cueItems,
  volumeLevel: state.ui.volumeLevel,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudioManager);
