export const PAIR_GENERATE_CODE = 'pair/GENERATE_PAIR_CODE';
export const PAIR_LOCALSTORAGE_FETCH = 'pair/PAIR_LOCALSTORAGE_FETCH';
export const PAIR_LOCALSTORAGE_SAVE = 'pair/PAIR_LOCALSTORAGE_SAVE';
export const PAIR_LOCALSTORAGE_ERROR = 'pair/PAIR_LOCALSTORAGE_ERROR';
export const PAIR_FIREBASE_CODE_REMOVED = 'pair/PAIR_FIREBASE_CODE_REMOVED';
export const SET_SESSION_ID = 'pair/SET_SESSION_ID';
export const SET_SESSION_STATUS = 'pair/SET_SESSION_STATUS';

export const STATUS_UPDATING = 'status/STATUS_UPDATING';
export const STATUS_SAVED = 'status/STATUS_SAVED';
export const STATUS_ERROR = 'status/STATUS_ERROR';

export const EVENT_UPDATING = 'event/EVENT_UPDATING';
export const EVENT_SET = 'event/EVENT_SET';
export const EVENT_ERROR = 'event/EVENT_ERROR';
