export const STORAGE = {
  urlBase: process.env.REACT_APP_FIREBASE_STORAGE_URLBASE,
  fullPath: `${process.env.REACT_APP_FIREBASE_STORAGE_URLBASE}/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/unity%2F`,
  partialPath: `${process.env.REACT_APP_FIREBASE_STORAGE_URLBASE}/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/`,
  nfxMediaPath: `${process.env.REACT_APP_FIREBASE_STORAGE_URLBASE}/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/nfxMedia%2Fv1%2Fen-us%2F`,

  packagedMediaPath: `${process.env.REACT_APP_FIREBASE_STORAGE_URLBASE}/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/packagedMedia%2Fv1%2Fen-us%2F`,

  cfPath: 'https://d204mhn7hn8u4n.cloudfront.net/',
  s3Path: 'https://s3.amazonaws.com/storage.noveleffect.com/mediascapes/',
  StbS3Path: 'https://s3.amazonaws.com/stb-storage/mediascapes/',
  delimiter: '%2F',
  mediaType: '.mp4?alt=media',
  webmFile: '.webm?alt=media',
  wavFile: '.mp3?alt=media',
  mp3File: '.mp3?alt=media',
  cfFile: '.mp4',
};
