export const AUDIO_EVENTS = {
  FADEIN: 'fadein',
  FADEOUT: 'fadeout',
  PAUSE: 'pause',
  PENDING: 'pending',
  PLAY: 'play',
  PLAYED: 'played',
  PLAYING: 'playing',
  READY: 'ready',
  STOP: 'stop',
  STOPPING: 'stopping',
};

export const VIDEO_EVENTS = {
  IDLE: 'idle',
  PENDING: 'pending',
  PLAYING: 'playing',
  READY: 'ready',
  STOPPED: 'stopped',
  STOPPING: 'stopping',
};

export const MESSAGE_TYPES = {
  ERROR: 'error',
  GENERAL: 'general',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
};
