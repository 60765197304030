import initialState from './initialState';
import * as types from './actionTypes';

export default (state = initialState, action) => {
  switch (action.type) {
  case types.UPDATE_DEVICE:
    return {
      ...state,
      currentDevice: action.payload.device ? action.payload.device : null,
      usesPairing: action.payload.usesPairing ? action.payload.usesPairing : false,
      usesCloudASR: action.payload.usesCloudASR ? action.payload.usesCloudASR : false,
      isVZSTB: action.payload.device
        ? action.payload.device.toLowerCase().includes('vms4100') || action.payload.device.toLowerCase().includes('ipc4100')
        : false,
      isFiretv: action.payload.isFiretv
    };
  case types.UPDATE_APP_ASR:
    return {
      ...state,
      usesAppASR: action.payload ? action.payload : false
    };
  default:
    return state;
  }
};
