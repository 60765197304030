import React, { Component } from 'react';
import { connect } from 'react-redux';
import { padStart } from '../../helpers';
import { VideoPlayer } from '../index';
import styles from './VideoController.module.scss';

class VideoController extends Component {
  constructor(props) {
    super(props);
    this.vcWapper = React.createRef();
    let urlParams = new URLSearchParams(window.location.search);
    let myParam = urlParams.get('vidSize');
    this.state = {
      isPlaying: false,
      isLooping: false,
      duration: '00:00',
      disabled: true,
      currentVideoId: props.videoId,
      videoItems: [],
      videoSize: myParam ? myParam : 4,
    };
  }

  componentDidMount() {
    const { videos } = this.props;

    let vidArr = [];
    if (Object.keys(videos).length > 0) {
      //initialize video buffer

      Object.keys(videos).forEach(key => {
        let videoObj = {
          id: key,
          animPath: videos[key].ani_url,
          idlePath: videos[key].loop_url,
        };
        vidArr.push(videoObj);
      });
    }

    this.setState({
      videoItems: vidArr,
    });
  }

  render() {
    const { videoId, onVideoEnd, onVideoStart, ui } = this.props;

    const currentAnimId = this.state.currentVideoId ? this.state.currentVideoId : parseInt(videoId, 10);
    const currentVideoKey = padStart(videoId, 3);

    let activeVideos = [];

    if (this.state.videoItems.length > 0) {
      for (let index = 0; index < this.state.videoSize + currentAnimId; index++) {
        activeVideos.push(this.state.videoItems[index]);
      }
    }

    let currentSize = {
      width: ui.videoSize.width,
      height: ui.videoSize.height,
    };
    return (
      <div ref={this.vcWapper} className={styles.videoWrapper}>
        {activeVideos.map((video, index) => {
          const idVal = video ? parseInt(video.id, 10) : -1;
          const currentVal = currentVideoKey ? parseInt(currentVideoKey, 10) : -1;

          if (currentVal <= idVal || idVal >= 0) {
            return (
              <VideoPlayer
                key={index}
                currentVideoKey={currentVideoKey}
                paths={video}
                isPlaying={video.id === currentVideoKey}
                onVideoEnd={onVideoEnd}
                onVideoStart={onVideoStart}
                size={currentSize}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ui: state.ui,
});

export default connect(mapStateToProps)(VideoController);
