import initialState from './initialState';
import * as types from './actionTypes';

export default (state = initialState, action) => {
  switch (action.type) {
  case types.BOOKID_SET:
    return {
      ...state,
      bookId: action.payload
    };
  case types.BOOKID_ERROR:
    return {
      ...state
    };
  case types.STORE_RESOURCEITEMS_FROM_STORAGE:
    return {
      ...state,
      audioItems: action.payload.audioItems,
      audioStatus: 'completed',
      videoItems: action.payload.videoItems,
      videoStatus: 'paths',
      corpus: action.payload.corpus,
      mediaComplete: action.payload.mediaComplete ? action.payload.mediaComplete : false
    };

  case types.STORE_RESOURCEITEMS_FROM_FIREBASE:
    return {
      ...state,
      audioItems: action.payload.audioItems,
      audioStatus: 'completed',
      videoItems: action.payload.videoItems,
      videoStatus: 'paths',
      mediaComplete: action.payload.mediaComplete ? action.payload.mediaComplete : false
    };

  case types.STORE_CORPUS_FROM_FIREBASE:
    return {
      ...state,
      corpus: action.payload.corpus,
      asrWords: action.payload.asrWords,
      displayWords: action.payload.displayWords
    };

  case types.RESOURCE_ACTIVE_SET:
    return {
      ...state,
      resourceId: action.payload.id,
      displayTitle: action.payload.title,
      resourceImagePath: action.payload.imagePath,
      description: action.payload.description
    };
  case types.LOOPING_SET:
    return {
      ...state,
      isLooping: action.payload
    };
  case types.LOOPING_ERROR:
    return {
      ...state
    };
  case types.AUDIO_LIST_SET:
    return {
      ...state,
      audioItems: action.payload,
      audioStatus: 'completed'
    };
  case types.AUDIO_LIST_FETCH:
  case types.AUDIO_LIST_ERROR:
    return {
      ...state,
      status: 'fetching audio list',
      audioStatus: 'error'
    };
  case types.AUDIO_ITEM_UPDATING:
    return {
      ...state,
      audioItems: {
        ...state.audioItems,
        [action.payload.id]: action.payload.audioState
      }
    };
  case types.AUDIO_ITEMS_UPDATING:
    console.log('action.payload', action.payload); // eslint-disable-line no-console
    return {
      ...state,
      audioItems: action.payload
    };
  case types.AUDIO_SET_MUTE:
    return {
      ...state,
      isMuted: action.payload
    };
  case types.VIDEO_LIST_SET:
    return {
      ...state,
      videoItems: action.payload,
      videoStatus: 'paths'
    };
  case types.VIDEO_LIST_FETCH:
  case types.VIDEO_LIST_ERROR:
    return {
      ...state,
      status: 'fetching video list'
    };
  case types.VIDEO_LASTVIDEO_SET:
    return {
      ...state,
      lastVideo: action.payload
    };
  case types.VIDEO_DOWNLOAD_START:
    return {
      ...state,
      videoStatus: 'fetchingData'
    };
  case types.VIDEO_DOWNLOAD_COMPLETED:
    return {
      ...state,
      videoItems: action.payload,
      videoStatus: 'downloadCompleted'
    };

  case types.IMAGE_LIST_SET:
    return {
      ...state,
      imageItems: action.payload,
      imageStatus: 'complete'
    };

  case types.IMAGE_LIST_FETCH:
  case types.IMAGE_LIST_ERROR:
    return {
      ...state,
      status: 'fetching image list'
    };
  case types.VIDEO_CODEC_SUPPORT:
    return {
      ...state,
      codec: action.payload
    };
  case types.TEST_EVENT_UPDATE:
  case types.TEST_EVENT_COMPLETE:
  case types.TEST_EVENT_ERROR:
    return {
      ...state
    };
  case types.SESSION_STATUS_UPDATE:
    return {
      ...state,
      session: action.payload
    };
  case types.SESSION_STATUS_COMPLETE:
  case types.SESSION_STATUS_ERROR:
    return {
      ...state
    };
  case types.RESETTING_RESOURCES:
    return {
      ...initialState
    };
  default:
    return state;
  }
};
