import React, { Component } from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import NoSleep from 'nosleep.js';
import { setSessionStatus } from '../../redux/modules/userReducer';
import { DictateClass } from '../../speech';
import { textLogo, micOff, micOn } from '../../assets';
import { Loading } from '../../components';
import styles from './Voice.module.scss';

class Voice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isConnected: false,
      isListening: false,
      micActive: false
    };

    this.dictate = null;
    this.isConnected = false;

    this.wsProtocol = 'wss://';
    this.port = process.env.REACT_APP_CLOUD_ASR_PORT;
    this.speechRouterAddress = process.env.REACT_APP_CLOUD_ASR_NAME;

    this.dictateServer = `${this.wsProtocol}${this.speechRouterAddress}:${this.port}/client`;
    this.playerServer = `${this.wsProtocol}${this.speechRouterAddress}:${this.port}/player`;

    this.noSleep = new NoSleep();

    this.setupDictate = this.setupDictate.bind(this);
    this.handleReadyForSpeech = this.handleReadyForSpeech.bind(this);
    this.handleEndOfSpeech = this.handleEndOfSpeech.bind(this);
    this.handleEndOfSession = this.handleEndOfSession.bind(this);
    this.handleErrors = this.handleErrors.bind(this);
    this.handleEvents = this.handleEvents.bind(this);
    this.beforeunload = this.beforeunload.bind(this);
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.beforeunload.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeunload.bind(this));
  }

  beforeunload(e) {
    const { match, setSessionStatus } = this.props;
    e.preventDefault();
    const payload = {
      userId: match.params.userid,
      status: 'disconnected'
    };

    setSessionStatus(payload);
    e.returnValue = true;
  }

  handleClick(resourceId, ssId) {}

  fetchCoverImage(images) {
    let activeImagePath;
    Object.keys(images).forEach((key) => {
      if (images[key].type === 'square' && images[key].isActive) {
        activeImagePath = images[key].downloadURL;
      }
    });
    return activeImagePath;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { sessionId } = this.props;
    if (sessionId && prevProps.sessionId !== sessionId) {
      console.log('sessionId', sessionId);
      this.setupDictate();
      this.dictate.init();
      this.dictate.setupExternalVoice(this.dictateServer, sessionId.trim());
    }
  }

  setupDictate() {
    const { sessionId } = this.props;

    console.log('dictate setup');

    const serverPath = `${this.dictateServer}/${sessionId.trim()}`;

    const dictateConfig = {
      speakerServer: serverPath,
      onReadyForSpeech: this.handleReadyForSpeech,
      onEndOfSpeech: this.handleEndOfSpeech,
      onEndOfSession: this.handleEndOfSession,
      onError: this.handleErrors,
      onEvent: this.handleEvents,
      selectionStart: 0
    };

    // dictate = new Dictate(dictateConfig);
    this.dictate = new DictateClass(dictateConfig);
  }

  handleReadyForSpeech() {
    const { match, setSessionStatus } = this.props;
    const payload = {
      userId: match.params.userid,
      status: 'connected'
    };
    console.log('@@@@@@')
    console.log('handle ready for speech')
    console.log('set sesstion status to connected')
    console.log('@@@@@@')

    setSessionStatus(payload);
    this.isConnected = true;
    this.setState({ micActive: true });
  }

  handleEndOfSpeech() {}

  handleEndOfSession() {
    this.isConnected = false;
    this.setState({ micActive: false });
  }

  handleErrors(code, data) {
    console.warn('dictate error: ', code, data);
    this.dictate.cancel();
  }

  handleEvents(code, data) {
    console.log('dictate event: ', code, data);

    if (code === 3) {
      this.setState({ isConnected: true });
      this.dictate.startListening();
    }
  }

  keepAwake() {
    this.noSleep.enable();
  }

  render() {
    return (
      <div className={styles.voice} onClick={() => this.keepAwake()}>
        {this.state.isConnected && (
          <div className={styles.listeningMode}>
            <div className={styles.logo}>
              <img src={textLogo} alt="Novel Effect" className={styles.NFXlogo} />
            </div>
            {this.state.micActive && (
              <div className={styles.microphone}>
                <img src={micOn} alt="Microphone is active" className={styles.micImage} />
              </div>
            )}
            {!this.state.micActive && (
              <div className={styles.microphone}>
                <img src={micOff} alt="Microphone is off" className={styles.micImage} />
              </div>
            )}
            {this.state.micActive && <div>Read the story out loud!</div>}
            {!this.state.micActive && <div>Waiting for microphone to begin</div>}
          </div>
        )}
        {!this.state.isConnected && (
          <div>
            <div>Pairing device…</div>
            <div className={styles.loading}>
              <Loading />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    setSessionStatus
  },
  dispatch,
);

const enhance = compose(
  firebaseConnect(props => [
    {
      path: `webclient/session/${props.match.params.userid}/voice/sessionId`,
      storeAs: 'sessionId'
    }
  ]),
  connect(
    // Map redux state to component props
    ({ firebase: { data } }) => ({
      sessionId: data.sessionId,
    }),
    mapDispatchToProps,
  ),
);

export default enhance(Voice);
