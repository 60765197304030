import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { updateBookmarkPos } from '../../redux/modules/cueReducer';
import { updatePause, updateVolumeValue, updateSkipBack } from '../../redux/modules/uiReducer';
import { updateMutedStatus } from '../../redux/modules/speechReducer';
import styles from './PlayerControls.module.scss';

class PlayerControls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuExpanded: false,
    };

    this.previousLevel = props.volumeLevel;
    this.previousMuted = props.isMuted;
  }

  handleSkipBack() {
    const { videoItems, mediaItems, bookmarkPos, updateBookmarkPos, updateSkipBack } = this.props;
    updateSkipBack(true);
    let currentVidIndex;
    let isMediaItems = !!mediaItems;
    if (!!mediaItems && mediaItems.length === 0) {
      isMediaItems = false;
    }

    let currentMediaItems = isMediaItems ? mediaItems : videoItems;

    currentMediaItems.forEach((item, index) => {
      const nextIndex = index < currentMediaItems.length - 1 ? index + 1 : currentMediaItems.length - 1;
      if (parseInt(item.start) <= bookmarkPos && parseInt(currentMediaItems[nextIndex].start) >= bookmarkPos) {
        console.log('index', index);
        currentVidIndex = index;
      }
    });

    console.log('skip backward', currentVidIndex, currentMediaItems);
    if (currentVidIndex !== null && currentVidIndex > 0) {
      let previousVid = currentMediaItems[currentVidIndex - 1];
      updateBookmarkPos(previousVid.start);
    } else if (currentVidIndex === 0) {
      updateBookmarkPos(-1);
    }
  }

  handleSkipForward() {
    const { videoItems, mediaItems, bookmarkPos, updateBookmarkPos } = this.props;
    let currentVidIndex;

    let isMediaItems = !!mediaItems;
    if (!!mediaItems && mediaItems.length === 0) {
      isMediaItems = false;
    }

    let currentMediaItems = isMediaItems ? mediaItems : videoItems;

    console.log('skip forward');

    currentMediaItems.forEach((item, index) => {
      const nextIndex = index < currentMediaItems.length - 1 ? index + 1 : currentMediaItems.length - 1;
      if (parseInt(item.start) <= bookmarkPos && parseInt(currentMediaItems[nextIndex].start) >= bookmarkPos) {
        console.log('index', index);
        currentVidIndex = index;
      }
    });

    // check to see if currentVidIndex is available or if the end has been reached befor allowing skipping forward
    if (currentVidIndex !== null && currentVidIndex < currentMediaItems.length - 1) {
      let nextVid = currentMediaItems[currentVidIndex + 1];
      updateBookmarkPos(nextVid.start);
      setTimeout(() => {
        updateBookmarkPos(nextVid.stop - 1);
      }, 500);
    }

    if (bookmarkPos === -1) {
      const nextIndex = isMediaItems ? 0 : 1;
      let nextVid = currentMediaItems[nextIndex];
      updateBookmarkPos(nextVid.start);
      setTimeout(() => {
        updateBookmarkPos(nextVid.stop - 1);
      }, 500);
    }
  }

  togglePlayPause() {
    const { isPaused, updatePause, updateVolumeValue, volumeLevel, isMuted, updateMutedStatus } = this.props;
    const newPauseValue = !isPaused;
    updatePause(newPauseValue);

    if (newPauseValue) {
      this.previousLevel = volumeLevel;
      this.previousMuted = isMuted;
      updateVolumeValue(0);
      updateMutedStatus(true);
    } else {
      updateVolumeValue(this.previousLevel);
      updateMutedStatus(this.previousMuted);
    }
  }
  render() {
    const { isPlaying, isVideo = true, isPaused } = this.props;

    const componentProps = {
      skipClass: classNames(styles.skipButton, {
        [styles.active]: isPlaying,
      }),
      playPauseClass: classNames(styles.playPauseButton, {
        [styles.active]: isPlaying,
      }),
    };

    return (
      <div className={styles.playControls}>
        {isVideo && (
          <div className={styles.skip} onClick={() => this.handleSkipBack()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23.961" height="18.059" viewBox="0 0 23.961 18.059">
              <g id="skip" transform="rotate(180 140.776 212.934)">
                <path
                  id="Rectangle_1935"
                  d="M0 0h2.812v14.999H0z"
                  className={componentProps.skipClass}
                  data-name="Rectangle 1935"
                  transform="rotate(180 140.026 212.184)"
                />
                <g id="play" transform="rotate(180 136.107 212.154)">
                  <g id="triangle">
                    <path id="Path_1281" d="M0 7.526L13.124 0v15z" className={componentProps.skipClass} data-name="Path 1281" />
                  </g>
                </g>
              </g>
            </svg>
          </div>
        )}
        <div className={styles.playpause} onClick={() => this.togglePlayPause()}>
          {isPaused && (
            <svg xmlns="http://www.w3.org/2000/svg" width="35.849" height="40.088" viewBox="0 0 35.849 40.088">
              <g id="play" transform="translate(-57.466 -396.157)">
                <path
                  id="Path_1281"
                  d="M60.649 19.11L27.8.5v37.088z"
                  className={componentProps.playPauseClass}
                  data-name="Path 1281"
                  transform="translate(31.166 397.157)"
                />
              </g>
            </svg>
          )}
          {!isPaused && (
            <svg xmlns="http://www.w3.org/2000/svg" width="30.884" height="40.339" viewBox="0 0 30.884 40.339">
              <g id="pause" transform="translate(-237.423 -394.203)">
                <g id="lines" transform="translate(244.453 403.108)">
                  <g id="lines-2" data-name="lines" transform="translate(-5.53 -7.405)">
                    <g id="Group_1049" data-name="Group 1049">
                      <g id="Group_958" data-name="Group 958">
                        <path id="Rectangle_1928" d="M0 0h10.087v37.203H0z" className={componentProps.playPauseClass} data-name="Rectangle 1928" />
                      </g>
                      <path
                        id="Rectangle_1929"
                        d="M0 0h10.087v37.203H0z"
                        className={componentProps.playPauseClass}
                        data-name="Rectangle 1929"
                        transform="translate(17.797 .136)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          )}
        </div>
        {isVideo && (
          <div className={styles.skip} onClick={() => this.handleSkipForward()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23.961" height="18.059" viewBox="0 0 23.961 18.059">
              <g id="skip" transform="translate(-257.591 -407.809)">
                <path
                  id="Rectangle_1935"
                  d="M0 0h2.812v14.999H0z"
                  className={componentProps.skipClass}
                  data-name="Rectangle 1935"
                  transform="rotate(180 140.026 212.184)"
                />
                <g id="play" transform="rotate(180 136.107 212.154)">
                  <g id="triangle">
                    <path id="Path_1281" d="M0 7.526L13.124 0v15z" className={componentProps.skipClass} data-name="Path 1281" />
                  </g>
                </g>
              </g>
            </svg>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  videoItems: state.cueItems.videoItems,
  mediaItems: state.cueItems.mediaItems,
  bookmarkPos: state.cueItems.bookmarkPos,
  isPaused: state.ui.isPaused,
  volumeLevel: state.ui.volumeLevel,
  isMuted: state.speech.isMuted,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateBookmarkPos,
      updatePause,
      updateVolumeValue,
      updateMutedStatus,
      updateSkipBack,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlayerControls);
