import * as types from './actionTypes';

// set the value of the dictation and bookmark server path for use to display in debug mode
export const setServers = payload => (dispatch, getState) => {
  dispatch({
    type: types.CLOUDASR_SERVER_PATH_SET,
    payload: payload,
  });
};

// set the value of the session ID
export const setCloudASRSessionID = payload => (dispatch, getState) => {
  //console.log('setCloudASRSessionID', payload);

  console.log(`%c 🎤 sessionID: ${payload} `, 'background: #222; color: #bada55; padding: 10px; border: 1px solid #bada55; font-size: 1.5em;');
  dispatch({
    type: types.CLOUDASR_SERVER_SESSION_ID_SET,
    payload: payload,
  });
};

// toggle when Cloud ASR has successfully connected
export const updateCloudASRConnection = payload => (dispatch, getState) => {
  dispatch({
    type: types.CLOUDASR_CONNECTION_UPDATE,
    payload: payload,
  });
};

// toggle ready for speech state
export const updateReadyForSpeech = payload => (dispatch, getState) => {
  dispatch({
    type: types.CLOUDASR_SPEECH_READY,
    payload: payload,
  });
};

// toggle ready for speech state via Verizon Bluetooth remote
export const updateSpeechClientReady = payload => (dispatch, getState) => {
  dispatch({
    type: types.CLOUDASR_BT_SPEECH_READY,
    payload: payload,
  });
};

// toggle listening state state
export const updateListeningState = payload => (dispatch, getState) => {
  dispatch({
    type: types.CLOUDASR_LISTENING_UPDATE,
    payload: payload,
  });
};

// update value of text
export const updateServerStatus = payload => (dispatch, getState) => {
  dispatch({
    type: types.CLOUDASR_SERVER_STATUS,
    payload: payload,
  });
};

// update ASR results
export const updateAsrResults = payload => (dispatch, getState) => {
  dispatch({
    type: types.CLOUDASR_RESULTS,
    payload: payload,
  });
};

// update output results
export const updateOutputResults = payload => (dispatch, getState) => {
  dispatch({
    type: types.CLOUDASR_OUTPUT_RESULTS,
    payload: payload,
  });
};

// update bookmark position
export const updateBookmark = payload => (dispatch, getState) => {
  dispatch({
    type: types.CLOUDASR_BOOKMARK_POSITION,
    payload: payload,
  });
};

// update media stream status
export const updateMediaStreamStatus = payload => (dispatch, getState) => {
  dispatch({
    type: types.CLOUDASR_MEDIA_STREAM_STATUS,
    payload: payload,
  });
};

// update status of mediascape processing
export const updateProcessedMediascape = payload => (dispatch, getState) => {
  dispatch({
    type: types.CLOUDASR_PROCESSED_MEDIASCAPE,
    payload: payload,
  });
};

// update status of muting microphone data
export const updateMutedStatus = payload => (dispatch, getState) => {
  dispatch({
    type: types.CLOUDASR_MUTED_UPDATE,
    payload: payload,
  });
};

// update bookmark text block
export const updateBookmarkTextBlock = payload => (dispatch, getState) => {
  dispatch({
    type: types.CLOUDASR_BOOKMARK_TEXT_BLOCK_UPDATE,
    payload: payload,
  });
};

// clean up all the settings and revert them to their initial values
export const resetSpeech = payload => (dispatch, getState) => {
  dispatch({
    type: types.RESETTING_SPEECH,
  });
};
