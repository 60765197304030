export const STATUS = {
  WEBGL_LOADING: { code: "webgl_loading", message: "loading webgl" },
  WEBGL_LOADED: {
    code: "webgl_loaded",
    message: "Unity WebGL component has successfully loaded",
    shouldListen: true,
  },
  PAIRED: { code: "paired", message: "user successfully paired with device" },
  ERROR: { code: "error", message: "something went wrong" },
  WEBGL_VIDEO: {
    code: "webgl_video",
    message: "Unity WebGL component has successfully loaded",
    videoPath: "https://firebasestorage.googleapis.com/v0/b/nf...", // full path of video file
    videoState: "Video_Idle_Started", // can be "Video_Started" || "Video_Stopped" || "Video_Idle_Started"
    videoPlayingPostion: "00:00:00", // future feature play position
  },
};
