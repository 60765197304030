import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  filter,
  isEqual
} from 'lodash';
import { updateCueAudioItems } from '../../redux/modules/cueReducer';
import { AUDIO_EVENTS } from '../../constants';
import AudioItemPlayer from '../AudioItemPlayer/AudioItemPlayer';
import {
  audioItemsSelector,
  bookmarkPosSelector
} from '../../selectors/cue-selectors';
import {
  audioStatusSelector,
  resourcesAudioItemsSelector
} from '../../selectors/resource-selectors';
import styles from './AudioPlayer.module.scss';

class AudioPlayer extends Component {
  static propTypes = {
    bookmarkPos: PropTypes.number.isRequired
  }

  constructor(props) {
    super(props);

    this.audioPlayer = React.createRef();

    this.state = {
      isPlaying: false,
      audioItemsForBookmarkPos: [],
      isLooping: false,
      duration: '00:00',
      disabled: true
    };

    this.handleMediaEnd = this.handleMediaEnd.bind(this);
    this.handleMediaReady = this.handleMediaReady.bind(this);
    this.handleMediaPlaying = this.handleMediaPlaying.bind(this);
    this.audioVolumeOut = this.audioVolumeOut.bind(this);

    this.audioItems = [];
    this.audioItemsQueue = [];
  }

  componentDidMount() {
    // this.audioPlayer.current.addEventListener('ended', this.handleMediaEnd);
    // this.audioPlayer.current.addEventListener('canplaythrough', this.handleMediaReady);
    // this.audioPlayer.current.addEventListener('playing', this.handleMediaPlaying);

    // this.audioPlayer.current.play();
  }

  shouldComponentUpdate(nextProps) {
    const {
      audioStatus,
      audioResource,
      audioItems,
      bookmarkPos
    } = this.props;

    if (
      audioStatus
      && !isEqual(audioResource !== nextProps.audioResource)
      && !isEqual(audioItems !== nextProps.audioItems)
    ) {
      return true;
    }

    if (audioStatus && bookmarkPos !== nextProps.bookmarkPos) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps) {
    const {
      audioResource,
      audioItems,
      bookmarkPos
    } = this.props;

    const {
      isPlaying
    } = this.state;

    if (this.audioItems.length === 0) {
      audioItems.forEach((audio, index) => {
        const newAttribiutes = {
          id: index,
          path: audio.name ? audioResource[audio.name].downloadPath : null,
          name: audio.name
        };

        const audioObj = {
          ...audio,
          ...newAttribiutes
        };

        this.audioItems.push(audioObj);
      });
    }


    const newAudioItemsForBookmarkPos = filter(
      this.audioItems,
      audioItem => audioItem.start <= bookmarkPos && audioItem.stop > bookmarkPos
    );

    if (
      bookmarkPos !== prevProps.bookmarkPos
    ) {
      this.setState({
        audioItemsForBookmarkPos: newAudioItemsForBookmarkPos
      });
    }

    if (audioResource.status === 'fadein' && !isPlaying) {
      this.audioVolumeIn(this.audioPlayer.current, audioResource.eventTime);
      this.setState({ isPlaying: true });
    }

    if (audioResource.status === 'fadeout' && isPlaying) {
      this.audioVolumeOut(this.audioPlayer.current, audioResource.eventTime);
      this.setState({ isPlaying: false });
    }
  }

  // componentWillUnmount() {
  //   this.audioPlayer.current.removeEventListener('ended', this.handleMediaEnd);
  //   this.audioPlayer.current.removeEventListener('canplaythrough', this.handleMediaReady);
  //   this.audioPlayer.current.removeEventListener('playing', this.handleMediaPlaying);
  // }

  getSoundAndFadeAudio = (audiosnippetId) => {
    const sound = document.getElementById(audiosnippetId);

    // Set the point in playback that fadeout begins. This is for a 2 second fade out.
    const fadePoint = sound.duration - 2;

    const fadeAudio = setInterval(() => {
      // Only fade if past the fade out point or not at zero already
      if (sound.currentTime >= fadePoint && sound.volume !== 0.0) {
        sound.volume -= 0.1;
      }
      // When volume at zero stop all the intervalling
      if (sound.volume === 0.0) {
        clearInterval(fadeAudio);
      }
    }, 200);
  }

  audioVolumeIn = (currentAudioPlayer, time) => {
    currentAudioPlayer.play();

    currentAudioPlayer.volume = 1;
    currentAudioPlayer.isPlaying = true;

    if (currentAudioPlayer.volume && time !== 0) {
      currentAudioPlayer.isStarting = true;

      let InT = 0;

      const timeSlice = 50;
      const timeMill = time * 1000;
      const steps = timeMill / timeSlice;
      const setVolume = 1; // Target volume level for new song
      const speed = 1 / steps; // Rate of increase

      currentAudioPlayer.volume = InT;

      const eAudio = setInterval(() => {
        InT += speed;
        currentAudioPlayer.volume = InT.toFixed(1);

        if (InT.toFixed(1) >= setVolume) {
          currentAudioPlayer.isStarting = false;

          clearInterval(eAudio);
        }
      }, timeSlice);
    }
  }

  audioVolumeOut(currentAudioPlayer, time) {
    if (currentAudioPlayer.volume && time !== 0) {
      const that = this;
      let InT = 1;

      currentAudioPlayer.isStopping = true;

      const timeSlice = 50;
      const timeMill = time * 1000;
      const steps = timeMill / timeSlice;
      const setVolume = 0; // Target volume level for new song
      const speed = 1 / steps; // Rate of decrease

      currentAudioPlayer.volume = InT;

      const eAudio = setInterval(() => {
        InT -= speed;
        // TODO: check to see if audio is starting and override stop
        InT = Number.isNaN(InT) ? 0 : InT;
        currentAudioPlayer.volume = InT.toFixed(1);
        if (InT.toFixed(1) <= setVolume) {
          currentAudioPlayer.pause();
          currentAudioPlayer.isStopping = false;
          currentAudioPlayer.isPlaying = false;
          clearInterval(eAudio);
          that.handleMediaEnd();
        }
      }, timeSlice);
    } else if (currentAudioPlayer.volume && time === 0) {
      currentAudioPlayer.pause();
      currentAudioPlayer.isStopping = false;
      currentAudioPlayer.isPlaying = false;
    }
  }


  handleMediaEnd() {
    // const { audioItems, actions, audioResource } = this.props;
    // this.setState({ isPlaying: false });

    // const index = audioItems.findIndex(x => x.event_data === audioResource.filename);

    // if (audioItems[index]) {
    //   if (audioItems[index].isRangedCue) {
    //     audioItems[index].status = AUDIO_EVENTS.STOP;
    //   } else {
    //     audioItems[index].status = AUDIO_EVENTS.PLAYED;
    //   }

    //   const payload = {
    //     audioItems
    //   };

    //   actions.updateCueAudioItems(payload);
    // }
  }

  handleMediaReady() {
    // const { audioItems, actions, audioResource } = this.props;

    // const index = audioItems.findIndex(x => x.event_data === audioResource.filename);

    // if (audioItems[index]) {
    //   if (audioItems[index].status === AUDIO_EVENTS.PENDING) {
    //     audioItems[index].status = AUDIO_EVENTS.READY;

    //     const payload = {
    //       audioItems
    //     };

    //     actions.updateCueAudioItems(payload);
    //   }
    // }
  }

  handleMediaPlaying() {
    // const { audioItems, actions, audioResource } = this.props;
    // const index = audioItems.findIndex(x => x.event_data === audioResource.filename);

    // if (audioItems[index]) {
    //   audioItems[index].status = AUDIO_EVENTS.PLAYING;
    //   const payload = {
    //     audioItems
    //   };

    //   actions.updateCueAudioItems(payload);
    // }
  }


  render() {
    const {
      audioItemsForBookmarkPos
    } = this.state;

    return (
      <div className={styles.audioplayer}>
        {
          audioItemsForBookmarkPos.map((audioItem, index) => (
            <AudioItemPlayer
              key={`audio-item-${index + 1}`}
              audioItem={audioItem}
            />
          ))
        }
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => (
  {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          updateCueAudioItems
        }
      ),
      dispatch
    )
  }
);

const mapStateToProps = state => ({
  audioStatus: audioStatusSelector(state),
  audioResource: resourcesAudioItemsSelector(state),
  audioItems: audioItemsSelector(state),
  bookmarkPos: bookmarkPosSelector(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudioPlayer);
