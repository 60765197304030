import React, { Component } from 'react';
import styles from './ProgressBar.module.css';

class ProgressBar extends Component {
  render() {
    const { value } = this.props;

    const displayLoading = Number(Math.round(value * 100));
    const loadingBarStyle = { width: `${displayLoading}%` };

    return (
      <div className={styles.UnityLoading}>
        <div className={styles.LoadingBar}>
          <div className={styles.FullBar}>
            <div className={styles.LoadingAmount} style={loadingBarStyle} />
            <div className={styles.LoadingNumber}>{displayLoading}%</div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProgressBar;
