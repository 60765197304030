import firebase from 'firebase/app';
import { initializeApp, auth as fbauth, database } from 'firebase';
import DB_CONFIG from '../config/firebase';

const config = DB_CONFIG;

initializeApp(config);

export const provider = new fbauth.GoogleAuthProvider();
export const auth = fbauth();
export const ref = database().ref();

export default firebase;
