import React, { Component } from 'react';
import { connect } from 'react-redux';
import { STORAGE } from '../../constants/index';
import styles from './AudioManager.module.css';

class AudioManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audioItems: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    const { event } = this.props;
    // if (event && nextProps.event && nextProps.event.event_data === 'SFX-machine-fast') {
    // }
    if (event && nextProps.event && nextProps.event.event_name === 'sfxName') {
      if (event.event_data !== nextProps.event.event_data) {
        if (this.refs[nextProps.event.event_data]) {
          const isLoop = nextProps.event.loop ? nextProps.event.loop : false;
          const isStop = nextProps.event.stop ? nextProps.event.stop : false;
          this.refs[nextProps.event.event_data].loop = isLoop;
          if (isStop && isLoop && !this.refs[nextProps.event.event_data].isStopping) {
            this.audioVolumeOut(this.refs[nextProps.event.event_data], nextProps.event['fade_out']);

            //this.refs[nextProps.event.event_data].pause();
          } else {
            if (nextProps.event['fade_in'] > 0) {
              //this.refs[nextProps.event.event_data].play();
              this.refs[nextProps.event.event_data].currentTime = 0;

              this.audioVolumeIn(this.refs[nextProps.event.event_data], nextProps.event['fade_in']);
            } else {
              this.refs[nextProps.event.event_data].isPlaying = true;
              this.refs[nextProps.event.event_data].play();
            }
          }
        }
      } else if (nextProps.event.stop === true) {
        if (!this.refs[nextProps.event.event_data].ended && !this.refs[nextProps.event.event_data].isStopping) {
          this.audioVolumeOut(this.refs[nextProps.event.event_data], nextProps.event['fade_out']);
        }
      }
    }
  }

  audioVolumeIn(q, time) {
    q.play();
    q.volume = 1;
    q.isPlaying = true;
    // console.log(`volume: ${q.volume}, ended: ${q.ended}, network: ${q.networkState}, muted:  ${q.muted}`);
    if (q.volume && time !== 0) {
      q.isStarting = true;
      var InT = 0;
      var timeSlice = 50;
      var timeMill = time * 1000;
      var steps = timeMill / timeSlice;
      var setVolume = 1; // Target volume level for new song
      var speed = 1 / steps; // Rate of increase
      q.volume = InT;
      var eAudio = setInterval(function() {
        InT += speed;
        q.volume = InT.toFixed(1);
        if (InT.toFixed(1) >= setVolume) {
          q.isStarting = false;
          clearInterval(eAudio);
        }
      }, timeSlice);
    }
  }

  audioVolumeOut(q, time) {
    if (q.volume && time !== 0) {
      var InT = 1;
      q.isStopping = true;
      var timeSlice = 50;
      var timeMill = time * 1000;
      var steps = timeMill / timeSlice;
      var setVolume = 0; // Target volume level for new song
      var speed = 1 / steps; // Rate of decrease
      q.volume = InT;
      var eAudio = setInterval(function() {
        InT -= speed;
        // TODO: check to see if audio is starting and override stop
        q.volume = InT.toFixed(1);
        if (InT.toFixed(1) <= setVolume) {
          q.pause();
          q.isStopping = false;
          q.isPlaying = false;
          clearInterval(eAudio);
        }
      }, timeSlice);
    } else if (q.volume && time === 0) {
      q.pause();
      q.isStopping = false;
      q.isPlaying = false;
    }
  }

  keyExists(key, search) {
    if (!search || (search.constructor !== Array && search.constructor !== Object)) {
      return false;
    }
    for (var i = 0; i < search.length; i++) {
      if (search[i].event_data === key) {
        return true;
      }
    }
    return key in search;
  }

  render() {
    const { bookId, resources } = this.props;

    return (
      <div className={styles.audioManager}>
        {resources.audioItems.map(audio => {
          if (audio.length > 0) {
            const currentAudioPath = STORAGE.fullPath + bookId + STORAGE.delimiter + 'mp4audio' + STORAGE.delimiter + audio + STORAGE.mediaType;
            return (
              <div key={audio}>
                {/* {audio} */}
                <video src={currentAudioPath} ref={audio} preload="auto" />
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  resources: state.resources,
});

export default connect(mapStateToProps)(AudioManager);
