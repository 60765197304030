// rootReducer
import { combineReducers } from 'redux';
import { firebaseStateReducer } from 'react-redux-firebase';
import userPair from './userReducer/reducers';
import resources from './resourcesReducer/reducers';
import cueItems from './cueReducer/reducers';
import ui from './uiReducer/reducers';
import speech from './speechReducer/reducers';
import device from './deviceReducer/reducers';

export default combineReducers({
  pair: userPair,
  device,
  resources,
  cueItems,
  ui,
  speech,
  firebase: firebaseStateReducer
});

//   routing: routerReducer,
