import request from 'request';
import { STORAGE } from '../../../constants/index';
import * as types from './actionTypes';

const gatherTextCues = cue => {
  if (cue.package.event_name && cue.package.event_name === 'NFXTextTrigger') {
    let fixedCue = {
      range: [cue.package.start_word, cue.package.end_word],
      activeWords: cue.package.active_words,
      start: parseInt(cue.start, 10),
      stop: parseInt(cue.stop, 10),
      status: 'pending',
    };
    return fixedCue;
  }
};

const gatherMediaCues = cue => {
  if (cue.package.event_name && cue.package.event_name === 'NFXTrigger') {
    let fixedCue = {
      event_data: cue.package.event_data,
      event_name: cue.package.event_name,
      range: [cue.package.start_word, cue.package.end_word],
      activeWords: cue.package.active_words,
      start: parseInt(cue.start, 10),
      stop: parseInt(cue.stop, 10),
      status: 'pending',
    };
    return fixedCue;
  }
};

const gatherAudioCues = (cue, audioItems) => {
  if (cue.package.event_name && cue.package.event_name === 'sfxName') {
    const audioFileName = cue.package.event_name.replace(/\.[^/.]+$/, '');
    let fixedCue = {
      event_data: audioFileName,
      event_name: cue.package.event_name,
      range: [cue.package.start_word, cue.package.end_word],
      loop: cue.isLoop,
      fade_in: parseInt(cue.fadeIn, 10),
      fade_out: parseInt(cue.fadeOut, 10),
      start: parseInt(cue.start, 10),
      stop: parseInt(cue.stop, 10),
      status: 'pending',
    };
    return fixedCue;
  }
};

const extractUnityCuesFromJSON = json => {
  let mediaObj = [];
  let audioObj = [];
  let textRangeObj = [];

  json.forEach(cue => {
    const mediaItem = gatherMediaCues(cue);
    if (mediaItem) {
      mediaObj.push(mediaItem);
    }

    const audioItem = gatherAudioCues(cue);
    if (audioItem) {
      audioObj.push(audioItem);
    }

    const textItem = gatherTextCues(cue);
    if (textItem) {
      textRangeObj.push(textItem);
    }
  });

  const results = {
    mediaObj,
    audioObj,
    textRangeObj,
  };

  return results;
};

export const hydrateCueDataFromFirebase = (payload, isWip = false, isVerizon = false) => (dispatch, getState, getFirebase) => {
  const firebase = getFirebase();

  const mediaPath = payload.soundscapeid ? '/packagedMedia/v1/en-us' : '/nfxMedia/v1/en-us';
  const whichKey = payload.soundscapeid ? payload.soundscapeid : payload.resourceid;

  dispatch({
    type: types.FETCH_CUEITEMS_FROM_FIREBASE,
  });

  const refPath = `${mediaPath}/${whichKey}/onlinePackageData`;

  return firebase
    .database()
    .ref(refPath)
    .once('value')
    .then(function(snapshot) {
      const cues = snapshot.val().cues;
      const corpus = snapshot.val().corpus.words;
      // parse storage based cues in to correct shape
      const newShape = {
        mediaItems: [],
        video:
          cues && cues.video
            ? cues.video.map(video => {
                const newVidItem = {
                  animation: video.artifactKeys.cutscene.replace(/\.[^/.]+$/, ''),
                  idle: video.artifactKeys.idle.replace(/\.[^/.]+$/, ''),
                  event_name: 'video',
                  isInitial: video.isInitial,
                  start: video.start,
                  stop: video.stop,
                  status: 'pending',
                };
                return newVidItem;
              })
            : [],
        textRanges:
          cues && cues.textBlock
            ? cues.textBlock.map(text => {
                const newText = {
                  start: parseInt(text.start || 0, 10),
                  stop: parseInt(text.stop || 0, 10),
                  text: corpus.slice(text.start, text.stop),
                };
                return newText;
              })
            : [],
        audio:
          cues && cues.audio
            ? cues.audio.map(audio => {
                const newAudio = {
                  name: audio.artifactKey.replace(/\.[^/.]+$/, ''),
                  isLoop: audio.isLoop,
                  isRangedCue: audio.isRangedCue,
                  fade_in: parseInt(audio.fadeIn || 0, 10),
                  fade_out: parseInt(audio.fadeOut || 0, 10),
                  start: parseInt(audio.start, 10),
                  stop: parseInt(audio.stop, 10),
                  status: 'pending',
                };
                return newAudio;
              })
            : [],
      };

      let parsedcues = {
        mediaItems: [],
        video: [],
        audio: [],
        textRanges: [],
        json: [],
      };

      if (cues) {
        parsedcues = {
          mediaItems: cues.mediaItems ? cues.mediaItems : [],
          video: newShape.video ? newShape.video : [],
          audio: cues.audioItems ? cues.audioItems : newShape.audio,
          textRanges: cues.textRanges ? cues.textRanges : newShape.textRanges,
          json: cues.json,
        };
      }
      if (cues) {
        dispatch({
          type: types.STORE_CUEITEMS_FROM_FIREBASE,
          payload: parsedcues,
        });
      }
    });
};

export const getCueDataFromStorage = (payload, isWip = false, isVerizon = false) => (dispatch, getState) => {
  const mediaPath = isWip ? STORAGE.packagedMediaPath : STORAGE.nfxMediaPath;
  const whichKey = isWip ? payload.soundscapeid : payload.resourceid;
  let nfxMediaURL = `${mediaPath}${whichKey}${STORAGE.delimiter}${whichKey}-NFXData.json?alt=media`;
  if (isVerizon) {
    nfxMediaURL = `${STORAGE.cfPath}${payload.resourceid}/json/${payload.soundscapeid}-NFXData.json`;
  }

  dispatch({
    type: types.FETCH_CUEITEMS_FROM_STORAGE,
  });

  request.get(nfxMediaURL, (err, httpResponse, body) => {
    if (httpResponse) {
      const parsed = JSON.parse(httpResponse.body);
      const cues = parsed.cues;

      if (cues && cues.json && cues.json.length > 0) {
        const extractedCues = extractUnityCuesFromJSON(cues.json);

        cues.mediaItems = extractedCues.mediaObj;
        cues.textRanges = extractedCues.textRangeObj;
        cues.audioItems = extractedCues.audioObj;
      }

      // parse storage based cues in to correct shape
      const newShape = {
        mediaItems: [],
        video: cues
          ? cues.video.map(video => {
              const newVidItem = {
                animation: video.artifactKeys.cutscene.replace(/\.[^/.]+$/, ''),
                idle: video.artifactKeys.idle.replace(/\.[^/.]+$/, ''),
                event_name: 'video',
                isInitial: video.isInitial,
                start: video.start,
                stop: video.stop,
                status: 'pending',
              };
              return newVidItem;
            })
          : [],
        textRanges: cues ? cues.textBlock : [],
        audio: cues
          ? cues.audio.map(audio => {
              const newAudio = {
                name: audio.artifactKey.replace(/\.[^/.]+$/, ''),
                isLoop: audio.isLoop,
                isRangedCue: audio.isRangedCue,
                fade_in: parseInt(audio.fadeIn || 0, 10),
                fade_out: parseInt(audio.fadeOut || 0, 10),
                start: parseInt(audio.start, 10),
                stop: parseInt(audio.stop, 10),
                status: 'pending',
              };
              return newAudio;
            })
          : [],
      };

      let parsedcues = {
        mediaItems: [],
        video: [],
        audio: [],
        textRanges: [],
        json: [],
      };

      if (cues) {
        parsedcues = {
          mediaItems: cues.mediaItems ? cues.mediaItems : [],
          video: newShape.video ? newShape.video : [],
          audio: cues.audioItems ? cues.audioItems : newShape.audio,
          textRanges: cues.textRanges ? cues.textRanges : newShape.textRanges,
          json: cues.json,
        };
      }
      if (cues) {
        dispatch({
          type: types.STORE_CUEITEMS_FROM_STORAGE,
          payload: parsedcues,
        });
      }
    }
  });
};

export const createVideoItems = payload => (dispatch, getState) => {
  dispatch({
    type: types.GENERATE_VIDEO_ITEMS,
    payload: payload,
  });
};

export const updateVideoItem = payload => (dispatch, getState) => {
  dispatch({
    type: types.VIDEO_ITEM_UPDATE,
    payload: payload,
  });
};

export const createMediaItems = payload => (dispatch, getState) => {
  dispatch({
    type: types.GENERATE_MEDIA_ITEMS,
    payload: payload,
  });
};

export const createAudioItems = payload => (dispatch, getState) => {
  dispatch({
    type: types.GENERATE_AUDIO_ITEMS,
    payload: payload,
  });
};

export const createTextRangeItems = payload => (dispatch, getState) => {
  dispatch({
    type: types.GENERATE_TEXTRANGE_ITEMS,
    payload: payload,
  });
};

export const updateMediaItem = payload => (dispatch, getState) => {
  dispatch({
    type: types.MEDIA_ITEM_UPDATE,
    payload: payload,
  });
};

export const updateCueAudioItems = payload => (dispatch, getState) => {
  dispatch({
    type: types.AUDIO_ITEMS_UPDATE,
    payload: payload,
  });
};

export const updateCueAudioItem = payload => (dispatch, getState) => {
  dispatch({
    type: types.AUDIO_ITEM_UPDATE,
    payload: payload,
  });
};

export const updateBookmarkPos = payload => (dispatch, getState) => {
  dispatch({
    type: types.BOOKMARK_POSITION_UPDATE,
    payload: parseInt(payload, 10),
  });
};

export const updateCueStatus = payload => (dispatch, getState) => {
  dispatch({
    type: types.CUE_STATUS_UPDATE,
    payload: payload,
  });
};

export const resetCues = payload => (dispatch, getState) => {
  dispatch({
    type: types.RESETTING_CUES,
  });
};
