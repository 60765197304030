import { padStart } from './index';
const userStorage = window.localStorage;

export const createCode = usedcodes => {
  const localpair = JSON.parse(userStorage.getItem('nfxSession:pairing'));
  if (!localpair) {
    const min = Math.ceil(0);
    const max = Math.floor(99999);
    const rendNum = Math.floor(Math.random() * (max - min)) + min;
    let isExisting = false;

    const result = padStart(rendNum, 5);

    // check to make sure it doesn't exist already
    if (usedcodes) {
      usedcodes.forEach(item => {
        if (item.key === result) {
          isExisting = true;
        }
      });
    }

    if (isExisting) {
      return this.createCode();
    } else {
      const storeItem = JSON.stringify({ pairCode: result });
      userStorage.setItem('nfxSession:pairing', storeItem);
      return result;
    }
  } else {
    return localpair.pairCode;
  }
};

export const removePairStorage = () => {
  localStorage.removeItem('nfxSession:pairing');
};

export const loadPairStorage = () => {
  const localState = JSON.parse(userStorage.getItem('nfxSession:pairing'));
  return localState;
};

export const loadUserStorage = () => {
  const localState = JSON.parse(userStorage.getItem('nfxSession:userId'));
  return localState;
};

export const saveUserStorage = id => {
  const storeItem = JSON.stringify({ userId: id });
  userStorage.setItem('nfxSession:userId', storeItem);
  return storeItem;
};

export const resetUserPairing = () => {
  localStorage.removeItem('nfxSession:pairing');
  localStorage.removeItem('nfxSession:userId');
};
