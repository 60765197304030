import React from 'react';
import classnames from 'classnames';
import styles from './Drawer.module.scss';

type Props = {
  children?: React$Element<any> | Array<React$Element<any>>,
  className?: string,
  disabled?: boolean,
  visible?: boolean,
  color?: string,
  size?: 'small' | 'normal' | 'large',
  theme?: 'default' | 'dark' | 'light',
};

const Drawer = ({ children, className, visible = false, disabled = false, size = 'normal', theme = 'default', text, color, ...others }: Props) => {
  const componentProps = {
    ...others,
    className: classnames(styles.drawer, styles[`theme--${theme}`], styles[`size--${size}`], { [styles.active]: !visible }, className),
    style: { backgroundColor: color },
    text,
    disabled,
  };

  const childComponent = children && <span className={styles.children}>{children}</span>;
  return <div {...componentProps}>{childComponent}</div>;
};

export default Drawer;
