import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class Title extends Component {
  shouldComponentUpdate(nextProps) {
    const { displayTitle } = this.props;
    if (displayTitle !== nextProps.displayTitle) {
      return true;
    }

    return false;
  }
  render() {
    const { displayTitle } = this.props;

    return (
      <Helmet>
        <title>{displayTitle} - Novel Effect</title>
      </Helmet>
    );
  }
}

export default Title;
