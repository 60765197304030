import React, { Component } from 'react';
// import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { Button, Input } from '../../components';
import styles from './auth.module.scss';
// import GoogleButton from 'react-google-button' // optional

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formEmail: '',
      formPass: '',
      message: '',
      error: '',
      UserId: '',
    };
    this.currentUrl = window.location.href.replace('/login', '/');
  }

  forgotPassword() {
    const { firebase } = this.props;

    let emailReset = prompt('Enter your email address', this.state.formEmail);
    if (emailReset !== null) {
      firebase
        .auth()
        .sendPasswordResetEmail(emailReset)
        .then(() => {
          this.setState({ message: `reset email sent to ${emailReset}` });
        })
        .catch(error => {
          this.setState({ message: error.message });
        });
    }
  }

  logout() {
    this.props.firebase.logout();
  }

  login() {
    const { firebase } = this.props;
    firebase
      .login({
        email: this.state.formEmail,
        password: this.state.formPass,
      })
      .then(response => {
        this.setState({ UserId: response.uid }, (window.location.href = this.currentUrl));
      })
      .catch(err => {
        this.setState({ error: err.message });
      });
  }

  render() {
    const { firebase } = this.props;

    return (
      <div className={styles.loginPage}>
        <div className={styles.loginbox}>
          <div>
            <div className={styles.loginHeader}>
              {/* <img src={logo} className={styles.logo} /> */}

              <h1>Login</h1>
            </div>
            <div className={styles.username}>
              {this.state.message && <div className={styles.message}>{this.state.message}</div>}
              &nbsp;
              <br />
              <Input
                id="formEmail"
                name="formEmail"
                type="email"
                placeholder="email address"
                value={this.state.formEmail}
                onChange={email => this.setState({ formEmail: email.target.value })}
              />
            </div>
            <div className={styles.password}>
              <Input
                id="formPass"
                name="formPass"
                type="password"
                placeholder="password"
                value={this.state.formPass}
                onChange={password => this.setState({ formPass: password.target.value })}
              />
            </div>
            {this.state.error !== '' && <div className={styles.errorMessage}>{this.state.error}</div>}
            <div className={styles.buttonbar}>
              <Button theme="primary" inverse={true} onClick={() => this.forgotPassword()}>
                Forgot Password
              </Button>
              <Button theme="success" onClick={() => this.login()}>
                Log In
              </Button>
            </div>
            <div className={styles.facebookButton}>
              <Button theme="primary" onClick={() => firebase.login({ provider: 'facebook', type: 'popup | redirect' })}>
                Login With Facebook
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export const Login = ({ firebase, auth }) => (
//   <div className="authContainer">
//     <div>
//       <button // <GoogleButton/> button can be used instead
//         onClick={() => firebase.login({ email: 'facebook', password: 'popup | redirect' })}
//       >
//         Login With Email
//       </button>
//     </div>
//     <div>
//       <button // <GoogleButton/> button can be used instead
//         onClick={() => firebase.login({ provider: 'facebook', type: 'popup | redirect' })}
//       >
//         Login With Facebook
//       </button>
//     </div>
//     <div>
//       <h2>Auth</h2>
//       {!isLoaded(auth) ? <span>Loading...</span> : isEmpty(auth) ? <span>Not Authed</span> : <pre>{JSON.stringify(auth, null, 2)}</pre>}
//     </div>
//   </div>
// );

// Login.propTypes = {
//   firebase: PropTypes.shape({
//     login: PropTypes.func.isRequired,
//   }),
//   auth: PropTypes.object,
// };

export default compose(
  firebaseConnect(), // withFirebase can also be used
  connect(({ firebase: { auth } }) => ({ auth })),
)(Login);
