import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import styles from './Branding.module.scss';
import logo from '../../assets/images/logo_full.svg';
import logoFiretv from '../../assets/images/FireTVicon-01.png';
import logoText from '../../assets/images/logo_text.svg';
import { areImagesLoaded } from '../../selectors/ui-selectors';
import { isFiretvSelector } from '../../selectors/device-selectors';

const Branding = ({ allImagesLoaded, isFiretv, style }) => {
  const src = isFiretv ? logoFiretv : logo;

  if (allImagesLoaded) {
    if (style === 'standard') {
      return (
        <div className={styles.branding}>
          <div>
            <img className={styles.logo} src={src} alt="Novel Effect" />
          </div>
          {!isFiretv && <div className={styles.subtext}>Voice-driven stories</div>}
        </div>
      );
    }
    return (
      <div className={styles.branding}>
        <img className={styles.logoText} src={logoText} alt="Novel Effect" />
      </div>
    );
  }
  return null;
};

Branding.propTypes = {
  allImagesLoaded: PropTypes.bool,
  isFiretv: PropTypes.bool,
  style: PropTypes.string
};

Branding.defaultProps = {
  allImagesLoaded: false,
  isFiretv: false,
  style: 'standard'
};

const mapStateToProps = state => ({
  allImagesLoaded: areImagesLoaded(state),
  isFiretv: isFiretvSelector(state)
});

export default connect(mapStateToProps, null)(Branding);
