export default class DictateDefaults {
  static get REFERENCE_HANDLER() {
    return 'http://bark.phon.ioc.ee:82/dev/duplex-speech-api/dynamic/reference';
  }

  static get CONTENT_TYPE() {
    return 'content-type=audio/x-raw,+layout=(string)interleaved,+rate=(int)16000,+format=(string)S16LE,+channels=(int)1';
  }

  // Send blocks 4 x per second as recommended in the server doc.
  static get INTERVAL() {
    return 250;
  }

  static get TAG_END_OF_SENTENCE() {
    return 'EOS';
  }

  // Server status codes
  // from https://github.com/alumae/kaldi-gstreamer-server
  static get SERVER_STATUS_CODE() {
    return {
      0: 'Success', // Usually used when recognition results are sent
      1: 'No speech', // Incoming audio contained a large portion of silence or non-speech
      2: 'Aborted', // Recognition was aborted for some reason
      9: 'No available', // Recognizer processes are currently in use and recognition cannot be performed
    };
  }
}
