import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import classNames from 'classnames';
import { Loading } from '../../components';
import styles from './CarouselDetails.module.scss';

class CarouselDetails extends Component {
  render() {
    const { collectionItem } = this.props;

    if (isLoaded(collectionItem) && !isEmpty(collectionItem)) {
      return (
        <div>
          <div className={styles.backgroundImage} />
          <div className={styles.detailsText}>
            <div className={classNames(styles.title, styles.titleAdditional)}>
              {collectionItem.title}
            </div>
            <div className={classNames(styles.description, styles.descriptionAdditional)}>
              {collectionItem.description}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.loadingWrap}>
          <Loading />
        </div>
      );
    }
  }
}

export default connect(
  ({ firebase: { data } }, props) => ({
    collectionItem: data[props.id],
  }),
)(CarouselDetails)
