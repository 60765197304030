import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { updateVolumeValue } from '../../redux/modules/uiReducer';
import VolumeToggle from './VolumeToggle';
import styles from './VolumeControl.module.scss';
import './VolumeControl.module.scss';

class VolumeControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuExpanded: false,
    };

    this.previousLevel = 100;
  }

  handleVolumeChange(level) {
    const { updateVolumeValue, isPaused } = this.props;
    if (!isPaused) {
      updateVolumeValue(parseInt(level));
    }
  }

  toggleVolume() {
    const { updateVolumeValue, volumeLevel, isPaused } = this.props;
    if (!isPaused) {
      if (volumeLevel === 0) {
        updateVolumeValue(this.previousLevel);
      } else {
        this.previousLevel = volumeLevel;
        updateVolumeValue(0);
      }
    }
  }
  render() {
    const { volumeLevel, isPaused } = this.props;
    return (
      <div
        className={classNames(styles.volumeControl, {
          [styles.disabled]: isPaused,
        })}
      >
        <div className={styles.speaker}>
          <VolumeToggle isMuted={volumeLevel === 0} onClick={() => this.toggleVolume()} />
        </div>
        <div className={styles.sliderWrapper}>
          <progress max={100} value={volumeLevel} className={styles.progress} />
          <input
            type="range"
            min={0}
            max={100}
            value={volumeLevel}
            className={classNames(styles.slider, {
              [styles.disabled]: isPaused,
            })}
            onChange={level => this.handleVolumeChange(level.target.value)}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  volumeLevel: state.ui.volumeLevel,
  isMuted: state.speech.isMuted,
  isPaused: state.ui.isPaused,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateVolumeValue,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VolumeControl);
