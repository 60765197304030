import React, { Component } from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import {
  Login,
  Content,
  StbCollection,
  Mediascape,
  Wait,
  Pairing,
  Voice,
  MobilePair,
  MediascapeDevice,
  ContentRedirector,
  FirstPlay
} from './Pages';
import { Loading, Menu, DeviceDetect } from './components';
import { setCloudASRSessionID } from './redux/modules/speechReducer';
import {
  setSessionId,
  setSessionStatus
} from './redux/modules/userReducer';
import './App.css';
import styles from './App.module.scss';

class App extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      setSessionId: PropTypes.func.isRequired,
      setCloudASRSessionID: PropTypes.func.isRequired,
      setSessionStatus: PropTypes.func.isRequired
    }).isRequired,
    auth: PropTypes.shape({
      isLoaded: PropTypes.bool.isRequired,
      isEmpty: PropTypes.bool.isRequired,
      isAnonymous: PropTypes.bool,
      uid: PropTypes.string
    }).isRequired,
    device: PropTypes.shape({
      usesPairing: PropTypes.bool.isRequired,
      currentDevice: PropTypes.string,
      isFiretv: PropTypes.bool
    }).isRequired,
    firebase: PropTypes.shape({
      auth: PropTypes.func.isRequired
    }).isRequired,
    pair: PropTypes.shape({
      userId: PropTypes.string
    }).isRequired,
    sessionId: PropTypes.string
  }

  constructor(props) {
    super(props);

    this.logInTimeout = 15; // in seconds;

    this.isAnonymous = false;
    this.uid = null;

    props.firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        this.uid = user.uid;
        // ...
      }
    });
    const domainCheck = 'verizon.noveleffect.com';
    // const domainCheck = 'localhost';

    // TODO: move this to something more central
    this.isVerizon = navigator.appVersion.toLowerCase().includes('verizon') || window.location.hostname === domainCheck;
    this.isVMS = navigator.appVersion.toLowerCase().includes('vms4100')
      || navigator.appVersion.toLowerCase().includes('ipc4100')
      || window.location.hostname === domainCheck;

    this.sessionID = null;
  }


  componentDidMount() {
    const { actions, auth } = this.props;

    this.resetSessionStatus();
    this.initSession();

    if (this.sessionID) {
      // session id used by a basic web client
      actions.setCloudASRSessionID(this.sessionID);
    }

    const isProd = process.env.NODE_ENV !== 'development';

    // user connection speed and type
    // console.log('connection', navigator.connection);

    if (isProd && !this.isVerizon && !this.isVMS) {
      ReactGA.initialize('UA-71476104-5');
      ReactGA.pageview(window.location.pathname + window.location.search);
    }

    if (isProd) ReactGA.set({ userId: auth.uid });
  }

  componentDidUpdate(prevProps) {
    const {
      auth, firebase, actions, sessionId
    } = this.props;

    if (prevProps.sessionId !== sessionId) {
      this.resetSessionStatus();
    }

    if (!sessionId) {
      const isUsingId = this.initSession();

      if (isUsingId) {
        actions.setCloudASRSessionID(this.sessionID);
      }
    }
    if (auth.isLoaded && auth.isEmpty) {
      firebase
        .auth()
        .signInAnonymously()
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // eslint-disable-next-line no-console
          console.log(errorCode, errorMessage);
        });
    }

    // store session in firebase for access and updates to the mobile voice client
    if (auth.isLoaded && !auth.isEmpty) {
      const voicePairPayload = {
        userId: auth.uid,
        sessionId: this.sessionID
      };

      actions.setSessionId(voicePairPayload);
    }
  }

  resetSessionStatus = () => {
    const { actions, auth } = this.props;

    const payload = {
      userId: auth.uid,
      status: 'disconnected'
    };

    actions.setSessionStatus(payload);
  }

  initSession() {
    let deviceType = '';
    if (this.isVerizon && this.isVMS) {
      deviceType = 'stb_';
    }

    const randomNumber = Math.random()
      .toString(36)
      .substring(2);
    this.sessionID = `session_${deviceType}${randomNumber}`;

    // eslint-disable-next-line max-len
    // if opening up via the external voice client we should use the sessionID in the url instead of creating a new one
    if (window.location.href.includes('voice/')) {
      this.sessionID = null;
      return false;
    }

    return true;
  }

  render() {
    const {
      auth, pair, firebase, device
    } = this.props;

    const myPath = process.env.PUBLIC_URL;

    const showPairing = device.usesPairing ? device.usesPairing : false;

    if ((this.isVerizon && this.isVMS) || device.isFiretv) {
      return (
        <div className={styles.App}>
          <Router>
            <div>
              {auth.isLoaded && !auth.isEmpty && (
                <div>
                  <DeviceDetect firebase={firebase} />
                  <Route exact path={`${myPath}/`} component={StbCollection} />
                  <Route exact path={`${myPath}/mediascape/`} component={StbCollection} />
                  <Route exact path={`${myPath}/pairdevice`} component={MobilePair} />
                  <Route exact path={`${myPath}/voice`} component={MobilePair} />
                  <Route exact path={`${myPath}/voice/:userid`} component={Voice} />
                  <Route exact path={`${myPath}/voice/:userid/:resourceid/:soundscapeid`} component={Voice} />
                  <Route exact path={`${myPath}/firstplay/:resourceid`} component={FirstPlay} />
                  <Route
                    exact
                    path={`${myPath}/mediascape/:resourceid/:soundscapeid`}
                    render={props => <MediascapeDevice {...props} auth={auth} />}
                  />
                  <Route
                    exact
                    path={`${myPath}/mediascape/:resourceid`}
                    render={props => <MediascapeDevice {...props} auth={auth} />}
                  />
                  {!auth.isAnonymous && <Route exact path={`${myPath}/wip/:soundscapeid`} component={MediascapeDevice} />}
                  <Route exact path={`${myPath}/wait`} component={Wait} />
                  {/* if we're using a paired device we should always be listening for meta */}

                  <Route exact path={`${myPath}/`} component={ContentRedirector} />
                  <Route exact path={`${myPath}/mediascape/:resourceid/:soundscapeid`} component={ContentRedirector} />
                  <Route exact path={`${myPath}/mediascape/:resourceid`} component={ContentRedirector} />
                </div>
              )}
              {!auth.isLoaded && !showPairing && auth.isEmpty && (
                <div className={styles.loadingWrap}>
                  <div className={styles.loading}>
                    <Loading />
                  </div>
                </div>
              )}
            </div>
          </Router>
        </div>
      );
    }

    if (!this.isVerizon && !this.isVMS) {
      return (
        <div className={styles.App}>
          <Router>
            <div>
              <DeviceDetect firebase={firebase} />

              <Route exact path={`${myPath}/pairdevice`} component={MobilePair} />
              <Route exact path={`${myPath}/voice`} component={MobilePair} />
              <Route exact path={`${myPath}/voice/:userid`} component={Voice} />
              <Route exact path={`${myPath}/voice/:userid/:resourceid/:soundscapeid`} component={Voice} />
              {showPairing && (
                <div>
                  <Route exact path={`${myPath}/mediascape/:resourceid/:soundscapeid`} component={MediascapeDevice} />
                  <Route exact path={`${myPath}/mediascape/:resourceid`} component={MediascapeDevice} />
                  {!auth.isAnonymous && <Route exact path={`${myPath}/wip/:soundscapeid`} component={MediascapeDevice} />}
                </div>
              )}
              {/* if we're using a paired device we should always be listening for meta */}
              {showPairing && (
                <div>
                  <Route exact path={`${myPath}/`} component={ContentRedirector} />
                  <Route exact path={`${myPath}/mediascape/:resourceid/:soundscapeid`} component={ContentRedirector} />
                  <Route exact path={`${myPath}/mediascape/:resourceid`} component={ContentRedirector} />
                </div>
              )}
              {pair.userId && (
                <div>
                  <Route exact path={`${myPath}/`} component={Wait} />
                </div>
              )}
              {auth.isAnonymous && !pair.userId && (
                <div>
                  {!showPairing && <Route exact path={`${myPath}/login`} component={Login} />}
                  {showPairing && (
                    <div className={styles.loadingWrap}>
                      <Route exact path={`${myPath}/`} component={Pairing} />
                    </div>
                  )}
                </div>
              )}
              {auth.isLoaded && !auth.isEmpty && !showPairing && (
                <div>
                  <Route path={`${myPath}/`} component={Menu} />
                  {/* <Menu firebase={firebase} isAnonymous={auth.isAnonymous} /> */}
                  {!auth.isAnonymous && (
                    <div>
                      <Route exact path={`${myPath}/`} component={Content} />
                      <Route exact path={`${myPath}/mediascape/`} component={Content} />
                    </div>
                  )}

                  {auth.isAnonymous && (
                    <div>
                      {/* <Route exact path={`${myPath}/`} component={Collection} />
                      <Route exact path={`${myPath}/mediascape/`} component={Collection} /> */}
                    </div>
                  )}

                  <Route exact path={`${myPath}/mediascape/:resourceid/:soundscapeid`} component={Mediascape} />
                  <Route exact path={`${myPath}/mediascape/:resourceid`} component={Mediascape} />
                  {!auth.isAnonymous && <Route exact path={`${myPath}/wip/:soundscapeid`} component={Mediascape} />}
                  {auth.isAnonymous && <Route exact path={`${myPath}/wip/:soundscapeid`} component={Login} />}
                </div>
              )}

              {!auth.isLoaded && !showPairing && auth.isEmpty && (
                <div className={styles.loadingWrap}>
                  <div className={styles.loading}>
                    <Loading />
                  </div>
                </div>
              )}
            </div>
          </Router>
        </div>
      );
    }

    return null;
  }
}

const mapDispatchToProps = dispatch => (
  {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          setCloudASRSessionID,
          setSessionId,
          setSessionStatus
        }
      ),
      dispatch
    )
  }
);

const mapStateToProps = (state) => {
  const {
    firebase: { auth, profile, data }, device, pair, speech
  } = state;

  return {
    auth,
    profile,
    pairCode: data.pairCode,
    session: data.session,
    sessionId: speech.sessionId,
    device,
    pair
  };
};

const enhance = compose(
  firebaseConnect(props => [
    {
      path: 'webclient/pairCode',
      storeAs: 'pairCode'
    },
    {
      path: `webclient/session/${props.pair.userId}`,
      storeAs: 'session'
    }
  ]),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default connect(mapStateToProps)(enhance(App));
