const initialState = {
  dictateServer: '',
  bookmarkingServer: '',
  isCloudASRConnected: false,
  isDictateInit: false,
  isReadyForSpeech: false,
  isBTReadyForSpeech: false,
  isListening: false,
  currentServerStatus: [],
  errors: null,
  outputResults: [],
  asrResults: '',
  bookmarkPos: -1,
  mediaStreamCreated: false,
  processedMediascape: false,
  isMuted: false,
  bookmarkTextBlock: 0,
  sessionId: null
};

export default initialState;
