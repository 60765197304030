import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { updateDrawer } from '../../redux/modules/uiReducer';
import logoText from '../../assets/images/logo_text.svg';
import styles from './TopBar.module.scss';

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuExpanded: false,
    };
  }

  handleHome() {
    this.props.history.push('/');
  }

  handleShare() {
    const { ui, updateDrawer } = this.props;
    const newValue = ui.drawerItem === 'share' ? null : 'share';
    updateDrawer(newValue);
  }

  handleAbout() {
    const { ui, updateDrawer } = this.props;
    const newValue = ui.drawerItem === 'about' ? null : 'about';
    updateDrawer(newValue);
  }

  handleSettings() {
    const { ui, updateDrawer } = this.props;
    const newValue = ui.drawerItem === 'settings' ? null : 'settings';
    updateDrawer(newValue);
  }

  render() {
    const { isHidden = false, ui, onMouseEnter, onMouseLeave } = this.props;

    let hideMenu = !ui.drawerItem ? isHidden : false;
    // hideMenu = false;
    return (
      <div
        className={classNames(styles.topBar, {
          [styles.hidden]: hideMenu,
        })}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className={styles.items}>
          <div className={styles.back} onClick={() => this.handleHome()}>
            <div className={styles.backIcon}>
              <svg xmlns="http://www.w3.org/2000/svg" width="33.326" height="25.367" viewBox="0 0 33.326 25.367">
                <g id="exit" transform="translate(-53.343 -246.934)">
                  <g id="group" transform="rotate(180 47.367 140.139)">
                    <g id="Group_1692" data-name="Group 1692">
                      <path
                        id="Path_858"
                        d="M19.882 0S30.568 11.184 30.32 11.184h-.249c.5 0-10.438 11.184-10.438 11.184"
                        className={styles.exitButton}
                        data-name="Path 858"
                        transform="translate(9.566 9.477)"
                      />
                      <path id="Path_1340" d="M39.342 20.66H9.89" className={styles.exitButton} data-name="Path 1340" />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div className={styles.backLabel}>Exit</div>
          </div>
          <div className={styles.branding}>
            <img src={logoText} alt="Novel Effect" />
          </div>
          <div className={styles.rightHand}>
            <div className={styles.actionItem} onClick={() => this.handleShare()}>
              <div className={styles.icon}>
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="80%" viewBox="0 0 27.068 33.002">
                  <g id="share" transform="translate(-153.604 -240.212)">
                    <g id="group" transform="translate(155.128 241.714)">
                      <g id="Group_1034" data-name="Group 1034">
                        <path
                          id="Path_853"
                          d="M3.6 6.561S10.324.359 10.324.5v17.456V.5c0-.288 6.724 6.058 6.724 6.058"
                          className={classNames(styles.shareIcon, {
                            [styles.active]: ui.drawerItem === 'share',
                          })}
                          data-name="Path 853"
                          transform="translate(1.572 -.493)"
                        />
                        <path
                          id="Path_854"
                          d="M7.575 7.346H.476L.5 26.175h24L24.544 7.2h-7.369"
                          className={classNames(styles.shareIcon, {
                            [styles.active]: ui.drawerItem === 'share',
                          })}
                          data-name="Path 854"
                          transform="translate(-.5 3.825)"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div
                className={classNames(styles.label, {
                  [styles.active]: ui.drawerItem === 'share',
                })}
              >
                Share
              </div>
            </div>
            <div className={styles.actionItem} onClick={() => this.handleAbout()}>
              <div className={styles.icon}>
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 33 33">
                  <g id="about" transform="translate(-237.5 -240.214)">
                    <g id="group">
                      <g id="Group_1672" data-name="Group 1672">
                        <g id="Group_1662" data-name="Group 1662" transform="translate(239 241.714)">
                          <g id="Group_1658" data-name="Group 1658">
                            <g id="Group_1563" data-name="Group 1563">
                              <circle
                                id="Ellipse_306"
                                cx="15"
                                cy="15"
                                r="15"
                                className={classNames(styles.cls1, {
                                  [styles.active]: ui.drawerItem === 'about',
                                })}
                                data-name="Ellipse 306"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g id="Group_1689" data-name="Group 1689" transform="translate(37 -1)">
                      <path
                        id="Line_34"
                        d="M0 0v8"
                        className={classNames(styles.cls2, {
                          [styles.active]: ui.drawerItem === 'about',
                        })}
                        data-name="Line 34"
                        transform="translate(217 256.5)"
                      />
                      <g
                        id="Ellipse_325"
                        className={classNames(styles.cls3, {
                          [styles.active]: ui.drawerItem === 'about',
                        })}
                        data-name="Ellipse 325"
                        transform="translate(215.5 250)"
                      >
                        <circle
                          cx="1.6"
                          cy="1.6"
                          r="1.6"
                          className={classNames(styles.cls4, {
                            [styles.active]: ui.drawerItem === 'about',
                          })}
                        />
                        <circle
                          cx="1.6"
                          cy="1.6"
                          r="1.1"
                          className={classNames(styles.cls5, {
                            [styles.active]: ui.drawerItem === 'about',
                          })}
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div
                className={classNames(styles.label, {
                  [styles.active]: ui.drawerItem === 'about',
                })}
              >
                About
              </div>
            </div>
            <div className={styles.actionItem} onClick={() => this.handleSettings()}>
              <div className={styles.icon}>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 42 42">
                  <g>
                    <g>
                      <path
                        className={classNames(styles.settingsIcon, {
                          [styles.active]: ui.drawerItem === 'settings',
                        })}
                        d="M10.1,9C7.6,9,5.6,7,5.6,4.5v0c0-1.2,0.5-2.3,1.3-3.2C7.8,0.5,8.9,0,10.1,0c0,0,0,0,0,0
			c1.2,0,2.3,0.5,3.2,1.3c0.9,0.8,1.3,2,1.3,3.2C14.6,7,12.6,9,10.1,9z M10.1,3C10.1,3,10.1,3,10.1,3C9.7,3,9.4,3.2,9.1,3.4
			C8.8,3.7,8.6,4.1,8.6,4.5v0C8.6,5.4,9.3,6,10.1,6s1.5-0.7,1.5-1.5c0-0.4-0.2-0.8-0.4-1.1C10.9,3.2,10.5,3,10.1,3z"
                      />
                    </g>
                    <g>
                      <path
                        className={classNames(styles.settingsIcon, {
                          [styles.active]: ui.drawerItem === 'settings',
                        })}
                        d="M25.4,21c-2.5,0-4.5-2-4.5-4.5v0c0-2.5,2-4.5,4.5-4.5s4.5,2,4.5,4.5C29.9,19,27.9,21,25.4,21z M25.4,15
			c-0.8,0-1.5,0.7-1.5,1.5v0c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5C26.9,15.7,26.2,15,25.4,15z"
                      />
                    </g>
                    <g>
                      <path
                        className={classNames(styles.settingsIcon, {
                          [styles.active]: ui.drawerItem === 'settings',
                        })}
                        d="M15.3,33c-2.5,0-4.5-2-4.5-4.5s2-4.5,4.5-4.5s4.5,2,4.5,4.5S17.8,33,15.3,33z M15.3,27
			c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5S16.2,27,15.3,27z"
                      />
                    </g>
                    <line
                      className={classNames(styles.settingsIcon, styles.settingsLine, {
                        [styles.active]: ui.drawerItem === 'settings',
                      })}
                      x1="6.9"
                      y1="4.4"
                      x2="1.5"
                      y2="4.4"
                    />
                    <line
                      className={classNames(styles.settingsIcon, styles.settingsLine, {
                        [styles.active]: ui.drawerItem === 'settings',
                      })}
                      x1="31.5"
                      y1="4.4"
                      x2="14.3"
                      y2="4.4"
                    />
                    <line
                      className={classNames(styles.settingsIcon, styles.settingsLine, {
                        [styles.active]: ui.drawerItem === 'settings',
                      })}
                      x1="20.8"
                      y1="16.5"
                      x2="1.5"
                      y2="16.5"
                    />
                    <line
                      className={classNames(styles.settingsIcon, styles.settingsLine, {
                        [styles.active]: ui.drawerItem === 'settings',
                      })}
                      x1="12.2"
                      y1="28.5"
                      x2="1.5"
                      y2="28.5"
                    />
                    <line
                      className={classNames(styles.settingsIcon, styles.settingsLine, {
                        [styles.active]: ui.drawerItem === 'settings',
                      })}
                      x1="31.5"
                      y1="28.5"
                      x2="19.6"
                      y2="28.5"
                    />
                    <line
                      className={classNames(styles.settingsIcon, styles.settingsLine, {
                        [styles.active]: ui.drawerItem === 'settings',
                      })}
                      x1="31.5"
                      y1="16.5"
                      x2="28.7"
                      y2="16.5"
                    />
                  </g>
                </svg>
              </div>
              <div
                className={classNames(styles.label, {
                  [styles.active]: ui.drawerItem === 'settings',
                })}
              >
                Settings
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateDrawer,
    },
    dispatch,
  );

const mapStateToProps = (state, ownProps) => ({
  ui: state.ui,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Menu);
