import React, { Component } from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect, isLoaded } from 'react-redux-firebase';
import classNames from 'classnames';
import { rateMediascape } from '../../redux/modules/uiReducer';

import styles from './Rating.module.scss';

class Rating extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentButton: 0,
    };

    this.maxSize = 1;
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKey);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKey);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { ratingsByUser, userId, resourceId, rateMediascape, onRated, isActive } = this.props;
    if (isLoaded(ratingsByUser) && ratingsByUser === null) {
      let payload = {
        userId,
        resourceId,
        rating: -1,
      };

      rateMediascape(payload);
    }
    if (isLoaded(ratingsByUser) && ratingsByUser >= 0) {
      onRated();
    }

    if (isActive && isActive !== prevProps.isActive) {
      this.setState({ currentButton: 0 });
    }
    if (!isActive && isActive !== prevProps.isActive) {
      this.setState({ currentButton: -1 });
    }
  }

  handleKey = e => {
    const { userId, resourceId, rateMediascape, isActive } = this.props;

    if (isActive) {
      let selectedItem = 0;
      let payload = {
        userId,
        resourceId,
      };

      switch (e.key) {
        case 'ArrowRight':
          if (this.state.currentButton < this.maxSize) {
            selectedItem = this.state.currentButton + 1;
          } else {
            selectedItem = 0;
          }
          this.setState({ currentButton: selectedItem });
          break;
        case 'ArrowLeft':
          if (this.state.currentButton > 0) {
            selectedItem = this.state.currentButton - 1;
          } else {
            selectedItem = this.maxSize;
          }
          this.setState({ currentButton: selectedItem });
          break;
        case 'Enter':
          if (this.state.currentButton === 0) {
            payload.rating = 1;
            rateMediascape(payload);
          }
          if (this.state.currentButton === 1) {
            payload.rating = 0;
            rateMediascape(payload);
          }

          break;

        default:
          break;
      }
    }
  };

  backToHome = () => {
    this.props.history.push('/');
  };

  reloadScape = () => {
    const currentUri = window.location.href;
    const myPath = process.env.PUBLIC_URL;
    window.location.href = `${myPath}/`;
    window.location.href = currentUri;
  };

  render() {
    const { ratingsByUser, isActive } = this.props;

    return (
      <div className={styles.rating}>
        {isLoaded(ratingsByUser) && (
          <div>
            <div className={styles.questionText}>Did you enjoy the story?</div>

            <div className={styles.buttonContainer}>
              <div
                className={classNames(styles.thumbContainter, {
                  [styles.hidden]: ratingsByUser !== 1 && !isActive,
                })}
              >
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50px" height="50px" viewBox="-5 0 45 46">
                  <path
                    className={classNames(styles.thumb, {
                      [styles.active]: this.state.currentButton === 0,
                      [styles.selected]: ratingsByUser === 1 && !isActive,
                    })}
                    d="M8.2,20.2v7.4c0,0.9-0.4,1.8-1.1,2.4c-0.4,0.3-1,0.5-1.5,0.6c-0.9,0-1.9,0-2.8,0
	c-1.3-0.2-2.3-1.2-2.3-2.5c0-0.2,0-0.3,0-0.5V12.7c-0.1-1.3,0.8-2.5,2.1-2.8c0.2-0.1,0.5-0.1,0.7-0.1c0.8,0,1.7,0,2.5,0.1
	C7,10,8,11,8.2,12.2c0,0.6,0.1,1.2,0.1,1.9C8.2,16.1,8.2,18.1,8.2,20.2L8.2,20.2z"
                  />
                  <path
                    className={classNames(styles.thumb, {
                      [styles.active]: this.state.currentButton === 0,
                      [styles.selected]: ratingsByUser === 1 && !isActive,
                    })}
                    d="M24.7,0.5H26c0.1,0,0.1,0.1,0.2,0.1c0.9,0,1.3,0.7,1,1.6c-0.5,1.3-0.9,2.6-1.4,3.9
	c-0.3,1-0.7,1.9-1,2.9c-0.1,0.3,0,0.7,0.4,0.9c0.1,0,0.1,0,0.2,0c0.2,0,0.3,0,0.5,0c3.2,0,6.4,0,9.5,0c0.6,0,1.3,0.1,1.9,0.3
	c0.9,0.3,1.8,0.6,2.1,1.6v0.7c0,0.1-0.1,0.2-0.1,0.3l-0.9,6c-0.3,2.1-0.6,4.2-1,6.4c-0.1,0.8-0.2,1.5-0.4,2.3
	c-0.3,0.8-0.8,1.6-1.5,2.2c-0.6,0.7-1.4,1.1-2.3,1.1H15.6c-1,0-1.4-0.4-1.4-1.4V11.3c0-0.9,0.4-1.7,1.1-2.2c2.2-1.7,4.2-3.8,5.6-6.2
	c0.6-1.1,1.7-1.8,2.9-2.1C24.1,0.6,24.4,0.6,24.7,0.5L24.7,0.5z"
                  />
                </svg>
              </div>
              <div
                className={classNames(styles.thumbContainter, {
                  [styles.hidden]: ratingsByUser !== 0 && !isActive,
                })}
              >
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xx="0px" y="0px" width="50px" height="50px" viewBox="0 -5 45 46">
                  <defs />
                  <path
                    id="Path_1313_1_"
                    className={classNames(styles.thumb, {
                      [styles.active]: this.state.currentButton === 1,
                      [styles.selected]: ratingsByUser === 0 && !isActive,
                    })}
                    d="M31.5,10.8V3.5c0-0.9,0.4-1.8,1.1-2.4c0.4-0.3,1-0.5,1.5-0.6c0.9,0,1.9,0,2.8,0
	c1.3,0.2,2.3,1.2,2.3,2.5c0,0.2,0,0.3,0,0.5v14.8c0.1,1.3-0.8,2.5-2.1,2.8c-0.2,0.1-0.5,0.1-0.7,0.1c-0.8,0-1.7,0-2.5-0.1
	c-1.2-0.1-2.2-1.1-2.3-2.3c0-0.6-0.1-1.2-0.1-1.9C31.5,14.9,31.5,12.9,31.5,10.8L31.5,10.8z"
                  />
                  <path
                    id="Path_1312_1_"
                    className={classNames(styles.thumb, {
                      [styles.active]: this.state.currentButton === 1,
                      [styles.selected]: ratingsByUser === 0 && !isActive,
                    })}
                    d="M15,30.5h-1.3c-0.1,0-0.1-0.1-0.2-0.1c-0.9,0-1.3-0.7-1-1.6c0.5-1.3,0.9-2.6,1.4-3.9
	c0.3-1,0.7-1.9,1-2.9c0.1-0.3,0-0.7-0.4-0.9c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3,0-0.5,0c-3.2,0-6.4,0-9.5,0c-0.6,0-1.3-0.1-1.9-0.3
	c-0.9-0.3-1.8-0.6-2.1-1.6v-0.7c0-0.1,0.1-0.2,0.1-0.3l0.9-6c0.3-2.1,0.6-4.2,1-6.4c0.1-0.8,0.2-1.5,0.4-2.3C3.2,3,3.7,2.2,4.4,1.6
	c0.6-0.7,1.4-1.1,2.3-1.1h17.4c1,0,1.4,0.4,1.4,1.4v17.9c0,0.9-0.4,1.7-1.1,2.2c-2.2,1.7-4.2,3.8-5.6,6.2c-0.6,1.1-1.7,1.8-2.9,2.1
	C15.7,30.4,15.4,30.4,15,30.5L15,30.5z"
                  />
                </svg>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      rateMediascape,
    },
    dispatch,
  );
const enhance = compose(
  firebaseConnect(props => [
    {
      path: `mediascapeRatings/byUser/${props.userId}/${props.resourceId}`,
      storeAs: 'ratingsByUser',
    },
  ]),
  connect(
    // Map redux state to component props
    ({ firebase: { data } }) => ({
      ratingsByUser: data.ratingsByUser,
    }),
    mapDispatchToProps,
  ),
);

export default enhance(Rating);
