import React, { Component } from 'react';
import { connect } from 'react-redux';
import { STORAGE } from '../../constants/index';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import { ScreenText, EndEvent } from '../index';
import { audioItemsSelector } from '../../selectors/cue-selectors';
import styles from './VideoPlayer.module.scss';

let sourceBuffer;

class VideoPlayer extends Component {
  constructor(props) {
    super(props);

    this.videoPlayer = React.createRef();
    this.state = {
      isPlaying: false,
      isLooping: false,
      currentVideoId: 0,
      currentIdleId: 0,
      readyForScreenText: false,
      endWord: -1,
      isPreloaded: false,
      isFinalVideo: false,
      showCompletedMenu: false,
      bufferIsReady: false,
      pendingTasks: []
    };
    // this.mimeCodec = 'video/mp4;codecs="avc1.42E01E, mp4a.40.2"';
    this.mimeCodec = 'video/mp4; codecs="avc1.64001f, mp4a.40.2"';

    this.handleVideoTimeUpdate = this.handleVideoTimeUpdate.bind(this);
    this.videoSourceOpen = this.videoSourceOpen.bind(this);
    this.cueClip = this.cueClip.bind(this);

    this.appendBufferToSourceBuffer = this.appendBufferToSourceBuffer.bind(this);

    this.mediaSource = null;

    this.status = 'init';

    this.loopCount = 0;
    this.minBufferDuration = 1; // seconds
    this.transitionDelay = 2;
    this.videoItems = [];
    this.isLooping = true;
    this.totalAppendCost = 0;
    this.isTaskRunning = false;
    this.maxSize = 1;
    this.endTime = 0;

    this.taskQueue = [];

    this.doGapless = window.location.hostname.includes('gapless');

    this.isTest = false;
    this.testVideos = [
      {
        url: 'https://s3.amazonaws.com/stb-storage/mediascapes/-LSqRIL6qK00d21pyuYi/video/1080/000_ani.mp4'
      },
      {
        url: 'https://s3.amazonaws.com/stb-storage/mediascapes/-LSqRIL6qK00d21pyuYi/video/1080/000_idle.mp4'
      },
      {
        url: 'https://s3.amazonaws.com/stb-storage/mediascapes/-LSqRIL6qK00d21pyuYi/video/1080/001_ani.mp4'
      },
      {
        url: 'https://s3.amazonaws.com/stb-storage/test_fragment.mp4'
      }
    ];
  }

  componentDidMount() {
    sourceBuffer = null;

    this.videoPlayer.current.addEventListener('timeupdate', this.handleVideoTimeUpdate);

    const _this = this;
    this.videoPlayer.current.addEventListener('stalled', (event) => {
      console.log('MSE: stalled. Failed to fetch data, but trying.');
    });
    this.videoPlayer.current.addEventListener('suspend', (event) => {
      console.log('MSE: suspend. Data loading has been suspended.');
    });
    this.videoPlayer.current.addEventListener('ended', (event) => {
      console.log('MSE: ended. Video stopped either because 1) it was over, '
        + 'or 2) no further data is available.');
    });
    this.videoPlayer.current.addEventListener('pause', (event) => {
      console.log('MSE: pause. The Boolean paused property is now true. Either the '
        + 'pause() method was called or the autoplay attribute was toggled.');
      if (_this.videoPlayer.current)
        _this.videoPlayer.current.play();

      console.log('MSE: try play again');
    });
    this.videoPlayer.current.addEventListener('emptied', (event) => {
      console.log('MSE: emptied. Uh oh. The media is empty. Did you call load()?');
    });
    this.videoPlayer.current.addEventListener('play', (event) => {
      console.log('MSE: play. The Boolean paused property is now false. Either the '
        + 'play() method was called or the autoplay attribute was toggled.');
    });
    this.videoPlayer.current.addEventListener('error', () => {
      console.error('MSE: Error loading.');
    });
    this.videoPlayer.current.addEventListener('ratechange', (event) => {
      console.log('MSE: ratechange. The playback rate changed.');
    });


    if ('MediaSource' in window && MediaSource.isTypeSupported(this.mimeCodec)) {
      this.mediaSource = new MediaSource();
      this.videoPlayer.current.src = URL.createObjectURL(this.mediaSource);

      if (this.isTest) {
        const _this = this;
        this.mediaSource.addEventListener('sourceopen', () => {
          _this.appendVideoElement(0);
        });
      } else {
        this.mediaSource.addEventListener('sourceopen', this.videoSourceOpen);
      }
    } else {
      console.error('Unsupported MIME type or codec: ', this.mimeCodec);
    }

    if (this.isTest) {
      const start_time = Date.now();
      setTimeout(() => {
        this.videoPlayer.current.play();
        console.log('start play', (Date.now() - start_time) / 1000, 'seconds');
      }, 1000);

      setTimeout(() => {
        this.appendVideoElement(1, 5);
        console.log('repeat idle once', (Date.now() - start_time) / 1000, 'seconds');
      }, 3000);

      setTimeout(() => {
        this.appendVideoElement(2, 7);
        console.log('repeat idle once', (Date.now() - start_time) / 1000, 'seconds');
      }, 5000);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { cueItems, shouldListen } = this.props;
    if (cueItems.bookmarkPos !== nextProps.cueItems.bookmarkPos && shouldListen) {
      this.mediascapeItemsFromBookmark(nextProps.cueItems);
    }
    if (cueItems && this.state.currentVideoId === -1) {
      this.mediascapeItemsFromBookmark(cueItems);
    }
  }

  onBufferTaskFinished() {
    const {
      pendingTasks
    } = this.state;

    console.log(`MSE: after >>>>> buffer [0, ${this.endTime}], player at ${this.videoPlayer.current.currentTime}`);

    this.taskQueue.shift();
    this.isTaskRunning = false;
    if (this.taskQueue.length > 0)
      this.setState({ pendingTasks: { action: 'next' } });
  }

  getDelay(scheduledstart) {
    const delay = 1000 * (scheduledstart - this.videoPlayer.current.currentTime);
    if (delay < 0)
      console.error('MSE:   player already passed the append point.');

    return delay;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.isTest) return;

    const {
      bufferIsReady,
      currentVideoId,
      currentIdleId,
      pendingTasks,
      isFinalVideo
    } = this.state;

    const {
      videoItems
    } = this.props;

    if (pendingTasks !== prevState.pendingTasks && pendingTasks.length > 0) {
      if (pendingTasks[0].action === 'next') {
        console.log('MSE:   continue next task');
      } else if (pendingTasks[0].action === 'append_idle' && this.taskQueue.length > 0) {
        console.log('MSE:   task queue is not empty, ignore append_idle.');
      } else {
        const _this = this;
        pendingTasks.forEach(function (item) {
          _this.taskQueue.push(item);
        });
      }
    }

    // processing the buffer task queue:
    if (this.taskQueue.length > 0 && !this.isTaskRunning) {
      this.isTaskRunning = true;
      console.log(`MSE: before >>>>> buffer [0, ${this.endTime}], player at ${this.videoPlayer.current.currentTime}`);
      console.log('MSE:   tasks in queue', this.taskQueue.length);
      const newTask = this.taskQueue[0];
      console.log('MSE:   first task', newTask);

      const nextVideoStartTime = this.endTime;

      if (newTask.action === 'remove') {

        this.removeBuffer(newTask.from).then(() => {
          this.onBufferTaskFinished();
        });

      }
      else if (newTask.action === 'append_anim') {

        let data = videoItems[newTask.index].animData;
        this.cueClip(data, newTask.offset).then(() => {

          // just hide text as soon as the append anim finishes, since text is useless before the next anim shows up.
          console.log('MSE:   hide text now', this.videoPlayer.current.currentTime);
          this.handleVideoPlaying();

          if (newTask.index === 0) {
            this.videoPlayer.current.play();
            console.log('MSE:   after first anim append, start play');
          }
          else {
            if (!this.doGapless) {
              console.log('MSE:   jump to the next video directly.');
              this.videoPlayer.current.currentTime = nextVideoStartTime;
            }
          }
          this.loopCount = 0;
          this.onBufferTaskFinished();
        });

      }
      else if (newTask.action === 'append_idle') {

        let data = videoItems[newTask.index].idleData;
        this.cueClip(data).then(() => {
          console.log('MSE:   loop count', this.loopCount);
          if (this.loopCount === 0) {
            // show text now, it can be at most minBufferDuration ahead of time.
            console.log('MSE:   show text now', this.videoPlayer.current.currentTime);
            this.handleVideoEnd();
          }
          this.loopCount++;
          if (isFinalVideo && this.loopCount > 0) {
            setTimeout(() => {
              this.setState({ showCompletedMenu: true });
            }, 2000);
          }

          this.onBufferTaskFinished();
        });

      }
      else {
        console.error('MSE:   error. undefined task.');
      }
    }

    if ((currentVideoId !== prevState.currentVideoId
      && currentVideoId !== currentIdleId) || bufferIsReady) {
      const index = videoItems.findIndex(x => x.id === currentVideoId);

      // add transition delay before appending next animation.
      let offset = this.videoPlayer.current.currentTime + this.transitionDelay;
      if (offset > this.endTime) { offset = -1; }

      if (!videoItems[index].animData) {
        console.error('MSE:   appending anim video at', index, 'is empty. Stop.');
        return;
      }

      const queue = [];
      // if there is an offset, then remove the buffer after the offset point.
      if (offset > 0) { queue.push({ action: 'remove', from: offset }); }
      // still need this offset before appending, otherwise, it may create holes in buffer.
      queue.push({ action: 'append_anim', index, offset: offset });
      this.setState({
        pendingTasks: queue,
        currentIdleId: currentVideoId,
        bufferIsReady: false
      });
    }
  }

  componentWillUnmount() {
    this.videoPlayer.current.removeEventListener('timeupdate', this.handleVideoTimeUpdate);
    // TODO: see if we actually need this
    // this.mediaSource.removeSourceBuffer();
    // this.mediaSource.endOfStream();
  }

  getVideoPaths(videoName, isIdle = false) {
    const { resources } = this.props;
    // const animPath = STORAGE.cfPath + videoName + this.mediaParam;
    const subPath = isIdle ? 'idle' : 'cutscene';
    const videoPath = `${STORAGE.StbS3Path}${resources.resourceId}/video/1080/${subPath}/${videoName}.mp4`;
    return videoPath;
  }

  /*
   * In the case of multiple timeranges grab the latest buffer end
   */

  getEndTime() {
    let latest = 0;
    if (sourceBuffer && sourceBuffer.buffered) {
      //console.log('MSE:   buffered length', sourceBuffer.buffered.length);
      const bufferCount = sourceBuffer.buffered.length;
      if (bufferCount !== 1) {
        console.error('MSE:   error. buffered length', bufferCount);
      }
      for (let r = 0; r < bufferCount; r++) {
        const start = sourceBuffer.buffered.start(r);
        const end = sourceBuffer.buffered.end(r);
        if (bufferCount !== 1)
          console.log('MSE:   buffer ', r, '[', start, ',', end, ']');
        latest = end > latest ? end : latest;
      }
    }
    //console.log('MSE:   getEndTime', latest);
    return latest;
  }

  // warn if SourceBuffer exceeded quota
  handleBufferMaxed = () => {
    // eslint-disable-next-line no-console
    console.warn('MSE:  SourceBuffer exceeded quota; attempting to recover');
  }

  // loop idle video
  appendIdleClip() {
    if (this.isEndOfSequence()) return;

    const {
      currentIdleId,
      currentVideoId,
      pendingTasks
    } = this.state;

    const {
      videoItems
    } = this.props;

    const index = currentIdleId === -1 ? 0 : currentIdleId;
    if (!videoItems[index].idleData) {
      console.error('MSE:   appending idle video at', index, 'is empty. Stop.');
      return;
    }
    if (currentIdleId === currentVideoId) {
      const queue = [];
      queue.push({ action: 'append_idle', index });
      this.setState({ pendingTasks: queue });
    }
  }

  isEndOfSequence() {
    if (!this.props.videoItems.length) {
      return true;
    }
    if (this.isLooping && this.state.currentVideoId === this.props.videoItems.length - 1) {
      if (!sourceBuffer.updating && this.mediaSource.readyState === 'open') {
        this.mediaSource.endOfStream();
      }
      const player = this.videoPlayer.current;
      player.pause();
      if (this.status !== 'completed') {
        setTimeout(() => {
          this.status = 'completed';
        }, 5000);
      }
    }

    return false;
  }

  handleVideoTimeUpdate() {
    if (this.isTest) return;

    //console.log('MSE: current video time', this.videoPlayer.current.currentTime, 'buffer', this.getEndTime(), 'mode', sourceBuffer.mode);
    if (sourceBuffer) {
      if (sourceBuffer.buffered && !sourceBuffer.buffered.length) {
        console.warn('MSE: SourceBuffer Empty');
      } else if (this.videoPlayer.current.currentTime >= this.endTime - this.minBufferDuration) {
        this.appendIdleClip();
      }
    }
  }

  videoSourceOpen() {
    if (!sourceBuffer) {
      sourceBuffer = this.mediaSource.addSourceBuffer(this.mimeCodec);

      // firefox need 'segments' mode to work properly. Chrome doesn't care.
      sourceBuffer.mode = 'segments';
      /*
      const sbMode = sourceBuffer.mode;
      if (sbMode === 'segments') {
        sourceBuffer.mode = 'sequence';
      }*/

      this.setState({
        bufferIsReady: true
      });
    }
  }

  handleVideoEnd() {
    const {
      readyForScreenText
    } = this.state;

    if (!readyForScreenText) {
      this.setState({ readyForScreenText: true },
        () => {
          console.log('MSE:   readyForScreenText updated', this.state.readyForScreenText);
        });
    }
  }

  handleVideoPlaying() {
    const {
      readyForScreenText
    } = this.state;

    if (readyForScreenText) {
      this.setState({
        readyForScreenText: false
      },
        () => {
          console.log('MSE:   readyForScreenText updated', this.state.readyForScreenText);
        }
      );
    }
  }

  mediascapeItemsFromBookmark(cueItems) {
    const { videoItems } = cueItems;
    const { textRanges } = cueItems;
    const { bookmarkPos } = cueItems;

    videoItems.forEach((item, index) => {
      if (item.isInitial && this.state.currentVideoId === -1) {
        this.setState({ currentVideoId: index });
      }
      if (parseInt(item.start) <= bookmarkPos && parseInt(item.stop) > bookmarkPos) {
        this.setState({ currentVideoId: index });
        if (index === videoItems.length - 1) {
          // mediascape completed
          this.setState({ isFinalVideo: true });
        }
      }
    });

    textRanges.forEach((item) => {
      if (parseInt(item.start) <= bookmarkPos && parseInt(item.stop) > bookmarkPos) {
        this.setState({ endWord: parseInt(item.stop) });
      }
    });
  }

  removeBuffer(start) {
    const _this = this;

    return new Promise(((resolve, reject) => {
      if (sourceBuffer && !sourceBuffer.updating) {
        try {
          sourceBuffer.remove(start, Infinity);
        } catch (error) {
          console.error(`MSE:   Argh! ${error}`);
        }
        sourceBuffer.addEventListener(
          'updateend',
          () => {
            _this.endTime = _this.getEndTime();
            resolve();
          },
          { once: true }
        );
      } else {
        reject();
      }
    }));
  }

  appendBufferToSourceBuffer(chunk, timestampOffset = -1) {
    try {
      //console.log('MSE: appendBufferToSourceBuffer');
      //console.log(chunk);
      //console.log(this.state.readyForScreenText);

      if (timestampOffset > 0) {
        //console.log('MSE:   append with offset', timestampOffset);
        sourceBuffer.timestampOffset = timestampOffset;
      } else {
        //console.log('MSE:   append to end', this.endTime);
        sourceBuffer.timestampOffset = this.endTime;
      }
      sourceBuffer.appendBuffer(chunk);
    } catch (error) {
      console.error('MSE:   error', error);
      if (error instanceof DOMException && error.name === 'QuotaExceededError') {
        sourceBuffer.trigger({
          segment: chunk,
          target: sourceBuffer,
          type: 'bufferMaxed'
        });
      } else {
        /* eslint-disable no-console */
        console.warn(error);
        console.warn(chunk);
        console.warn(sourceBuffer);
        /* eslint-enable no-console */
      }
    }
  }

  cueClip(chunk, timestampOffset = -1) {
    const videoplayer = this.videoPlayer.current;
    const that = this;

    return new Promise(((resolve, reject) => {
      if (sourceBuffer && !sourceBuffer.updating && chunk) {
        // add event for when the buffer is maxed
        sourceBuffer.addEventListener('bufferMaxed', (event) => {
          that.handleBufferMaxed(event);
        });

        const lastEndTime = that.endTime;
        that.appendBufferToSourceBuffer(chunk, timestampOffset);
        sourceBuffer.addEventListener(
          'updateend',
          function onPlayChunkUpdateEnd() {
            sourceBuffer.removeEventListener('updateend', onPlayChunkUpdateEnd);
            that.endTime = that.getEndTime();
            //console.log('MSE:   append end at', videoplayer.currentTime, 'at offset', timestampOffset);
            if (timestampOffset > 0 && videoplayer.currentTime > timestampOffset)
              console.error('MSE:   error. player already passed the offset timestamp.');

            if (Math.abs(lastEndTime - that.endTime) < 0.1)
              console.error('MSE:   append failed. buffer is not changed.');

            resolve();
          },
          false,
        );
      } else {
        reject();
      }
    }));
  }

  async appendVideoElement(resourcesIndex, offset = -1) {
    const _this = this;
    /* if (resourcesIndex === this.videoResources.length) {
         this.mediaSource.endOfStream();
         return;
       } */

    try {
      let sourceBuffer;
      const resource = this.testVideos[resourcesIndex];

      if (this.mediaSource.sourceBuffers.length === 0) {
        // console.log('Adding source buffer with type: ' + _this.mimeCodec);
        sourceBuffer = this.mediaSource.addSourceBuffer(_this.mimeCodec);
        // The 'sequence' mode is used here purely for simplicity. A more
        // sophisticated app would know which timestampOffset and appendWindow to
        // use for each appendBuffer operation when splicing media.
        sourceBuffer.mode = 'sequence';
      } else {
        // console.log('Changing source buffer type to: ' + _this.mimeCodec);
        sourceBuffer = this.mediaSource.sourceBuffers[0];
        // sourceBuffer.changeType(resource.type);
      }

      console.log('Fetching video data...');
      const response = await fetch(resource.url);
      const data = await response.arrayBuffer();

      console.log('Appending data to video element...');
      if (offset > 0) { sourceBuffer.timestampOffset = offset; }
      sourceBuffer.appendBuffer(data);
      sourceBuffer.addEventListener(
        'updateend',
        () => {
          console.log('Source buffer updated');
        },
        { once: true },
      );
    } catch (error) {
      console.log(`Argh! ${error}`);
      // this.feedVideoElement(++resourcesIndex);
    }
  }

  render() {
    const {
      resources, cueItems, history, userId, audioItems
    } = this.props;
    const hasTextRanges = cueItems.textRanges.length > 0;

    return (
      <div>
        {this.state.showCompletedMenu && <EndEvent history={history} resourceId={resources.resourceId} userId={userId} />}
        {hasTextRanges && (
          <ScreenText
            displayText={resources.corpus}
            isVideoReady={this.state.readyForScreenText}
            endWord={this.state.endWord}
            resourceId={resources.resourceId}
            cueStatus={cueItems.status}
          />
        )}
        <div className={styles.stbVideo}>
          <video ref={this.videoPlayer} width="100%" height="auto" playsInline />
          {/* {audioItems.length > 0 && <AudioPlayer />} */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  videoSize: state.ui.videoSize,
  resources: state.resources,
  cueItems: state.cueItems,
  audioItems: audioItemsSelector(state)
});

export default connect(mapStateToProps)(VideoPlayer);
