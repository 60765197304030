import React, { Component } from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import ReactGA from 'react-ga';
import { cleanText } from '../../helpers';
import { DictateClass, EventCodes } from '../../speech';

import { updateCueStatus } from '../../redux/modules/cueReducer';
import { updateNotification } from '../../redux/modules/uiReducer';
import {
  updateReadyForSpeech,
  updateListeningState,
  updateServerStatus,
  updateAsrResults,
  updateOutputResults,
  updateBookmark,
  updateMediaStreamStatus,
  updateProcessedMediascape,
  setServers,
  updateSpeechClientReady,
} from '../../redux/modules/speechReducer';
// import { Object } from 'es6-shim';

let dictate;

class SpeechProcssing extends Component {
  constructor(props) {
    super(props);
    this.handleReadyForSpeech = this.handleReadyForSpeech.bind(this);
    this.handleEvents = this.handleEvents.bind(this);
    this.handleErrors = this.handleErrors.bind(this);
    this.handleBookmark = this.handleBookmark.bind(this);
    this.formatBookData = this.formatBookData.bind(this);
    this.handleSpeechClientReady = this.handleSpeechClientReady.bind(this);
    this.handleServerStatus = this.handleServerStatus.bind(this);

    this.wsProtocol = 'wss://';
    this.port = process.env.REACT_APP_CLOUD_ASR_PORT;
    // this.bkport = process.env.REACT_APP_CLOUD_ASR_BKPORT;
    this.asrServerAddress = process.env.REACT_APP_CLOUD_ASR_NAME;

    this.playerServer = `${this.wsProtocol}${this.asrServerAddress}:${this.port}/player`;
    this.speakerServer = `${this.wsProtocol}${this.asrServerAddress}:${this.port}/client`;

    this.sessionID = `${props.speech.sessionId}`;

    this.isCloudASRConnected = false;
    this.isDictateInit = false;

    this.processedMediascape = false;

    this.VZUAL = global.VZUAL || {};

    this.isDevEnv = process.env.NODE_ENV === 'development';
  }

  componentDidMount() {
    const { setServers, device } = this.props;
    const isSTB = device.currentDevice.toLowerCase().includes('stb');
    sessionStorage.clear();
    setServers({ sessionID: this.sessionID, dictateServer: this.dictateServer, bookmarkingServer: this.serverBK });
    if (!isSTB && navigator.permissions) {
      navigator.permissions.query({ name: 'microphone' }).then(result => {
        let inputStatus = result.state;
        console.log(inputStatus);
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { cueItems, speech } = this.props;

    const newCues = cueItems.videoItems.length !== nextProps.cueItems.videoItems.length;
    if (newCues) {
      return true;
    }

    const newSpeech = speech !== nextProps.speech;
    if (newSpeech) {
      return true;
    }
    return true;
  }

  componentWillUnmount() {
    if (this.VZUAL && this.VZUAL.VoiceClient && this.VZUAL.VoiceClient.stopNovelSession) {
      this.VZUAL.VoiceClient.stopNovelSession();
    }
  }

  initializeDictate() {
    const { device } = this.props;
    const isSTB = device.isVZSTB;
    this.setupDictate();
    dictate.init();
    // console.log(`%c 🎤 sessionID: ${this.sessionID} `, 'background: #222; color: #bada55; padding: 10px; border: 1px solid #bada55; font-size: 1.5em;');

    dictate.setServer(this.playerServer, this.speakerServer, this.sessionID, isSTB);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { title, updateCueStatus, ui, updateReadyForSpeech, speech, updateProcessedMediascape, resources, cueItems } = this.props;
    // const newCues = cueItems.videoItems.length !== prevProps.cueItems.videoItems.length;
    if (resources.corpus) {
      if (!this.processedMediascape && this.isDictateInit) {
        this.processedMediascape = true;
        updateProcessedMediascape(true);
        this.processSoundscape();
      }

      if (!this.isDictateInit) {
        this.isDictateInit = true;
        this.initializeDictate();
      }
    }

    if (prevProps.speech.mediaStreamCreated === false && speech.mediaStreamCreated && !speech.isReadyForSpeech) {
      updateReadyForSpeech(true);
    }

    if (!prevProps.title || title !== prevProps.title) {
      if (cueItems.status !== 'selected') updateCueStatus('selected');
    }

    if (ui.isPlaying !== prevProps.ui.isPlaying) {
      this.toggleListening();
    }

    if (speech.isMuted !== prevProps.speech.isMuted) {
      if (speech.isMuted) {
        if (dictate) {
          dictate.mute();
        }
      } else {
        if (dictate) {
          setTimeout(() => {
            dictate.unmute();
          }, 50);
        }
      }
    }

    if (speech.bookmarkTextBlock !== prevProps.speech.bookmarkTextBlock) {
      dictate.setBookmarkTextBlock(speech.bookmarkTextBlock);
    }
  }

  testWSConnection(url) {
    var conn = new WebSocket(url);
    conn.onmessage = function(e) {};
    conn.onopen = () => conn.send('hello');
  }

  setupDictate() {
    let dictateConfig = {
      server: this.dictateServer,
      onReadyForSpeech: this.handleReadyForSpeech,
      onSpeechClientReady: this.handleSpeechClientReady,
      onEndOfSpeech: this.handleEndOfSpeech,
      onEndOfSession: this.handleEndOfSession,
      onServerStatus: this.handleServerStatus,
      onBookmark: this.handleBookmark,
      onError: this.handleErrors,
      onEvent: this.handleEvents,
      selectionStart: 0,
    };

    dictate = new DictateClass(dictateConfig);
  }

  handleEndOfSpeech() {}

  handleEndOfSession() {}

  handleSpeechClientReady(isReady) {
    const { updateSpeechClientReady } = this.props;
    console.log('speechClientReady', isReady);
    updateSpeechClientReady(isReady);
  }

  handleServerStatus(json) {
    const { updateMediaStreamStatus } = this.props;
    console.log('handleServerStatus', json);
    updateMediaStreamStatus(json.workerReady);
  }

  handleBookmark(p) {
    const { updateCueStatus, speech, updateBookmark } = this.props;

    if (p >= speech.outputResults.length - 1) {
      updateCueStatus('end');
    }
    updateBookmark(p);
  }

  handleErrors(code, data) {
    const { updateNotification } = this.props;
    let errMsg = 'ERROR: ' + code + ': ' + (data || '');
    console.warn(errMsg);
    console.warn(code, data);
    dictate.cancel();
    if (process.env.NODE_ENV === 'production') {
      ReactGA.exception({
        description: errMsg,
        fatal: true,
      });
    }

    if (code === 2 && !data) {
      errMsg = 'Websocket connection failed (504)';
    }
    const errPayload = {
      type: 'error',
      code,
      message: errMsg,
    };
    updateNotification(errPayload);
  }

  handleEvents(code, data) {
    const { ui, updateServerStatus, updateMediaStreamStatus, speech } = this.props;
    if (typeof data === 'string') {
      let tempArr = [...speech.currentServerStatus];
      if (this.isDevEnv) {
        console.log(`%c ServerStatus: ${data} `, 'background: #222; color: #4bb6c2');
      }
      tempArr.unshift(data);

      if (ui.isDebugMode) {
        updateServerStatus(tempArr);
      }
      if (data.startsWith('sentences:')) {
        updateMediaStreamStatus(true);
        // this.setState({ workerReady: true });
      }
    }

    if (code === EventCodes.MSG_SEND) {
      // handle message
      // console.log('data', data);
    }

    if (code === EventCodes.MSG_MEDIA_STREAM_CREATED) {
      updateMediaStreamStatus(true);
      // this.setState({ workerReady: true });
    }

    if (code === EventCodes.MSG_SERVER_CHANGED) {
      if (this.VZUAL && this.VZUAL.VoiceClient && this.VZUAL.VoiceClient.startNovelSession) {
        // console.log('starting VZUAL Voiceclient', this.sessionID);

        this.VZUAL.VoiceClient.startNovelSession(this.sessionID);
      }
    }

    if (data === 'Stopped recording') {
      console.log('Stopped recording');
      // this.isCloudASRConnected = false;
      // TODO: are these the correct values to set?
      // this.setState({
      //   isCloudASRConnected: false,
      //   isStarted: false,
      // });
    }
  }

  handleReadyForSpeech() {
    const { updateListeningState } = this.props;
    if (!this.isCloudASRConnected) {
      this.isCloudASRConnected = true;

      setTimeout(updateListeningState(true), 1000);
    }
  }

  toggleListening() {
    const { updateCueStatus, ui, updateListeningState, updateAsrResults, device } = this.props;
    const isSTB = device.isVZSTB && !device.currentDevice.includes('web based');

    if (dictate) {
      if (!ui.isPlaying) {
        if (!isSTB) {
          dictate.stopListening();
        }
        this.isCloudASRConnected = false;
        updateListeningState(false);
        updateAsrResults('');
        updateBookmark(-1);
        window.location.reload();
      } else {
        if (!isSTB) {
          dictate.startListening();
        }
        updateCueStatus('started');
      }
    }
  }

  processSoundscape() {
    const { updateOutputResults, resources } = this.props;
    let bookData = this.formatBookData();

    let corpus = resources.asrWords ? resources.asrWords : [];

    let soundScape = {
      book_data: bookData,
      corpus,
    };

    console.log('bookdataArr', soundScape);

    let soundscapeStr = JSON.stringify(soundScape);
    // TODO: move this to after user interaction
    dictate.createBookmarking(soundscapeStr);
    updateOutputResults(corpus);
  }

  formatBookData() {
    const { cueItems, resources } = this.props;
    const bookText = resources ? resources.corpus : '';
    let fixedText = cleanText(bookText, true);
    let corpus = fixedText.split(' ');

    let bookdataArr = [];
    if (cueItems) {
      cueItems.textRanges.forEach(cue => {
        const bookDataObj = {
          name: 'textranges',
          type: 'text',
          mediaType: 'text',
          start_word: parseInt(cue.start, 10),
          end_word: parseInt(cue.stop, 10),
        };
        bookdataArr.push(bookDataObj);
      });

      if (cueItems.textRanges.length === 0) {
        const bookDataObj = {
          name: 'textranges',
          type: 'text',
          mediaType: 'text',
          start_word: 0,
          end_word: corpus.length - 1,
        };
        bookdataArr.push(bookDataObj);
      }
      cueItems.audioItems.forEach(cue => {
        const bookDataObj = {
          mediaType: 'audio',
          file: cue.name,
          start_word: parseInt(cue.start, 10),
          end_word: parseInt(cue.stop, 10),
        };
        bookdataArr.push(bookDataObj);
      });

      cueItems.videoItems.forEach(cue => {
        const bookDataObj = {
          name: cue.animation,
          idle: cue.idle,
          type: 'video',
          mediaType: 'video',
          start_word: parseInt(cue.start, 10),
          end_word: parseInt(cue.stop, 10),
        };
        bookdataArr.push(bookDataObj);
      });
    }

    bookdataArr.sort(function(a, b) {
      return a.start_word - b.start_word;
    });

    return bookdataArr;
  }

  render() {
    return <div />;
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateCueStatus,
      updateNotification,
      updateReadyForSpeech,
      updateListeningState,
      updateServerStatus,
      updateAsrResults,
      updateOutputResults,
      updateBookmark,
      updateMediaStreamStatus,
      updateProcessedMediascape,
      setServers,
      updateSpeechClientReady,
    },
    dispatch,
  );
const enhance = compose(
  firebaseConnect(props => [
    {
      path: `webclient/session/${props.userid}/voice/status`,
      storeAs: 'voiceStatus',
    },
  ]),
  connect(
    ({ firebase: { data, profile }, resources, cueItems, ui, speech, device }) => ({
      voiceStatus: data.voiceStatus,
      profile,
      resources,
      cueItems,
      ui,
      speech,
      device,
    }),
    mapDispatchToProps,
  ),
);

export default enhance(SpeechProcssing);
