import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { areImagesLoaded } from '../../selectors/ui-selectors';

const StbCollectionTitle = ({ allImagesLoaded, helpText }) => (
  <div>
    {allImagesLoaded && helpText}
  </div>
);

StbCollectionTitle.propTypes = {
  allImagesLoaded: PropTypes.bool,
  helpText: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  allImagesLoaded: areImagesLoaded(state)
});

export default connect(mapStateToProps, null)(StbCollectionTitle);
