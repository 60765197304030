import React from 'react';
import classNames from 'classnames';
import styles from './DebugButton.module.scss';

const DebugButton = ({ onClick, isDebug }: Props) => {
  const componentProps = {
    className: classNames(styles.debugIcon, {
      [styles.active]: isDebug,
    }),
  };

  return (
    <div className={styles.debugMode} onClick={onClick} title="Enables debug view">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 90 88.1">
        <path
          d="M15.8,28.1l-6-6c-1.1-1.1-2.9-1.1-4.1,0c-1.1,1.1-1.1,3,0,4.1l6.9,6.5c0.5,0.5,1.3,0.4,2,0.4h5.6c-4,5-6.6,11-7.6,17H2.9
c-1.6,0-2.9,1.4-2.9,3c0,1.6,1.3,3,2.9,3h9.3c0,5,1.3,10,3.5,14h-2.1c-0.8,0-1.5,0.4-2,1l-6.9,7c-1.1,1.1-1.1,3,0,4.1
C5.3,82.7,6,83,6.8,83c0.7,0,1.5-0.3,2-0.8l6-6.1h4.6c5.6,7,14.5,11.5,23.5,12.1V28.1H15.8z"
          className={componentProps.className}
        />
        <path
          d="M87.1,50.1h-10c-1.2-6-4.4-12-8.2-17h6.4c0.8,0,1.5,0.1,2-0.4l6.9-6.7c1.1-1.1,1.1-2.8,0-4c-1.1-1.1-2.9-1.1-4.1,0l-6,6H47
v60c9-0.6,17.7-6,23.4-12h4.8l6,6.1c0.6,0.6,1.3,0.8,2,0.8c0.7,0,1.5-0.3,2-0.8c1.1-1.1,1.1-2.9,0-4.1l-6.9-7c-0.5-0.5-1.3-1-2-1
h-2.2c2.2-4,3.5-9,3.6-14h9.4c1.6,0,2.9-1.4,2.9-3C90,51.5,88.7,50.1,87.1,50.1z"
          className={componentProps.className}
        />
        <path
          d="M26.4,24.1h37.2c0-7-3.5-12.3-8.7-15.6l3.8-3.7c1.1-1.1,1.1-2.9,0-4c-1.1-1.1-2.9-1.1-4.1,0l-5.4,5.4
C47.8,6,46.4,5.8,45,5.8S42.2,6,40.8,6.3l-5.4-5.4c-1.1-1.1-2.9-1.1-4.1,0s-1.1,2.9,0,4.1l3.8,3.6C29.9,11.8,26.4,17.1,26.4,24.1z"
          className={componentProps.className}
        />
      </svg>
    </div>
  );
};

export default DebugButton;
