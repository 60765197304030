import React, { Component } from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import classNames from 'classnames';
import { STORAGE } from '../../constants/index';
import { logStatus } from '../../redux/modules/userReducer';
import styles from './ImageViewer.module.css';

class ImageViewer extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { event } = this.props;
    if (nextProps.event && nextProps.event.event_name === 'sceneIndex') {
      if (nextProps.event.event_data !== event.event_data) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  imagePathBuilder(images) {}
  render() {
    const { resourceId, event, resources } = this.props;

    // let imageVal = '001';
    let currenIndex = 0;
    if (event && event.event_name === 'sceneIndex') {
      currenIndex = event.event_data - 1;
    }

    const imagesWithPath = resources.imageItems.map(imageId => {
      const imagePath = `${STORAGE.fullPath}${resourceId}%2Fimages%2F${imageId}.png?alt=media`;
      return imagePath;
    });

    return (
      <div className={styles.imageViewer}>
        {imagesWithPath.map((path, index) => (
          <img
            className={classNames(styles.imageContainer, { [styles.hidden]: currenIndex !== index })}
            key={index}
            ref={index}
            src={path}
            width="100%"
            height="auto"
            alt="Book Page"
          />
        ))}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logStatus,
    },
    dispatch,
  );
const enhance = compose(
  firebaseConnect(props => [
    {
      path: `webclient/session/${props.pair.userId}/event`,
      storeAs: 'event',
    },
  ]),
  connect(
    // Map redux state to component props
    ({ firebase: { data } }) => ({
      event: data.event,
    }),
    mapDispatchToProps,
  ),
);

const mapStateToProps = state => ({
  pair: state.pair,
  resources: state.resources,
});

export default connect(mapStateToProps)(enhance(ImageViewer));
