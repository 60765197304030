export const CORPUS = `Welcome to Everwilde! Home to Felicity Fox and her Enchantimal friends.
Here comes Felicity now, back from a day full of adventure.
Hi Felicity. Nice to meet you Flick!
What adventures did you go on today?
That sounds like so much fun.
Felicity and Flick sure had a fun day with lots of adventure. Let's head downstream on the babbling brook that connects all of Everwilde.
Look over there! It's Chief Tech Designer, maker and baker Bree Bunny and her assistant Twist, building a sandwich making machine.
Keep your ears crossed. Go Twist!
What's the matter Bree? Is it working?
Wow, that looks super great!
Hey, is it supposed to do that?
Sage Skunk and Caper like to play music! Look at Sage rocking out on the guitar. Caper is playing the drums. They also like jokes! Let's see if they have any. How about it Sage?
Hm, I don't know, that's a tricky one.
Let's go visit Wonderwood Village in the heart of Everwilde and see what's happening! There are so many happy and playful creatures of nature here! Look bees!
Listen closely to the Crickets!
The birds love being part of the fun!
And look at the beautiful butterflies!
Thank you Felicity and friends for sharing your adventures and making today special.
Everwilde is such an amazing place full of adventure, friendship and wonder.
See you later, everyone!`;
