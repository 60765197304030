import React from 'react';
import styles from './FullScreenToggle.module.scss';

const FullScreenToggle = ({ onClick, isFullScreen }: Props) => {
  return (
    <div className={styles.fullscreenMode} onClick={onClick} title="toggle fullscreen">
      {!isFullScreen && (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 39.243 39.243">
          <g id="fullscreen" transform="translate(-335.567 -394.879)">
            <g id="Group_1588" data-name="Group 1588" transform="translate(358.415 397)">
              <g id="Group_1586" data-name="Group 1586" transform="rotate(45 -22.03 17.577)">
                <path id="Path_1292" d="M0 0l4.225 4.225L0 8.451" className={styles.fullScreenIcon} data-name="Path 1292" transform="translate(7.51)" />
                <path id="Path_1293" d="M0 0h11.547" className={styles.fullScreenIcon} data-name="Path 1293" transform="translate(0 4.225)" />
              </g>
              <g id="Group_1587" data-name="Group 1587" transform="rotate(-45 10.017 4.149)">
                <path id="Path_1292-2" d="M0 0l4.225 4.225L0 8.451" className={styles.fullScreenIcon} data-name="Path 1292" transform="translate(7.51)" />
                <path id="Path_1293-2" d="M0 0h11.547" className={styles.fullScreenIcon} data-name="Path 1293" transform="translate(0 4.225)" />
              </g>
            </g>
            <g id="Group_1589" data-name="Group 1589" transform="translate(337.689 397)">
              <g id="Group_1586-2" data-name="Group 1586" transform="rotate(135 1.607 16.307)">
                <path id="Path_1292-3" d="M0 8.451l4.225-4.225L0 0" className={styles.fullScreenIcon} data-name="Path 1292" transform="translate(7.511)" />
                <path id="Path_1293-3" d="M0 0h11.547" className={styles.fullScreenIcon} data-name="Path 1293" transform="translate(0 4.226)" />
              </g>
              <g id="Group_1587-2" data-name="Group 1587" transform="rotate(-135 7.106 5.418)">
                <path id="Path_1292-4" d="M0 8.451l4.225-4.225L0 0" className={styles.fullScreenIcon} data-name="Path 1292" transform="translate(7.511)" />
                <path id="Path_1293-4" d="M0 0h11.547" className={styles.fullScreenIcon} data-name="Path 1293" transform="translate(0 4.226)" />
              </g>
            </g>
          </g>
        </svg>
      )}

      {isFullScreen && (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 28.243 28.243">
          <g id="exit_fullscreen" transform="translate(-458.878 -400.189)">
            <g id="Group_1588" data-name="Group 1588" transform="translate(476.036 402.311)">
              <g id="Group_1586" data-name="Group 1586" transform="rotate(-135 6.465 11.381)">
                <path id="Path_1292" d="M0 0l4.225 4.225L0 8.451" className={styles.fullScreenIcon} data-name="Path 1292" />
              </g>
              <g id="Group_1587" data-name="Group 1587" transform="rotate(135 3.244 4.844)">
                <path id="Path_1292-2" d="M0 0l4.225 4.225L0 8.451" className={styles.fullScreenIcon} data-name="Path 1292" />
              </g>
            </g>
            <g id="Group_1589" data-name="Group 1589" transform="translate(461 402.311)">
              <g id="Group_1586-2" data-name="Group 1586" transform="rotate(45 2.988 7.214)">
                <path id="Path_1292-3" d="M0 0l4.225 4.225L0 8.451" className={styles.fullScreenIcon} data-name="Path 1292" />
              </g>
              <g id="Group_1587-2" data-name="Group 1587" transform="rotate(-45 21.757 9.012)">
                <path id="Path_1292-4" d="M0 0l4.225 4.225L0 8.451" className={styles.fullScreenIcon} data-name="Path 1292" />
              </g>
            </g>
          </g>
        </svg>
      )}
    </div>
  );
};

export default FullScreenToggle;
