import React, { Component } from 'react';
import styles from './Modal.module.scss';

class Modal extends Component {
  render() {
    const { children } = this.props;

    const childComponent = children && <span className={styles.children}>{children}</span>;
    return (
      <div className={styles.modalContainer}>
        <div className={styles.modalBox} />
        {childComponent}
        <div />
      </div>
    );
  }
}

export default Modal;
