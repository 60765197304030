import React, { Component } from 'react';
import {
  compose,
  bindActionCreators
} from 'redux';
import { connect } from 'react-redux';
import {
  firebaseConnect,
  isLoaded,
  isEmpty
} from 'react-redux-firebase';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Object } from 'es6-shim';
import {
  AudioManager,
  TitleID,
  BookmarkManager,
  VideoManager,
  VideoManagerData,
  Loading,
  TextRangeManager,
  StartButton,
  TopBar,
  BottomBar,
  Modal,
  DrawerManager,
  VideoPreloader,
  SpeechProcessing,
  Soundscape
} from '../../components';
import { setEventData } from '../../redux/modules/userReducer';
import {
  updateCueStatus,
  hydrateCueDataFromFirebase
} from '../../redux/modules/cueReducer';
import {
  updateNotification,
  updateShelfStatus
} from '../../redux/modules/uiReducer';
import { updateMutedStatus } from '../../redux/modules/speechReducer';
import DebugMode from './DebugMode';
import Title from './Title';
import styles from './Mediascape.module.scss';

class Mediascape extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      setEventData: PropTypes.func.isRequired,
      updateCueStatus: PropTypes.func.isRequired,
      updateNotification: PropTypes.func.isRequired,
      updateShelfStatus: PropTypes.func.isRequired,
      updateMutedStatus: PropTypes.func.isRequired,
      hydrateCueDataFromFirebase: PropTypes.func.isRequired
    }).isRequired,
    cueItems: PropTypes.shape({
      mediaItems: PropTypes.array,
      videoItems: PropTypes.array
    }).isRequired,
    history: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        resourceid: PropTypes.string
      })
    }).isRequired,
    nfxMedia: PropTypes.shape({
      title: PropTypes.string
    }),
    profile: PropTypes.shape({}).isRequired,
    packagedMedia: PropTypes.shape({
      title: PropTypes.string
    }),
    resources: PropTypes.shape({
      corpus: PropTypes.string
    }).isRequired,
    speech: PropTypes.shape({
      isReadyForSpeech: PropTypes.bool,
      isListening: PropTypes.bool
    }).isRequired,
    ui: PropTypes.shape({
      isPlaying: PropTypes.bool,
      isFullScreen: PropTypes.bool,
      isShelfActive: PropTypes.bool
    }).isRequired,
    userid: PropTypes.string.isRequired
  };

  static defaultProps = {
    packagedMedia: null,
    nfxMedia: null
  }

  constructor(props) {
    super(props);
    this.state = {
      displayDebug: false,
      overrideHide: false
    };

    this.idleTimer = null;
    this.idleWait = 2000;

    this._isMounted = false;
  }

  componentDidMount() {
    const { actions, match } = this.props;
    sessionStorage.clear();

    this._isMounted = true;

    actions.hydrateCueDataFromFirebase(match.params);

    if (navigator.permissions) {
      navigator.permissions.query({ name: 'microphone' }).then((result) => {
        const inputStatus = result.state;
        /* eslint-disable no-console */
        console.log('inputStatus', inputStatus);
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      actions, cueItems, packagedMedia, nfxMedia, ui
    } = this.props;

    if (isLoaded(packagedMedia) && isLoaded(nfxMedia) && !isEmpty(nfxMedia)) {
      const title = packagedMedia ? packagedMedia.title : nfxMedia.title;

      let prevTitle;
      if (prevProps.packagedMedia) {
        prevTitle = prevProps.packagedMedia.title;
      } else if (prevProps.nfxMedia) {
        prevTitle = prevProps.nfxMedia.title;
      }
      if (title !== prevTitle) {
        if (process.env.NODE_ENV === 'production') {
          ReactGA.event({
            category: 'Mediascape',
            action: 'User has selected Mediascape',
            label: title
          });
        }

        actions.updateCueStatus('selected');
      }

      if (
        ui.isPlaying
        && ui.isPlaying !== prevProps.ui.isPlaying
        && cueItems.mediaItems.length === 0
        && cueItems.videoItems.length === 0
      ) {
        // to unmute we have to mute first
        actions.updateMutedStatus(true);
        setTimeout(() => {
          actions.updateMutedStatus(false);
        }, 250);
      }
    }

    if (ui.isPlaying !== prevProps.ui.isPlaying) {
      const title = packagedMedia ? packagedMedia.title : nfxMedia.title;
      if (process.env.NODE_ENV === 'production') {
        ReactGA.event({
          category: 'Mediascape',
          action: 'User has started mediascape',
          label: title
        });
      }
    }

    if (ui.isFullScreen !== prevProps.ui.isFullScreen) {
      this.toggleFullScreen(ui.isFullScreen);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toggleFullScreen = (isFullScreen) => {
    const elem = document.documentElement;

    if (isFullScreen) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen();
    }
  }

  getEmptyMessage = () => "The video you're looking for is no longer available. Please contact for details"

  handleMouseEnter() {
    const { actions, ui } = this.props;

    if (!ui.isShelfActive) {
      actions.updateShelfStatus(true);
    }
  }

  handleMouseOut() {
    const { actions, ui } = this.props;

    if (ui.isShelfActive) {
      actions.updateShelfStatus(false);
    }
  }

  handleShelfEnter() {
    this.setState({ overrideHide: true });
  }

  handleShelfLeave() {
    this.setState({ overrideHide: false });
  }

  handleMouseMove() {
    const { actions } = this.props;
    const { overrideHide } = this.state;

    clearTimeout(this.idleTimer);
    actions.updateShelfStatus(true);
    if (!overrideHide) {
      this.idleTimer = setTimeout(() => {
        actions.updateShelfStatus(false);
      }, this.idleWait);
    }
  }

  render() {
    const {
      resources, match, profile, packagedMedia, nfxMedia, cueItems, ui, speech, userid, history
    } = this.props;

    const {
      displayDebug
    } = this.state;

    if (this._isMounted) {
      if (isLoaded(packagedMedia) && isLoaded(nfxMedia) && !isEmpty(nfxMedia)) {
        const isAudioOnly = cueItems.mediaItems.length === 0 && cueItems.videoItems.length === 0;
        const useVideoData = cueItems.videoItems.length > 0;
  
        let splashImageStyle;
  
        const currentResource = packagedMedia || nfxMedia;
        if (currentResource && currentResource.images) {
          Object.keys(currentResource.images).forEach((key) => {
            if (currentResource.images[key].type === 'mediascapeteaser') {
              const splashImage = currentResource.images[key].downloadURL;
              splashImageStyle = { backgroundImage: `url(${splashImage}` };
            }
          });
        }
        return (
          <div
            className={styles.mediascape}
            onMouseEnter={() => this.handleMouseEnter()}
            onMouseLeave={() => this.handleMouseOut()}
            onMouseMove={() => this.handleMouseMove()}
          >
            <SpeechProcessing match={match} userid={userid} />
            {ui.isPlaying && (
              <div>
                <TopBar
                  history={history}
                  isHidden={!ui.isShelfActive}
                  onMouseEnter={() => this.handleShelfEnter()}
                  onMouseLeave={() => this.handleShelfLeave()}
                />
                <BottomBar
                  isHidden={!ui.isShelfActive}
                  profile={profile}
                  readyForSpeech={speech.isReadyForSpeech}
                  onMouseEnter={() => this.handleShelfEnter()}
                  onMouseLeave={() => this.handleShelfLeave()}
                  isVideo={!isAudioOnly}
                />
                <DrawerManager match={match} />
              </div>
            )}
            <Title displayTitle={currentResource.title} />
            <TitleID
              resourceId={match.params.resourceid}
              resourceDescription={currentResource.description}
              title={currentResource.title}
              images={currentResource.images}
              soundscapeId={currentResource.soundscapeId}
              match={match}
            />
            <BookmarkManager />
            <DebugMode displayDebug={displayDebug} />
            {ui.isPlaying && <TextRangeManager />}
            <div
              className={classNames(styles.videoSplash, {
                [styles.hidden]: ui.isPlaying
              })}
              style={splashImageStyle}
            />
            <AudioManager resourceId={match.params.resourceid} shouldListen={speech.isListening} />
            {isAudioOnly && <Soundscape match={match} />}
            {!isAudioOnly && (
              <div>
                <VideoPreloader videoData={useVideoData} />
                {ui.isPlaying && useVideoData && (
                  <VideoManagerData
                    history={history}
                    resourceId={match.params.resourceid}
                    shouldListen={speech.isListening}
                    corpus={resources.corpus}
                  />
                )}
                {ui.isPlaying && !useVideoData && (
                  <VideoManager
                    resourceId={match.params.resourceid}
                    shouldListen={speech.isListening}
                    corpus={resources.corpus}
                  />
                )}
              </div>
            )}
  
            {!ui.isPlaying && (
              <div className={styles.playButton}>
                <StartButton />
              </div>
            )}
            {!speech.isReadyForSpeech && (
              <Modal>
                <div className={styles.gettingSpeechReady}>
                  <div>
                    <Loading />
                  </div>
                  <div>Preparing your experience</div>
                </div>
              </Modal>
            )}
          </div>
        );
      }
  
      if (isEmpty(packagedMedia)) {
        return <div className={styles.fadeIn}>{this.getEmptyMessage()}</div>;
      }
  
      return (
        <div className={styles.loading}>
          <Loading />
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  const {
    firebase: { auth, data, profile },
    resources,
    cueItems,
    ui,
    speech
  } = state;

  return {
    userid: auth.uid,
    packagedMedia: data.packagedMedia,
    nfxMedia: data.nfxMedia,
    profile,
    resources,
    cueItems,
    ui,
    speech
  };
};

const mapDispatchToProps = dispatch => (
  {
    actions: bindActionCreators(
      {
        setEventData,
        updateCueStatus,
        updateNotification,
        updateShelfStatus,
        updateMutedStatus,
        hydrateCueDataFromFirebase
      },
      dispatch,
    )
  }
);

const enhance = compose(
  firebaseConnect(props => [
    {
      path: `packagedMedia/v1/en-us/${props.match.params.soundscapeid}`,
      storeAs: 'packagedMedia'
    },
    {
      path: `nfxMedia/v1/en-us/${props.match.params.resourceid}`,
      storeAs: 'nfxMedia'
    }
  ]),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(Mediascape);
