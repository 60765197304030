import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect, isLoaded } from 'react-redux-firebase';
import { Helmet } from 'react-helmet';
import { default as CollectionItem } from './CollectionItem';
import { Loading, Branding } from '../../components';
import styles from './Collection.module.scss';
import selectSound from '../../assets/audio/Move_UI.wav';
import bonkSound from '../../assets/audio/ScrollToBottom_UI.wav';
import startSound from '../../assets/audio/Select_UI.wav';

// const myPath = process.env.PUBLIC_URL;

class Collection extends Component {
  constructor(props) {
    super(props);
    this.audioPlayer = React.createRef();
    this.resourceList = React.createRef();

    this.state = {
      currentKey: null,
      currentIndex: 0,
      isActive: false,
      itemWidth: 0,
      scrollPos: 0,
    };

    this.idleTimer = null;
    this.idleWait = 2000;

    let urlParams = new URLSearchParams(window.location.search);
    this.isWip = urlParams.get('collection') === 'wip';
    this.maxSize = 0;
    this.scrollPos = 0;

    this.handleKey = this.handleKey.bind(this);

    this.VZUAL = global.VZUAL || {};
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKey);

    if (this.VZUAL && this.VZUAL.VoiceClient && this.VZUAL.VoiceClient.stopNovelSession) {
      this.VZUAL.VoiceClient.stopNovelSession();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKey);
  }

  playAudio(file) {
    const audioPath = `https://d204mhn7hn8u4n.cloudfront.net/${file}`;
    if (this.VZUAL && this.VZUAL.TTS && this.VZUAL.TTS.play) {
      this.VZUAL.TTS.play(audioPath, 1);
    }

    if (!this.VZUAL) {
      if (this.audioPlayer) {
        this.audioPlayer.current.src = audioPath;
        this.audioPlayer.current.play();
      }
    }
  }

  handleKey(e) {
    console.log('key', e.key, e.code);
    let selectedItem = 0;

    switch (e.key) {
      case 'ArrowRight':
        if (this.state.currentIndex < this.maxSize) {
          selectedItem = this.state.currentIndex + 1;
          this.scrollPos = this.scrollPos + this.state.itemWidth;
          this.resourceList.current.scrollLeft = this.scrollPos;
          this.playAudio('Move_UI.wav');
        } else {
          selectedItem = this.state.currentIndex;
          this.playAudio('ScrollToBottom_UI.wav');
        }
        this.setState({ currentIndex: selectedItem });
        break;
      case 'ArrowLeft':
        if (this.state.currentIndex > 0) {
          selectedItem = this.state.currentIndex - 1;
          this.scrollPos = this.scrollPos - this.state.itemWidth;
          this.resourceList.current.scrollLeft = this.scrollPos;
          this.playAudio('Move_UI.wav');
        } else {
          selectedItem = this.state.currentIndex;
          this.playAudio('ScrollToBottom_UI.wav');
        }
        this.setState({ currentIndex: selectedItem });
        break;
      case 'Enter':
        this.playAudio('Select_UI.wav');
        setTimeout(() => {
          this.setState({ isActive: true });
        }, 500);

        break;
      default:
        break;
    }
  }

  handleItemSize(size) {
    if (this.state.itemWidth === 0) {
      this.setState({ itemWidth: size });
    }
  }

  render() {
    const { collection, history } = this.props;
    let myShelf = [];
    if (isLoaded(collection)) {
      this.maxSize = Object.keys(collection).length - 1;
      // console.log('collection', collection);

      Object.keys(collection).forEach(key => {
        // console.log(key, packagedMedia.v1['en-us'][key]);
        collection[key].key = key;
        myShelf.push(collection[key]);
      });

      // console.log('myShelf', myShelf);
      return (
        <div className={styles.Content}>
          <Branding />
          <Helmet>
            <link rel="preload" href={selectSound} as="audio" type="audio/wav" />
            <link rel="preload" href={bonkSound} as="audio" type="audio/wav" />
            <link rel="preload" href={startSound} as="audio" type="audio/wav" />
          </Helmet>
          <ul className={styles.resourceList} ref={this.resourceList}>
            {myShelf.map((item, index) => {
              return (
                <CollectionItem
                  key={index}
                  id={item.id}
                  index={index}
                  currentId={this.state.currentIndex}
                  isActive={this.state.isActive}
                  history={history}
                  returnItemSize={size => this.handleItemSize(size)}
                />
              );
            })}
          </ul>
          <audio ref={this.audioPlayer} />
        </div>
      );
    } else {
      return (
        <div className={styles.loadingWrap}>
          <Loading />
        </div>
      );
    }
  }
}

const enhance = compose(
  firebaseConnect(props => [
    {
      path: `/collections/-LeDZ0Kb5my2ihUJ8YVu/items`,
      storeAs: 'collection',
    },
  ]),
  connect(
    // Map redux state to component props
    ({ firebase: { auth, profile, data } }) => ({
      collection: data.collection,
      auth,
      profile,
    }),
  ),
);

export default enhance(Collection);
