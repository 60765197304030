import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { updateMutedStatus } from '../../redux/modules/speechReducer';
import { logStatus } from '../../redux/modules/userReducer';
import styles from './Soundscape.module.scss';

class Soundscape extends Component {
  static propTypes = {
    resources: PropTypes.shape({
      displayTitle: PropTypes.string,
      description: PropTypes.string
    }).isRequired,
    packagedMedia: PropTypes.shape({}),
    nfxMedia: PropTypes.shape({}).isRequired
  }

  static defaultProps = {
    packagedMedia: null
  }

  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(window.location.search);
    this.isWip = urlParams.get('collection') === 'wip';
  }

  fetchCoverImage = (images) => {
    let activeImagePath;
    Object.keys(images).forEach((key) => {
      if (images[key].type === 'square' && images[key].isActive) {
        activeImagePath = images[key].downloadURL;
      }
    });
    return activeImagePath;
  }

  fetchAffliliateLink = (affiliates) => {
    let activePath;
    if (affiliates) {
      activePath = affiliates.amazonLink ? affiliates.amazonLink : null;
    }

    return activePath;
  }

  renderAuthors = (authors) => {
    const output = [];
    authors.forEach((author, i) => {
      // if list is more than one item and this is the last item, prefix with 'and '
      if (authors.length > 1 && i === authors.length - 1) output.push('and ');
      // output the item
      output.push(author.name);
      // if list is more than 2 items, append a comma to all but the last item
      if (authors.length > 2 && i < authors.length - 1) output.push(',');
      // if list is more than 1 item, append a space to all but the last item
      if (authors.length > 1 && i < authors.length - 1) output.push(' ');
    });

    return output;
  }

  render() {
    const { resources, packagedMedia, nfxMedia } = this.props;
    // console.log('mediaMeta', mediaMeta);
    // style={ui.videoSize}
    let mediaMeta;
    if (isLoaded(packagedMedia) && !isEmpty(packagedMedia)) {
      mediaMeta = packagedMedia;
    }

    if (isLoaded(nfxMedia) && !isEmpty(nfxMedia)) {
      mediaMeta = nfxMedia;
    }
    if (mediaMeta) {
      const activeImage = this.fetchCoverImage(mediaMeta.images);
      const activeAffiliate = this.fetchAffliliateLink(mediaMeta.affiliates);
      const displayAuthors = this.renderAuthors(mediaMeta.authors);

      return (
        <div className={styles.soundscape}>
          <h1>{resources.displayTitle}</h1>

          <div>
            <img src={activeImage} alt={`cover for ${resources.displayTitle}`} />
          </div>

          <div className={styles.authors}>
            By &nbsp;
            {displayAuthors.map((author, index) => (
              <span key={`display-author-${index + 1}`}>{author}</span>
            ))}
          </div>
          <div className={styles.description}>{resources.description}</div>
          <div className={styles.copyright}>{mediaMeta.copyright}</div>
          <div>
            <a href={activeAffiliate} target="_blank" rel="noopener noreferrer">
              Purchase this on Amazon
            </a>
          </div>
        </div>
      );
    }
    return null;
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    updateMutedStatus,
    logStatus
  },
  dispatch,
);

// const mapStateToProps = state => ({
//   resources: state.resources,
// });

const enhance = compose(
  firebaseConnect(props => [
    {
      path: `packagedMedia/v1/en-us/${props.match.params.soundscapeid}`,
      storeAs: 'packagedMedia'
    },
    {
      path: `nfxMedia/v1/en-us/${props.match.params.resourceid}`,
      storeAs: 'nfxMedia'
    }
  ]),
  connect(
    // Map redux state to component props
    ({ firebase: { data, profile }, resources }) => ({
      packagedMedia: data.packagedMedia,
      nfxMedia: data.nfxMedia,
      profile,
      resources
    }),
    mapDispatchToProps,
  ),
);

export default enhance(Soundscape);
