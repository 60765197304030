// fetch Array Buffer for video asset via URL

export const fetchAB = (url, cb) => {
  var newURL = url;
  //this.setState({ status: `fetching: ${url}` });
  console.log(newURL);
  var xhr = new XMLHttpRequest();
  xhr.open('get', newURL);
  xhr.responseType = 'arraybuffer';
  xhr.onload = function() {
    cb(xhr.response);
  };
  xhr.send();
};

export const fetchArrayBuffer = (url, method) => {
  // Create the XHR request
  var request = new XMLHttpRequest();

  // Return it as a Promise
  return new Promise(function(resolve, reject) {
    // Setup our listener to process compeleted requests
    request.onreadystatechange = function() {
      // Only run if the request is complete
      if (request.readyState !== 4) return;

      // Process the response
      if (request.status >= 200 && request.status < 300) {
        // If successful
        resolve(request.response);
      } else {
        // If failed
        reject({
          status: request.status,
          statusText: request.statusText,
        });
      }
    };

    // Setup our HTTP request
    request.responseType = 'arraybuffer';
    request.open(method || 'GET', url, true);

    // Send the request
    request.send();
  });
};
