import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateCueAudioItems } from '../../redux/modules/cueReducer';
import styles from './About.module.scss';

class About extends Component {
  render() {
    const { resources } = this.props;
    return (
      <div className={styles.aboutDrawer}>
        <h3>How does this work?</h3>
        <div>
          Novel Effect uses voice recognition technology to follow along as you read out loud. Read at your own pace, pause or skip around and Novel Effect will
          keep up with you, wherever you are in the story.
        </div>
        <div />
        <h3>About Novel Effect</h3>
        <div>
          Visit our{' '}
          <a href="https://www.noveleffect.com" target="_blank" rel="noopener noreferrer">
            website
          </a>{' '}
          to learn more about voice-driven interactive storytelling.
        </div>
        <div>
          Learn about our{' '}
          <a href="https://www.noveleffect.com/privacy-policy" target="_blank" rel="noopener noreferrer">
            privacy policy
          </a>{' '}
          and{' '}
          <a href="https://www.noveleffect.com/terms-of-service" target="_blank" rel="noopener noreferrer">
            terms of service
          </a>
          .
        </div>
        <div />
        <h3>About {resources.displayTitle}</h3>
        <div>{resources.description}</div>
        <div />
        <h3>Get in touch!</h3>
        <div>
          Say hello at <a href="mailto:info@noveleffect.com">info@noveleffect.com</a> or sign up for our newsletter.
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateCueAudioItems,
    },
    dispatch,
  );

const mapStateToProps = (state, ownProps) => ({
  ui: state.ui,
  resources: state.resources,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(About);
