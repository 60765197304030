import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { AUDIO_EVENTS } from '../../constants/index';
import styles from './DebugMode.module.scss';

class DebugMode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      debug: false,
    };
  }

  render() {
    const { speech, isDebug, audioItems, bookmarkPos, notifications, device } = this.props;
    let activeAudioCues = [];
    Object.keys(audioItems).forEach(key => {
      let isPlaying =
        audioItems[key].status === AUDIO_EVENTS.PLAYING || audioItems[key].status === AUDIO_EVENTS.FADEIN || audioItems[key].status === AUDIO_EVENTS.FADEOUT;
      if (isPlaying) {
        activeAudioCues.push(audioItems[key]);
      }
    });

    let notificationMsg;
    let showNotifications = false;
    if (Array.isArray(notifications) && notifications.length > 0) {
      showNotifications = true;
      notificationMsg = notifications.toString();
    }

    if (typeof notifications === 'string' || notifications instanceof String) {
      showNotifications = true;
      notificationMsg = notifications;
    }

    return (
      <div
        className={classNames(styles.debugMode, {
          [styles.enabled]: isDebug,
        })}
      >
        <div className={styles.positions}>
          {showNotifications && <div className={styles.notification}>Notifications: {notificationMsg}</div>}
          <div>sessionID: {speech.sessionId}</div>
          <div>Dictate Server: {speech.dictateServer}</div>
          <div>Bookmark Server: {speech.bookmarkingServer}</div>
          <div>workerReady: {speech.isReadyForSpeech.toString()}</div>
          <div>Verizon ReadyForSpeech: {speech.isBTReadyForSpeech.toString()}</div>
          <div>uses pairing: {device.usesPairing}</div>
          <div>bookmarkPos: {speech.bookmarkPos}</div>
          <div>Active Audio Cues:</div>
          <div className={styles.activeAudioItems}>
            {activeAudioCues.map((audioCue, index) => (
              <div
                className={classNames(styles.cueitem, {
                  [styles.fadeout]: audioCue.status === 'fadeout',
                })}
                key={index}
              >
                {audioCue.event_data}
                {audioCue.name}
              </div>
            ))}
          </div>
        </div>
        <div className={styles.output}>
          {speech.outputResults.map((word, index) => (
            <span
              key={index}
              className={classNames(styles.wordItem, {
                [styles.highlight]: bookmarkPos === index,
              })}
            >
              {word}
            </span>
          ))}
        </div>
        {false && <div className={styles.hypothesis}>{speech.asrResults}</div>}
        <div className={styles.hypothesis}>{speech.asrResults}</div>

        <div className={styles.serverStatus}>
          {speech.currentServerStatus.map((status, key) => (
            <div key={key}>{status}</div>
          ))}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  audioItems: state.cueItems.audioItems,
  bookmarkPos: state.cueItems.bookmarkPos,
  isDebug: state.ui.isDebugMode,
  notifications: state.ui.notifications,
  speech: state.speech,
  device: state.device,
});

export default connect(mapStateToProps)(DebugMode);
