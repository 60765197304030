import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { updateMutedStatus } from '../../redux/modules/speechReducer';
import styles from './Microphone.module.scss';

class Microphone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      voiceHeard: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { asrResults, isMuted } = this.props;

    if (asrResults !== prevProps.asrResults && !isMuted) {
      this.setState({ voiceHeard: !this.state.voiceHeard });
    }

    if (isMuted !== prevProps.isMuted && isMuted) {
      this.setState({ voiceHeard: false });
    }
  }

  handleMicToggle() {
    const { isMuted, updateMutedStatus, isPaused } = this.props;

    if (!isPaused) {
      updateMutedStatus(!isMuted);
    }
  }

  render() {
    const { isMuted, isPaused } = this.props;

    return (
      <div
        className={classNames(styles.microphoneButton, {
          [styles.disabled]: isPaused,
        })}
        onClick={() => this.handleMicToggle()}
        title="Enables debug view"
      >
        <div className={styles.microphoneIcon}>
          {!isMuted && (
            <svg xmlns="http://www.w3.org/2000/svg" width="29.104" height="35.018" viewBox="0 0 29.104 35.018">
              <g id="mic_on" transform="translate(-566 -395)">
                <g id="Group_1682" data-name="Group 1682">
                  <path
                    id="Path_1330"
                    d="M141.722 781.267v13.37a8.009 8.009 0 0 0 7.7 7.8 8.146 8.146 0 0 0 8.687-7.985v-13a8.146 8.146 0 0 0-8.687-7.985 8.009 8.009 0 0 0-7.7 7.8zm2.239 12.588a.085.085 0 0 1 .083-.1h2.256a.085.085 0 0 1 .082.064l.154.616a3.627 3.627 0 0 0 .931 1.664 3.4 3.4 0 0 0 4.974-.058 3.615 3.615 0 0 0 .873-1.6l.152-.619a.085.085 0 0 1 .082-.065h2.257a.085.085 0 0 1 .083.1l-.156.722a6.523 6.523 0 0 1-1.15 2.636 5.858 5.858 0 0 1-4.516 2.331h-.135a5.906 5.906 0 0 1-4.17-1.767 6.013 6.013 0 0 1-1.587-2.934z"
                    className={styles.micOn}
                    data-name="Path 1330"
                    transform="translate(430.626 -378.454)"
                  />
                  <path
                    id="Path_1331"
                    d="M161.523 795.85a12.457 12.457 0 0 1-3.555 8.722 12.074 12.074 0 0 1-8.7 3.7c-.244 0-.489-.008-.735-.023a12.27 12.27 0 0 1-11.513-12.232v-.168h-2.3v.168a14.57 14.57 0 0 0 13.674 14.526c.293.017.583.026.872.026a14.355 14.355 0 0 0 10.35-4.395 14.742 14.742 0 0 0 4.208-10.325z"
                    className={styles.micOn}
                    data-name="Path 1331"
                    transform="translate(431.282 -380.552)"
                  />
                </g>
              </g>
            </svg>
          )}

          {isMuted && (
            <svg xmlns="http://www.w3.org/2000/svg" width="31.399" height="35.018" viewBox="0 0 31.399 35.018">
              <g id="mic_off" transform="translate(-661.5 -395)">
                <g id="Group_1677" data-name="Group 1677" transform="translate(662.621 415.304)">
                  <path
                    id="Path_1325"
                    d="M137.348 799.061a12.183 12.183 0 0 1-.392-3.026v-.168h-2.3v.168a14.44 14.44 0 0 0 .828 4.789z"
                    className={styles.micOff}
                    data-name="Path 1325"
                    transform="translate(-134.659 -795.867)"
                  />
                  <path
                    id="Path_1326"
                    d="M158.363 804.585a12.074 12.074 0 0 1-8.7 3.7c-.243 0-.488-.008-.735-.023a12.183 12.183 0 0 1-7.672-3.348l-1.666 1.578a14.477 14.477 0 0 0 9.2 4.063c.292.017.583.026.871.026a14.348 14.348 0 0 0 10.346-4.393 14.736 14.736 0 0 0 4.206-10.321h-2.3a12.456 12.456 0 0 1-3.55 8.718z"
                    className={styles.micOff}
                    data-name="Path 1326"
                    transform="translate(-135.123 -795.867)"
                  />
                </g>
                <g id="Group_1678" data-name="Group 1678" transform="translate(669.066 395)">
                  <path
                    id="Path_1327"
                    d="M150.32 798.555a3.432 3.432 0 0 0 2.43-1.11 3.618 3.618 0 0 0 .872-1.6l.153-.62a.084.084 0 0 1 .082-.064h2.255a.085.085 0 0 1 .083.1l-.156.723a6.52 6.52 0 0 1-1.15 2.635 5.854 5.854 0 0 1-4.514 2.329h-.136a5.877 5.877 0 0 1-3.627-1.287l-2.084 1.973a8.347 8.347 0 0 0 5.195 2.2 8.143 8.143 0 0 0 8.685-7.982v-7.368l-10.029 9.5a3.347 3.347 0 0 0 1.941.571z"
                    className={styles.micOff}
                    data-name="Path 1327"
                    transform="translate(-142.032 -774.869)"
                  />
                  <path
                    id="Path_1328"
                    d="M157.564 778.52a8.23 8.23 0 0 0-8.1-5.051 8 8 0 0 0-7.693 7.8v12.211z"
                    className={styles.micOff}
                    data-name="Path 1328"
                    transform="translate(-141.773 -773.455)"
                  />
                </g>
                <path
                  id="Path_1329"
                  d="M164.366 776.05a1.532 1.532 0 0 0-.969-.333 1.551 1.551 0 0 0-1.065.409l-28.521 27.014a1.161 1.161 0 0 0 .066 1.789 1.535 1.535 0 0 0 .969.333h.063a1.544 1.544 0 0 0 1-.409l28.522-27.014a1.161 1.161 0 0 0-.065-1.789z"
                  className={styles.redLine}
                  data-name="Path 1329"
                  transform="translate(528.078 -378.668)"
                />
              </g>
            </svg>
          )}
        </div>
        <div
          className={classNames(styles.visualizer, {
            [styles.active]: this.state.voiceHeard,
          })}
        >
          <div
            className={classNames(styles.small, styles.one, {
              [styles.active]: this.state.voiceHeard,
            })}
          />
          <div
            className={classNames(styles.small, styles.two, {
              [styles.active]: this.state.voiceHeard,
            })}
          />
          <div
            className={classNames(styles.large, {
              [styles.active]: this.state.voiceHeard,
            })}
          />
          <div
            className={classNames(styles.small, styles.three, {
              [styles.active]: this.state.voiceHeard,
            })}
          />
          <div
            className={classNames(styles.small, styles.four, {
              [styles.active]: this.state.voiceHeard,
            })}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  asrResults: state.speech.asrResults,
  isMuted: state.speech.isMuted,
  isPaused: state.ui.isPaused,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateMutedStatus,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Microphone);
