import React, { Component } from 'react';
import { compose, bindActionCreators } from 'redux';
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { updateReadyForSpeech, updateListeningState } from '../../redux/modules/speechReducer';
import { fetchUserId, logStatus } from '../../redux/modules/userReducer';
import { updateBookmarkPos } from '../../redux/modules/cueReducer';

class ContentRedirector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      debug: false,
    };

    let urlParams = new URLSearchParams(window.location.search);
    this.pairReset = urlParams.get('pairreset');
    if (urlParams.get('resetpair')) {
      this.pairReset = urlParams.get('resetpair');
    }
    this.isPairReset = this.pairReset === 'true' ? true : false;
    this.handleKey = this.handleKey.bind(this);
  }

  componentDidMount() {
    const { history, fetchUserId } = this.props;
    // window.addEventListener('keypress', this.handleKey);
    window.addEventListener('keydown', this.handleKey);
    // window.addEventListener('scroll', this.handleScroll);

    // window.addEventListener('mousewheel', this.handleScroll);

    const VZUAL = global.VZUAL || {};
    if (VZUAL) {
      console.log('VZUAL', VZUAL);
    }

    if (false) {
      console.log('platform: ', navigator.platform);
      console.log('is Verizon: ', navigator.appVersion.toLowerCase().includes('verizon'));
      console.log('is VMS4100: ', navigator.appVersion.toLowerCase().includes('vms4100'));

      console.log('product: ', navigator.product);
      console.log('appCodeName: ', navigator.appCodeName);
      console.log('appName: ', navigator.appName);
    }
    fetchUserId();
    if (this.isPairReset) {
      localStorage.removeItem('nfxSession:pairing');
      localStorage.removeItem('nfxSession:userId');
      fetchUserId();
      // const newUrl = window.location.href.replace(/\?.*$/g, '');
      // const newUrl = window.location.href.replace('?pairreset=true', '');
      history.push('/');
    }
    let payload = { code: 'playBackStarted', shouldListen: true };
    logStatus(payload);
  }

  componentWillUnmount() {
    window.removeEventListener('keypress', this.handleKey);
    // window.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { event, updateReadyForSpeech, updateListeningState, speech, history, logStatus, bookId, soundscapeId, match } = this.props;

    if (isLoaded(soundscapeId) && isLoaded(bookId)) {
      let isResourceChange = soundscapeId !== match.params.soundscapeid;

      if (isResourceChange) {
        if (bookId && soundscapeId) {
          const newUrl = `/mediascape/${bookId}/${soundscapeId}`;
          let payload = { code: null, shouldListen: false };

          logStatus(payload);
          window.location.href = newUrl;
        }
      }
    }
    if (isLoaded(event) && !isEmpty(event) && isLoaded(soundscapeId)) {
      if (!speech.isReadyForSpeech || !speech.isListening) {
        updateReadyForSpeech(true);
        updateListeningState(true);
      }

      let isSpeechEvent = speech.isReadyForSpeech !== prevProps.speech.isReadyForSpeech && speech.isListening !== prevProps.speech.isListening;

      // console.log('isResourceChange', soundscapeId, prevProps.soundscapeId);
      if (isSpeechEvent) {
        if (event.event_name === 'NFXMeta' && event.event_data) {
          if (bookId && soundscapeId) {
            const newUrl = `/mediascape/${bookId}/${soundscapeId}`;

            let payload = { code: null, shouldListen: false };

            logStatus(payload);
            history.push(newUrl);
          }
          //window.location.href = newUrl;
        }
      }
    }
  }

  handleKey(e) {
    const { updateBookmarkPos, history } = this.props;
    // console.log('key press', e);

    switch (e.key) {
      case 'Delete':
      case 'Escape':
        // history.goBack();
        // const myPath = process.env.PUBLIC_URL;
        // window.location.href = `${myPath}/`;
        history.push('/');
        updateBookmarkPos(-1);
        break;

      default:
        break;
    }
  }

  // handleScroll(e) {
  //   console.log('scroll event', e);
  // }
  render() {
    return <div />;
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateReadyForSpeech,
      updateListeningState,
      fetchUserId,
      logStatus,
      updateBookmarkPos,
    },
    dispatch,
  );

const enhance = compose(
  firebaseConnect(props => [
    {
      path: `webclient/session/${props.pair.userId}/event`,
      storeAs: 'event',
    },
    {
      path: `webclient/session/${props.pair.userId}/event/event_data/bookid`,
      storeAs: 'bookId',
    },
    {
      path: `webclient/session/${props.pair.userId}/event/event_data/soundscapeid`,
      storeAs: 'soundscapeId',
    },
  ]),
  connect(
    // Map redux state to component props
    ({ firebase: { data, profile } }) => ({
      event: data.event,
      bookId: data.bookId,
      soundscapeId: data.soundscapeId,
      profile,
    }),
    mapDispatchToProps,
  ),
);

const mapStateToProps = state => ({
  pair: state.pair,
  speech: state.speech,
});

export default connect(mapStateToProps)(enhance(ContentRedirector));
