import React, { Component } from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  firebaseConnect,
  isLoaded
} from 'react-redux-firebase';
import ReactGA from 'react-ga';
import classNames from 'classnames';
import { Object } from 'es6-shim';
import PropTypes from 'prop-types';
import { STORAGE } from '../../constants/index';
import {
  AudioManager,
  TitleID,
  BookmarkManager,
  VideoManager,
  VideoManagerData,
  Loading,
  TextRangeManager,
  StartButton,
  TopBar,
  BottomBar,
  DrawerManager,
  VideoPreloader,
  SpeechProcessing,
  StbVideoPlayer
} from '../../components';
import QrCode from '../../components/QrCode/QrCode';
import {
  setSessionStatus,
  setEventData
} from '../../redux/modules/userReducer';
import {
  updateCueStatus,
  hydrateCueDataFromFirebase
} from '../../redux/modules/cueReducer';
import {
  updateNotification,
  updateShelfStatus
} from '../../redux/modules/uiReducer';
import {
  updateMutedStatus,
  updateReadyForSpeech,
  updateListeningState
} from '../../redux/modules/speechReducer';
import {
  hydrateResourceDataFromFirebase
} from '../../redux/modules/resourcesReducer';
import Title from './Title';
import { fetchArrayBuffer } from '../../helpers';
import styles from './Mediascape.module.scss';

class MediascapeDevice extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      setEventData: PropTypes.func,
      setSessionStatus: PropTypes.func,
      updateCueStatus: PropTypes.func,
      updateNotification: PropTypes.func,
      updateShelfStatus: PropTypes.func,
      updateMutedStatus: PropTypes.func,
      hydrateCueDataFromFirebase: PropTypes.func,
      hydrateResourceDataFromFirebase: PropTypes.func,
      updateReadyForSpeech: PropTypes.func,
      updateListeningState: PropTypes.func
    }).isRequired,
    audioItems: PropTypes.arrayOf(PropTypes.shape({})),
    auth: PropTypes.shape({
      uid: PropTypes.string
    }).isRequired,
    cueItems: PropTypes.shape({
      status: PropTypes.string,
      mediaItems: PropTypes.array,
      videoItems: PropTypes.arrayOf(PropTypes.shape({
        isIdle: PropTypes.bool
      }))
    }).isRequired,
    device: PropTypes.shape({
      usesAppASR: PropTypes.bool,
      isVZSTB: PropTypes.bool,
      isFiretv: PropTypes.bool
    }).isRequired,
    history: PropTypes.shape({}).isRequired,
    idleItems: PropTypes.arrayOf(
      PropTypes.shape({
        isIdle: PropTypes.bool
      })
    ),
    isSTB: PropTypes.bool.isRequired,
    isFiretv: PropTypes.bool.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        userid: PropTypes.string,
        resourceid: PropTypes.string,
        soundscapeid: PropTypes.string
      })
    }).isRequired,
    nfxMedia: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      images: PropTypes.shape({})
    }),
    packagedMedia: PropTypes.shape({
      title: PropTypes.string,
      images: PropTypes.shape({}),
      description: PropTypes.string
    }),
    profile: PropTypes.shape({}).isRequired,
    resources: PropTypes.shape({
      videoItems: PropTypes.shape({
        cutscene: PropTypes.shape({}),
        idle: PropTypes.shape({})
      }),
      corpus: PropTypes.string
    }).isRequired,
    speech: PropTypes.shape({
      isReadyForSpeech: PropTypes.bool,
      isListening: PropTypes.bool,
      isBTReadyForSpeech: PropTypes.bool,
      sessionId: PropTypes.string
    }).isRequired,
    status: PropTypes.string,
    ui: PropTypes.shape({
      isPlaying: PropTypes.bool,
      isFullScreen: PropTypes.bool,
      isShelfActive: PropTypes.bool
    }).isRequired,
    videoItems: PropTypes.arrayOf(
      PropTypes.shape({
        isIdle: PropTypes.bool
      })
    )
  }

  static defaultProps = {
    audioItems: null,
    idleItems: null,
    // nfxMedia: null,
    // packagedMedia: null,
    status: '',
    videoItems: null
  }

  constructor(props) {
    super(props);
    this.state = {
      overrideHide: false,
      videoItemssLoaded: false,
      videoDownloadIndex: 0,
      videoItemsLoading: false,
      currentVideoId: -1
    };

    this.idleTimer = null;
    this.idleWait = 2000;
    this.numberOfDownloadEachTime = 3;

    const urlParams = new URLSearchParams(window.location.search);
    this.enableAudio = urlParams.get('audio') === 'true';

    this.isVerizonBTReady = false;
    this.videoItems = [];
  }

  async componentDidMount() {
    const {
      actions, match, speech
    } = this.props;

    sessionStorage.clear();

    await actions.hydrateResourceDataFromFirebase(match.params, this.props.cueItems.mediaItems);
    await actions.hydrateCueDataFromFirebase(match.params);

    if (!speech.isReadyForSpeech || !speech.isListening) {
      this.isVerizonBTReady = false;
      actions.updateReadyForSpeech(true);
      actions.updateListeningState(true);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      actions, cueItems, packagedMedia, nfxMedia, ui, speech
    } = this.props;

    const {
      videoDownloadIndex,
      videoItemsLoading
    } = this.state;

    if (isLoaded(packagedMedia) && isLoaded(nfxMedia)) {
      const title = packagedMedia ? packagedMedia.title : nfxMedia.title;

      let prevTitle;
      if (prevProps.packagedMedia) {
        prevTitle = prevProps.packagedMedia.title;
      } else if (prevProps.nfxMedia) {
        prevTitle = prevProps.nfxMedia.title;
      }

      if (title !== prevTitle) {
        if (process.env.NODE_ENV === 'production') {
          ReactGA.event({
            category: 'Mediascape',
            action: 'User has selected Mediascape',
            label: title
          });
        }
        if (cueItems.status !== 'selected') {
          actions.updateCueStatus('selected');
        }
      }

      if (
        ui.isPlaying
        && ui.isPlaying !== prevProps.ui.isPlaying
        && cueItems.mediaItems.length === 0
        && cueItems.videoItems.length === 0
      ) {
        // to unmute we have to mute first
        actions.updateMutedStatus(true);
        setTimeout(() => {
          actions.updateMutedStatus(false);
        }, 250);
      }

      if (speech.isBTReadyForSpeech) {
        this.isVerizonBTReady = true;
      }
    }

    if (ui.isPlaying !== prevProps.ui.isPlaying) {
      const title = packagedMedia ? packagedMedia.title : nfxMedia.title;
      if (process.env.NODE_ENV === 'production') {
        ReactGA.event({
          category: 'Mediascape',
          action: 'User has started mediascape',
          label: title
        });
      }
    }

    if (ui.isFullScreen !== prevProps.ui.isFullScreen) {
      this.toggleFullScreen(ui.isFullScreen);
    }

    if (this.props.cueItems.videoItems.length > 0 && this.props.cueItems.videoItems !== prevProps.cueItems.videoItems) {
      this.props.cueItems.videoItems.forEach((cue, index) => {
        const videoObj = {
          id: index,
          anim: {
            path: cue.animation ? this.props.resources.videoItems.cutscene[cue.animation].downloadPath : null,
            name: cue.animation
          },
          idle: {
            path: cue.idle ? this.props.resources.videoItems.idle[cue.idle].downloadPath : null,
            name: cue.idle
          }
        };
        this.videoItems.push(videoObj);
      });

      this.setState({
        videoItemsLoading: true
      });

      this.videoBufferPreLoader(this.videoItems).then(() => {
        this.setState({
          videoItemssLoaded: true,
          videoDownloadIndex: (this.numberOfDownloadEachTime + videoDownloadIndex)
        });
      });
    }

    if (cueItems.bookmarkPos !== prevProps.cueItems.bookmarkPos && !videoItemsLoading) {
      this.mediascapeItemsFromBookmark();

      this.setState({
        videoItemsLoading: true
      });

      this.videoBufferPreLoader(this.videoItems).then(() => {
        this.setState({
          videoDownloadIndex: (this.numberOfDownloadEachTime + videoDownloadIndex)
        });
      });
    }
  }

  getVideoPaths(videoName, isIdle = false) {
    const { match } = this.props;
    const subPath = isIdle ? 'idle' : 'cutscene';
    const videoPath = `${STORAGE.StbS3Path}${match.params.resourceid}/video/1080/${subPath}/${videoName}.mp4`;
    return videoPath;
  }

  toggleFullScreen = (isFullScreen) => {
    const elem = document.documentElement;

    if (isFullScreen) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen();
    }
  }

  mediascapeItemsFromBookmark = () => {
    const { 
      cueItems: {
        videoItems,
        bookmarkPos
      }
  } = this.props;

    videoItems.forEach((item, index) => {
      if (parseInt(item.start) <= bookmarkPos && parseInt(item.stop) > bookmarkPos) {
        this.setState({ currentVideoId: index });
      }
    });
  }

  showQRcode = () => {
    const {
      isFiretv,
      status
    } = this.props;

    if (isFiretv) {
      return status !== 'connected';
    }

    return false;
  }

  videoBufferPreLoader = (videoItems) => {
    const {
      videoDownloadIndex,
      currentVideoId
    } = this.state;

    const promises = [];

    videoItems.forEach((item, index) => {
      if (index >= videoDownloadIndex && index < videoDownloadIndex + this.numberOfDownloadEachTime) {
        const animPath = this.getVideoPaths(item.anim.name, false);
        const idlePath = this.getVideoPaths(item.idle.name, true);

        if (!item.idleData) {
          promises.push(
            fetchArrayBuffer(idlePath).then((buffer) => {
              item.idleData = buffer;
            }),
          );
        }

        if (!item.animData) {
          promises.push(
            fetchArrayBuffer(animPath).then((buffer) => {
              item.animData = buffer;
            }),
          );
        }
      }

      if (index < currentVideoId) {
        item.animData = null;
        item.idleData = null;
      }
    });

    return new Promise((resolve) => {
      Promise.all(promises).then(() => {
        this.setState({
          videoItemsLoading: false
        })
        resolve(videoItems);
      });
    });
  }

  handleMouseEnter() {
    const { actions, ui } = this.props;

    if (!ui.isShelfActive) {
      actions.updateShelfStatus(true);
    }
  }

  handleMouseOut() {
    const { actions, ui } = this.props;
    if (ui.isShelfActive) {
      actions.updateShelfStatus(false);
    }
  }

  handleShelfEnter() {
    this.setState({ overrideHide: true });
  }

  handleShelfLeave() {
    this.setState({ overrideHide: false });
  }

  handleMouseMove() {
    const { actions } = this.props;
    const { overrideHide } = this.state;

    clearTimeout(this.idleTimer);

    actions.updateShelfStatus(true);

    if (!overrideHide) {
      this.idleTimer = setTimeout(() => {
        actions.updateShelfStatus(false);
      }, this.idleWait);
    }
  }

  render() {
    const {
      resources,
      match,
      audioItems,
      profile,
      auth,
      packagedMedia,
      nfxMedia,
      cueItems,
      videoItems,
      idleItems,
      ui,
      speech,
      device,
      isSTB,
      history
    } = this.props;

    const {
      videoItemssLoaded
    } = this.state;
    // disabled for now
    const isAudioSupported = cueItems.audioItems.length > 0;

    if (this.showQRcode()) {
      return (
        <QrCode size={288} />
      );
    }

    if (isLoaded(packagedMedia) && isLoaded(nfxMedia) && videoItemssLoaded) {
      let videoArr = [];
      let idelArr = [];
      let mediaArr = [];
      if (videoItems && Object.keys(videoItems).length > 0) {
        videoArr = Object.keys(videoItems).map((key) => {
          videoItems[key].isIdle = false;
          return videoItems[key];
        });
      }

      if (idleItems && Object.keys(idleItems).length > 0) {
        idelArr = Object.keys(idleItems).map((key) => {
          idleItems[key].isIdle = true;
          return idleItems[key];
        });
      }

      const isAudioOnly = cueItems.mediaItems.length === 0 && cueItems.videoItems.length === 0;
      const useVideoData = cueItems.videoItems.length > 0;
      mediaArr = videoArr.concat(idelArr);

      let splashImageStyle;

      const useNfxAppASR = device.usesAppASR ? device.usesAppASR : false;
      let imageItems;
      if (packagedMedia) {
        imageItems = packagedMedia.images;
      } else if (nfxMedia) {
        imageItems = nfxMedia.images;
      }
      if (imageItems) {
        Object.keys(imageItems).forEach((key) => {
          if (imageItems[key].type === 'mediascapeteaser') {
            // let splashImage = `${STORAGE.cfPath}${resources.resourceId}/images/splash.png`;
            const splashImage = imageItems[key].downloadURL;
            splashImageStyle = { backgroundImage: `url(${splashImage}` };
          }
        });
      }

      const resourcesReady = resources.videoItems && Object.keys(resources.videoItems).length > 0;
      const cuesReady = cueItems.videoItems && Object.keys(cueItems.videoItems).length > 0;

      const currentTitle = packagedMedia ? packagedMedia.title : nfxMedia.title;
      const currentDesc = packagedMedia ? packagedMedia.description : nfxMedia.description;
      const currentImage = packagedMedia ? packagedMedia.images : nfxMedia.images;

      return (
        <div
          className={styles.mediascape}
          onMouseEnter={() => this.handleMouseEnter()}
          onMouseLeave={() => this.handleMouseOut()}
          onMouseMove={() => this.handleMouseMove()}
        >
          {!useNfxAppASR && <SpeechProcessing title={currentTitle} sessionId={speech.sessionId} />}
          {ui.isPlaying && !isSTB && (
            <div>
              <TopBar
                isHidden={!ui.isShelfActive}
                onMouseEnter={() => this.handleShelfEnter()}
                onMouseLeave={() => this.handleShelfLeave()}
              />
              <BottomBar
                isHidden={!ui.isShelfActive}
                profile={profile}
                readyForSpeech={speech.isReadyForSpeech}
                onMouseEnter={() => this.handleShelfEnter()}
                onMouseLeave={() => this.handleShelfLeave()}
                isVideo={!isAudioOnly}
              />
              <DrawerManager />
            </div>
          )}
          <Title displayTitle={currentTitle} />
          <TitleID
            resourceId={match.params.resourceid}
            resourceDescription={currentDesc}
            title={currentTitle}
            images={currentImage}
            soundscapeId={match.params.soundscapeid}
            audioItems={audioItems}
            videoItems={mediaArr}
            match={match}
          />
          <BookmarkManager />

          {ui.isPlaying && <TextRangeManager />}

          <div
            className={classNames(styles.videoSplash, {
              [styles.hidden]: ui.isPlaying
            })}
            style={splashImageStyle}
          />

          {
            isAudioSupported
            && (
              <AudioManager
                resourceId={match.params.resourceid}
                shouldListen={speech.isListening}
              />
            )
          }
          {ui.isPlaying && resourcesReady && (device.isVZSTB || device.isFiretv) && cuesReady && (
            <StbVideoPlayer
              history={history}
              shouldListen={speech.isListening}
              userId={auth.uid}
              videoItems={this.videoItems}
            />
          )}
          {!isAudioOnly && !device.isVZSTB && !device.isFiretv && (
            <div>
              <VideoPreloader videoData={useVideoData} />
              {ui.isPlaying && useVideoData && (
                <VideoManagerData
                  resourceId={match.params.resourceid}
                  shouldListen={speech.isListening}
                  corpus={resources.corpus}
                />
              )}
              {ui.isPlaying && !useVideoData && (
                <VideoManager
                  resourceId={match.params.resourceid}
                  shouldListen={speech.isListening}
                  corpus={resources.corpus}
                />
              )}
            </div>
          )}

          {!ui.isPlaying && (
            <div className={styles.playButton}>
              <StartButton />
            </div>
          )}
          {!device.usesAppASR && (
            <div>
              {!speech.isReadyForSpeech
                  && (
                    <div className={styles.waitforSpeech}>
                      <div className={styles.gettingSpeechReady}>
                        <div className={styles.prepText}>Get ready for your adventure…</div>
                        <div>
                          <Loading />
                        </div>
                      </div>
                    </div>
                  )
              }
            </div>
          )}
        </div>
      );
    }
    return (
      <div className={styles.loading}>
        <Loading />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => (
  {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          setEventData,
          setSessionStatus,
          updateCueStatus,
          updateNotification,
          updateShelfStatus,
          updateMutedStatus,
          hydrateCueDataFromFirebase,
          updateReadyForSpeech,
          updateListeningState,
          hydrateResourceDataFromFirebase
        },
      ),
      dispatch
    )
  }
);

const enhance = compose(
  firebaseConnect(props => [
    {
      path: `packagedMedia/v1/en-us/${props.match.params.soundscapeid}`,
      storeAs: 'packagedMedia'
    },
    {
      path: `nfxMedia/v1/en-us/${props.match.params.resourceid}`,
      storeAs: 'nfxMedia'
    },
    {
      path: `webclient/session/${props.auth.uid}/voice/status`,
      storeAs: 'status'
    }
  ]),
  connect(
    // Map redux state to component props
    ({
      firebase: { data, profile, auth },
      device,
      resources,
      cueItems,
      ui,
      speech
    }) => ({
      packagedMedia: data.packagedMedia,
      nfxMedia: data.nfxMedia,
      profile,
      device,
      resources,
      cueItems,
      ui,
      status: data.status,
      speech,
      isSTB: device.isVZSTB,
      isFiretv: device.isFiretv,
      auth
    }),
    mapDispatchToProps,
  ),
);

export default enhance(MediascapeDevice);
