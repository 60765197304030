import { createSelector } from 'reselect';

export const cueSelector = state => state.cueItems;

export const mediaItemsSelector = createSelector(
  cueSelector,
  cue => cue.mediaItems
);

export const videoItemsSelector = createSelector(
  cueSelector,
  cue => cue.videoItems
);

export const audioItemsSelector = createSelector(
  cueSelector,
  cue => cue.audioItems
);

export const textRangesSelector = createSelector(
  cueSelector,
  cue => cue.textRanges
);

export const jsonItemsSelector = createSelector(
  cueSelector,
  cue => cue.jsonItems
);

export const bookmarkPosSelector = createSelector(
  cueSelector,
  cue => cue.bookmarkPos
);

export const statusSelector = createSelector(
  cueSelector,
  cue => cue.status
);
