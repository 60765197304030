import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import 'url-search-params-polyfill';
import classNames from 'classnames';
import { VideoControllerData, ScreenText, BrowserSizeManager } from '../index';
import { updateAudioItem } from '../../redux/modules/resourcesReducer';
// import { STORAGE } from '../../constants/index';
import styles from './VideoManager.module.scss';

class VideoManager extends Component {
  constructor(props) {
    let urlParams = new URLSearchParams(window.location.search);
    let myParam = urlParams.get('media');
    super(props);
    this.state = {
      currentVideoId: -1,
      readyForScreenText: false,
      endWord: -1,
      myParam: myParam,
      isPreloaded: false,
      isFinalVideo: false,
      showCompletedMenu: false,
      currentButton: -1,
    };

    this.maxSize = 1;

    this.mediascapeItemsFromBookmark = this.mediascapeItemsFromBookmark.bind(this);
    this.handleKey = this.handleKey.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKey);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKey);
  }

  componentWillReceiveProps(nextProps) {
    const { cueItems, shouldListen } = this.props;
    if (cueItems.bookmarkPos !== nextProps.cueItems.bookmarkPos && shouldListen) {
      this.mediascapeItemsFromBookmark(nextProps.cueItems);
    }
    if (cueItems && this.state.currentVideoId === -1) {
      this.mediascapeItemsFromBookmark(cueItems);
    }
  }

  mediascapeItemsFromBookmark(cueItems) {
    let videoItems = cueItems.videoItems;
    let textRanges = cueItems.textRanges;
    let bookmarkPos = cueItems.bookmarkPos;

    videoItems.forEach((item, index) => {
      if (item.isInitial && this.state.currentVideoId === -1) {
        this.setState({ currentVideoId: index });
      }
      if (parseInt(item.start) <= bookmarkPos && parseInt(item.stop) > bookmarkPos) {
        this.setState({ currentVideoId: index });
        if (index === videoItems.length - 1) {
          console.log('mediaacape completed ');
          this.setState({ isFinalVideo: true });
        }
      }
    });

    textRanges.forEach(item => {
      if (parseInt(item.start) <= bookmarkPos && parseInt(item.stop) > bookmarkPos) {
        this.setState({ endWord: parseInt(item.stop) });
      }
    });
  }

  handleFinalVideoPlayed() {
    this.setState({ showCompletedMenu: true });
  }

  handleVideoEnd() {
    this.setState({ readyForScreenText: true });
    // console.log('readyForScreenText :', true);
  }

  handleVideoPlaying() {
    this.setState({ readyForScreenText: false });
    // console.log('readyForScreenText :', false);
  }

  handleSizeChange(size) {
    this.setState({ readyForScreenText: false });
  }

  backToHome() {
    this.props.history.push('/');
  }

  reloadScape() {
    window.location.reload();
  }

  handleKey(e) {
    let selectedItem = 0;
    if (this.state.showCompletedMenu) {
      switch (e.key) {
        case 'ArrowRight':
          if (this.state.currentButton < this.maxSize) {
            selectedItem = this.state.currentButton + 1;
          } else {
            selectedItem = this.state.currentButton;
          }
          this.setState({ currentButton: selectedItem });
          break;
        case 'ArrowLeft':
          if (this.state.currentButton > 0) {
            selectedItem = this.state.currentButton - 1;
          } else {
            selectedItem = this.state.currentButton;
          }
          this.setState({ currentButton: selectedItem });
          break;
        case 'Enter':
          if (this.state.currentButton === 0) {
            this.backToHome();
          }

          if (this.state.currentButton === 1) {
            this.reloadScape();
          }

          break;
        default:
          break;
      }
    }
  }

  render() {
    const { resources, shouldListen, corpus, cueItems, history } = this.props;

    const videoCount = Object.keys(resources.videoItems).length;
    const usesVideo = videoCount > 0;
    const usesImages = resources.imageItems.length > 0;
    const hasTextRanges = cueItems.textRanges.length > 0;

    return (
      <div className={styles.videoManager}>
        {usesVideo && shouldListen && (
          <div className={styles.videoMediaScape}>
            <div
              className={classNames(styles.completedMenu, {
                [styles.active]: this.state.showCompletedMenu,
              })}
            >
              <div
                className={classNames(styles.completedButton, {
                  [styles.active]: this.state.currentButton === 0,
                })}
                onClick={() => this.backToHome()}
              >
                Exit
              </div>
              <div
                className={classNames(styles.completedButton, {
                  [styles.active]: this.state.currentButton === 1,
                })}
                onClick={() => this.reloadScape()}
              >
                Play Again
              </div>
            </div>

            {hasTextRanges && (
              <ScreenText
                displayText={corpus}
                isVideoReady={this.state.readyForScreenText}
                endWord={this.state.endWord}
                resourceId={resources.resourceId}
                cueStatus={cueItems.status}
              />
            )}
            <VideoControllerData
              videoId={this.state.currentVideoId}
              resourceId={resources.resourceId}
              onVideoEnd={() => this.handleVideoEnd()}
              onVideoStart={() => this.handleVideoPlaying()}
              onSizeChange={size => this.handleSizeChange(size)}
              mediaType={this.state.myParam}
              videos={resources.videoItems}
              videoCues={cueItems.videoItems}
              isMuted={resources.isMuted}
              isFinalVideo={this.state.isFinalVideo}
              onFinalVideo={() => this.handleFinalVideoPlayed()}
              history={history}
            />
            <BrowserSizeManager />
          </div>
        )}
        {usesImages && <div>IMAGES GO HERE</div>}
        {false && (
          <div>
            <div>videoId={this.state.currentVideoId}</div>
            <div>resourceId={resources.resourceId}</div>
            <div>mediaType={this.state.myParam}</div>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateAudioItem,
    },
    dispatch,
  );

const mapStateToProps = state => ({
  resources: state.resources,
  cueItems: state.cueItems,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VideoManager);
