import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateBookmarkTextBlock } from '../../redux/modules/speechReducer';
class TextRangeManager extends Component {
  state = {
    currentIndex: -1
  }

  componentDidMount() {
    const { updateBookmarkTextBlock } = this.props;
    updateBookmarkTextBlock(0);
  }

  componentDidUpdate(prevProps) {
    const { cueItems } = this.props;

    if (cueItems.bookmarkPos !== prevProps.cueItems.bookmarkPos) {
      if (this.state.currentIndex < 0) {
        this.setState({ currentIndex: 0 });
      }
      this.mediascapeItemsFromBookmark();
    }
  }

  updateTextRangeEvent = () => {
    const { 
      updateBookmarkTextBlock,
      cueItems: { textRanges }
    } = this.props;
    const { currentIndex }  = this.state;
    const index = ( currentIndex > textRanges.length - 1 ) ? 0 : currentIndex;
    
    updateBookmarkTextBlock(index);
  }

  mediascapeItemsFromBookmark = () => {
    const { 
      cueItems: {
        textRanges,
        bookmarkPos
      } 
    } = this.props;

    const { currentIndex }  = this.state;
    if (currentIndex >= 0 && currentIndex < textRanges.length) {
      if (parseInt(textRanges[currentIndex].stop, 10) - 1 <= bookmarkPos) {
        this.setState({ 
          currentIndex: currentIndex  + 1
        },
        () => this.updateTextRangeEvent());
      }
    }
  }

  render() {
    return <div className="textRangeManager" />;
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateBookmarkTextBlock,
    },
    dispatch,
  );

const mapStateToProps = state => ({
  resources: state.resources,
  cueItems: state.cueItems,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TextRangeManager);
