import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { firebaseConnect, isLoaded } from 'react-redux-firebase';
import { updateCueAudioItems } from '../../redux/modules/cueReducer';
import styles from './Share.module.scss';

class Share extends Component {
  render() {
    const { packagedMedia, nfxMedia } = this.props;
    if (isLoaded(packagedMedia) && isLoaded(nfxMedia)) {
      const currentResource = packagedMedia ? packagedMedia : nfxMedia;
      const isWip = !!packagedMedia;
      const port = window.location.port ? `:${window.location.port}` : '';
      const simplePath = `${window.location.protocol}//${window.location.hostname}${port}`;
      return (
        <div className={styles.shareDrawer}>
          <h3>Share</h3>
          {isWip && <div>This is a work in progress version.</div>}
          {isWip && (
            <div className={styles.shareItem}>
              WIP shareable path <a href={window.location.href}>{window.location.href}</a>
            </div>
          )}
          <div className={styles.shareItem}>
            {isWip && <span>published</span>} shareable path{' '}
            <a href={`${simplePath}/mediascape/${currentResource.studioBookId}`}>{`${simplePath}/mediascape/${currentResource.studioBookId}`}</a>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateCueAudioItems,
    },
    dispatch,
  );
const enhance = compose(
  firebaseConnect(props => [
    {
      path: `packagedMedia/v1/en-us/${props.match.params.soundscapeid}`,
      storeAs: 'packagedMedia',
    },
    {
      path: `nfxMedia/v1/en-us/${props.match.params.resourceid}`,
      storeAs: 'nfxMedia',
    },
  ]),
  connect(
    // Map redux state to component props
    ({ firebase: { auth, data, profile }, resources, cueItems, ui, speech }) => ({
      packagedMedia: data.packagedMedia,
      nfxMedia: data.nfxMedia,

      ui,
    }),
    mapDispatchToProps,
  ),
);

export default enhance(Share);
