import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect, isLoaded } from 'react-redux-firebase';
import { RandomCode } from '../../components';
import logo from '../../assets/images/logo_full.svg';
import styles from './Pairing.module.scss';

class Pairing extends Component {
  render() {
    const { pairCode } = this.props;
    console.log('render pairing');
    if (isLoaded(pairCode)) {
      return (
        <div className={styles.Pairing}>
          <div className={styles.NFXlogoContainer}>
            <img src={logo} className={styles.NFXlogo} alt="logo" />
            <div className={styles.helpText}>Enter pairing code on your mobile device</div>
            <RandomCode />
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.Pairing}>
          <div className={styles.NFXlogoContainer}>
            <div className={styles.helpText}>Loading</div>
          </div>
        </div>
      );
    }
  }
}

const enhance = compose(
  firebaseConnect(props => [
    {
      path: 'webclient/pairCode',
      storeAs: 'pairCode',
    },
  ]),
  connect(
    // Map redux state to component props
    ({ firebase: { auth, profile, data } }) => ({
      pairCode: data.pairCode,
      auth,
      profile,
    }),
  ),
);

export default enhance(Pairing);
