const shouldMute = () => {
  const { userAgent } = window.navigator;
  let isSafariMobile = false;
  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
    isSafariMobile = true;
  }

  return isSafariMobile;
};

const initialState = {
  asrWords: [],
  audioItems: null,
  audioStatus: null,
  bookId: null,
  codec: null,
  corpus: '',
  description: '',
  displayTitle: null,
  displayWords: [],
  imageItems: [],
  imageStatus: null,
  isLooping: true,
  isMuted: shouldMute(),
  lastVideo: null,
  mediaComplete: false,
  resourceId: null,
  resourceImagePath: null,
  session: null,
  status: null,
  videoItems: [],
  videoStatus: null
};

export default initialState;
