import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { updateDebugMode, updatePlaying, updateFullScreen } from '../../redux/modules/uiReducer';
import DebugButton from './DebugButton';
import PlayerControls from './PlayerControls';
import VolumeControl from './VolumeControl';
import FullScreenToggle from './FullScreenToggle';
import Microphone from './Microphone';
// import { Button } from '../../components';
import styles from './BottomBar.module.scss';

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuExpanded: false,
    };
  }

  handleDebug() {
    const { isDebug, updateDebugMode } = this.props;
    const updateValue = !isDebug;
    updateDebugMode(updateValue);
  }

  toggleFullScreen() {
    const { isFullScreen, updateFullScreen } = this.props;

    updateFullScreen(!isFullScreen);
  }

  render() {
    const { isHidden = false, profile, readyForSpeech, drawerItem, isDebug, isPlaying, onMouseEnter, onMouseLeave, isFullScreen, isVideo } = this.props;

    const hideMenu = !drawerItem ? isHidden : false;
    return (
      <div
        className={classNames(styles.bottomBar, {
          [styles.hidden]: hideMenu,
        })}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className={styles.centerOptions}>
          <Microphone />
        </div>
        <div className={styles.items}>
          <div className={styles.leftOptions}>
            <PlayerControls isPlaying={isPlaying} readyForSpeech={readyForSpeech} isVideo={isVideo} />
          </div>

          <div className={styles.rightOptions}>
            <VolumeControl />
            <FullScreenToggle onClick={() => this.toggleFullScreen()} isFullScreen={isFullScreen} />
            {profile.type === 'admin' && <DebugButton onClick={() => this.handleDebug()} isDebug={isDebug} />}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  bookmarkPos: state.cueItems.bookmarkPos,
  isDebug: state.ui.isDebugMode,
  isPlaying: state.ui.isPlaying,
  drawerItem: state.ui.drawerItem,
  isFullScreen: state.ui.isFullScreen,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateDebugMode,
      updatePlaying,
      updateFullScreen,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Menu);
