import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { updateVideoItem } from '../../redux/modules/cueReducer';
import { updateSkipBack } from '../../redux/modules/uiReducer';
import { VIDEO_EVENTS } from '../../constants';
import styles from './VideoPlayer.module.scss';

class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.videoContainer = React.createRef();
    this.animPlayer = React.createRef();
    this.splashPlayer = React.createRef();
    this.idlePlayer = React.createRef();
    this.state = {
      isPlaying: false,
      isIdle: false,
      isSplash: true,
    };

    this.animEnded = this.animEnded.bind(this);
    this.animReady = this.animReady.bind(this);
    this.animStarted = this.animStarted.bind(this);
    this.animUpdate = this.animUpdate.bind(this);
    this.idleEnded = this.idleEnded.bind(this);
    this.splashEnded = this.splashEnded.bind(this);
    this.splashReady = this.splashReady.bind(this);

    this.localAnimPaused = false;
    this.localIdlePaused = false;

    this.textDisplayTime = 1000;
  }

  componentDidMount() {
    if (this.splashPlayer.current) {
      this.splashPlayer.current.addEventListener('ended', this.splashEnded);
      this.splashPlayer.current.addEventListener('canplay', this.splashReady);
    }
    this.animPlayer.current.addEventListener('canplay', this.animReady);
    this.animPlayer.current.addEventListener('ended', this.animEnded);
    this.animPlayer.current.addEventListener('play', this.animStarted);
    this.animPlayer.current.addEventListener('timeupdate', this.animUpdate);
    this.idlePlayer.current.addEventListener('play', this.idleEnded);
  }

  componentWillUnmount() {
    if (this.splashPlayer.current) {
      this.splashPlayer.current.removeEventListener('ended', this.splashEnded);
      this.splashPlayer.current.removeEventListener('canplay', this.splashReady);
    }
    this.animPlayer.current.removeEventListener('canplay', this.animReady);
    this.animPlayer.current.removeEventListener('ended', this.animEnded);
    this.animPlayer.current.removeEventListener('play', this.animStarted);
    this.animPlayer.current.removeEventListener('timeupdate', this.animUpdate);
    this.idlePlayer.current.removeEventListener('play', this.idleEnded);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { paths, currentVideoKey, volumeLevel, isPaused, isSkippingBack, updateSkipBack } = this.props;

    if (currentVideoKey !== prevProps.currentVideoKey && currentVideoKey === paths.id) {
      const player = this.animPlayer.current;

      this.vidReadyHandler(player);
    } else if (currentVideoKey !== paths.id) {
      if (this.refs && this.idlePlayer.current) {
        this.idlePlayer.current.pause();
        this.animPlayer.current.pause();
      }
    } else if (currentVideoKey === paths.id && isSkippingBack) {
      const player = this.animPlayer.current;
      updateSkipBack(false);
      this.vidReadyHandler(player);
    }

    if (volumeLevel !== prevProps.volumeLevel) {
      this.animPlayer.current.volume = volumeLevel * 0.01;
      this.idlePlayer.current.volume = volumeLevel * 0.01;
    }

    if (isPaused !== prevProps.isPaused && isPaused) {
      if (this.isVideoPlaying(this.animPlayer.current)) {
        this.animPlayer.current.pause();
        this.localAnimPaused = true;
      }
      if (this.isVideoPlaying(this.idlePlayer.current)) {
        this.idlePlayer.current.pause();
        this.localIdlePaused = true;
      }
    }

    if (isPaused !== prevProps.isPaused && !isPaused) {
      if (this.localAnimPaused) {
        this.animPlayer.current.play();
        this.localAnimPaused = false;
      }
      if (this.localIdlePaused) {
        this.idlePlayer.current.play();
        this.localIdlePaused = false;
      }
    }
  }

  isVideoPlaying(player) {
    return !!(player.currentTime > 0 && !player.ended && !player.paused && player.readyState > 2);
  }

  animReady(e) {
    const { currentVideoKey, updateVideoItem, cueItems } = this.props;

    if (cueItems.videoItems[currentVideoKey]) {
      cueItems.videoItems[currentVideoKey].status = VIDEO_EVENTS.READY;
      const payload = {
        videoItems: cueItems.videoItems,
      };

      updateVideoItem(payload);
    }
    if (this.refs && !this.state.isIdle && !this.state.isSplash) {
      const player = this.animPlayer.current;
      this.vidReadyHandler(player);
    }
  }

  animStarted() {
    const { onVideoStart, updateVideoItem, cueItems, currentVideoKey } = this.props;

    if (cueItems.videoItems[currentVideoKey]) {
      cueItems.videoItems[currentVideoKey].status = VIDEO_EVENTS.PLAYING;
      const payload = {
        videoItems: cueItems.videoItems,
      };

      updateVideoItem(payload);
    }
    onVideoStart();
  }

  splashReady(event) {
    if (this.splashPlayer && this.state.isSplash) {
      const player = this.splashPlayer.current;
      this.vidReadyHandler(player);
    }
  }

  splashEnded(event) {
    this.setState({ isSplash: false });
    const player = this.animPlayer.current;
    this.vidReadyHandler(player);
  }

  animEnded(event) {
    const { onVideoEnd, updateVideoItem, cueItems, currentVideoKey } = this.props;
    const filenameId = event.target.id;

    if (cueItems.videoItems[currentVideoKey]) {
      cueItems.videoItems[currentVideoKey].status = VIDEO_EVENTS.IDLE;
      const payload = {
        videoItems: cueItems.videoItems,
      };

      updateVideoItem(payload);
    }

    if (cueItems.videoItems[currentVideoKey].idle) {
      this.setState({ isIdle: true });
      const player = this.idlePlayer.current;
      this.vidReadyHandler(player);
    }

    // this is here just in case for some reason the event in animUpdate does not trigger
    if (cueItems.videoItems[currentVideoKey].animation === filenameId) {
      onVideoEnd();
    }
  }

  animUpdate() {
    const { onVideoEnd } = this.props;

    const player = this.animPlayer.current;
    const remainingTime = (player.duration - player.currentTime) * 1000;

    if (remainingTime < this.textDisplayTime) {
      onVideoEnd();
    }
  }

  idleEnded(event) {
    const { isFinalVideo, onFinalVideo } = this.props;
    console.log('idle video ended', isFinalVideo);
    if (isFinalVideo) {
      onFinalVideo();
    }
  }

  vidReadyHandler(player) {
    const { isPlaying } = this.props;

    if (player && isPlaying) {
      const playPromise = player.play();

      if (playPromise !== undefined) {
        playPromise
          .then(_ => {
            // Autoplay started!
            player.isPlaying = true;
          })
          .catch(error => {
            // Autoplay was prevented.
            // Show a "Play" button so that user can start playback.
          });
      }
    }
  }

  render() {
    const { paths, currentVideoKey, size, volumeLevel } = this.props;
    const showControls = false;

    const fixedVolume = volumeLevel * 0.01;
    return (
      <div
        className={classNames(styles.videoWrapper, {
          [styles.hidden]: currentVideoKey !== paths.id,
        })}
      >
        {paths.splash && (
          <div
            ref={this.videoContainer}
            className={classNames(styles.videoContainer, {
              [styles.hidden]: !this.state.isSplash,
            })}
          >
            <video
              src={paths.splash.path}
              id={paths.splash.name}
              ref={this.splashPlayer}
              width={size.width}
              height={size.height}
              loop={false}
              controls={showControls}
              playsInline={true}
              preload="auto"
              autoPlay={true}
              volume={fixedVolume}
            />
          </div>
        )}
        <div
          ref={this.videoContainer}
          className={classNames(styles.videoContainer, {
            [styles.hidden]: this.state.isIdle,
          })}
        >
          <video
            src={paths.anim.path}
            id={paths.anim.name}
            ref={this.animPlayer}
            width={size.width}
            height={size.height}
            loop={false}
            controls={showControls}
            playsInline={true}
            preload="auto"
            volume={fixedVolume}
          />
        </div>

        <div
          className={classNames(styles.videoContainer, {
            [styles.hidden]: !this.state.isIdle,
          })}
        >
          <video
            id={paths.idle.name}
            src={paths.idle.path}
            ref={this.idlePlayer}
            width={size.width}
            height={size.height}
            loop={true}
            controls={showControls}
            playsInline={true}
            preload="auto"
            volume={fixedVolume}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateVideoItem,
      updateSkipBack,
    },
    dispatch,
  );

const mapStateToProps = state => ({
  cueItems: state.cueItems,
  volumeLevel: state.ui.volumeLevel,
  isPaused: state.ui.isPaused,
  isSkippingBack: state.ui.isSkippingBack,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VideoPlayer);
