// Error codes (mostly following Android error names and codes)
export default class ErrorCodes {
  static get ERR_NETWORK() {
    return 2;
  }

  static get ERR_AUDIO() {
    return 3;
  }

  static get ERR_SERVER() {
    return 4;
  }

  static get ERR_CLIENT() {
    return 5;
  }
}
