import * as types from './actionTypes';
import {
  createCode,
  loadUserStorage,
  loadPairStorage,
  saveUserStorage,
  removePairStorage
} from '../../../helpers/pairCode';

export const generatePairCode = payload => (dispatch, getState, getFirebase) => {
  const firebase = getFirebase();
  const existingPair = loadPairStorage();
  const existingUser = loadUserStorage();
  if (!existingPair && !existingUser) {
    const newCode = createCode(payload.pairCode);

    firebase
      .update(`webclient/pairCode/${newCode}`, {
        userId: 'pending',
        created: Date.now()
      })
      .then((response) => {
        dispatch({
          type: types.PAIR_GENERATE_CODE,
          payload: newCode
        });
      });
  } else if (!existingUser) {
    dispatch({
      type: types.PAIR_GENERATE_CODE,
      payload: existingPair.pairCode
    });
  } else if (existingPair) {
    console.log('existingPair', existingPair);
    firebase.remove(`webclient/pairCode/${existingPair.pairCode}`).then((response) => {
      removePairStorage();
      dispatch({
        type: types.PAIR_FIREBASE_CODE_REMOVED,
        payload: existingPair.pairCode
      });
    });
  }
};

export const setSessionId = payload => (dispatch, getState, getFirebase) => {
  const firebase = getFirebase();
  if (payload.sessionId) {
    firebase
      .update(`webclient/session/${payload.userId}/voice`, {
        sessionId: payload.sessionId,
        created: Date.now()
      })
      .then((response) => {
        dispatch({
          type: types.SET_SESSION_ID
        });
      });
  }
};

export const setSessionStatus = payload => (dispatch, getState, getFirebase) => {
  const firebase = getFirebase();

  firebase
    .update(`webclient/session/${payload.userId}/voice`, {
      status: payload.status,
      created: Date.now()
    })
    .then((response) => {
      dispatch({
        type: types.SET_SESSION_STATUS,
        payload
      });
    });
};

export const fetchUserId = payload => (dispatch) => {
  const user = loadUserStorage();
  const result = user ? user.userId : null;
  dispatch({
    type: types.PAIR_LOCALSTORAGE_FETCH,
    payload: result
  });
};

export const saveUserId = payload => (dispatch, getState, getFirebase) => {
  const firebase = getFirebase();
  saveUserStorage(payload.id);
  dispatch({
    type: types.PAIR_LOCALSTORAGE_SAVE,
    payload
  });
  firebase.remove(`webclient/pairCode/${payload.pairCode}`).then((response) => {
    removePairStorage();
    dispatch({
      type: types.PAIR_FIREBASE_CODE_REMOVED,
      payload: payload.pairCode
    });
  });
};

export const logStatus = payload => (dispatch, getState, getFirebase) => {
  const firebase = getFirebase();
  const user = loadUserStorage();
  const userId = user ? user.userId : null;
  if (userId) {
    dispatch({
      type: types.STATUS_UPDATING
    });

    firebase
      .update(`webclient/session/${userId}`, {
        status: payload,
        browser: navigator.userAgent
      })
      .then((response) => {
        // console.log('update status', payload, response);
        dispatch({
          type: types.STATUS_SAVED,
          payload: response
        });
      })
      .catch((err) => {
        // console.log('status updated faild', err);
        dispatch({
          type: types.STATUS_ERROR,
          payload: err
        });
      });
  }
};

export const setEventData = payload => (dispatch, getState, getFirebase) => {
  const firebase = getFirebase();
  const user = loadUserStorage();
  const userId = user ? user.userId : null;
  if (userId) {
    dispatch({
      type: types.EVENT_UPDATING
    });
    setTimeout(() => {
      firebase
        .update(`webclient/session/${userId}`, {
          event: payload
        })
        .then((response) => {
          firebase.update(`webclient/session/${userId}`, {
            current_word: 0,
            bookmarkPosition: 0
          });
          dispatch({
            type: types.EVENT_SET,
            payload: response
          });
        })
        .catch((err) => {
          dispatch({
            type: types.EVENT_ERROR,
            payload: err
          });
        });
    }, 1000);
  }
};
