import React, { Component } from 'react';
import styles from './Wait.module.scss';

class Wait extends Component {
  render() {
    return (
      <div className={styles.waitMode}>
        <div>
          <h2>Waiting for device</h2>
          <div>Please select a title from the Novel Effect app to begin</div>
        </div>
      </div>
    );
  }
}

export default Wait;
