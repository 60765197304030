import React, { Component } from 'react';
import classnames from 'classnames';
import styles from './Input.module.scss';

// type Props = {
//   value?: string,
//   className?: string,
//   disabled?: boolean,
//   raised?: boolean,
//   href?: string,
//   inverse?: boolean,
//   onChange?: Function,
//   onKeyDown?: Function,
//   placeholder?: string,
//   size?: 'small' | 'normal' | 'large',
//   theme?: 'error' | 'dark' | 'light',
//   type?: string,
//   maxLength?: string,
// };

class Input extends Component {
  render() {
    const {
      value = '',
      onChange,
      onKeyDown,
      onBlur,
      disabled = false,
      placeholder,
      size = 'normal',
      theme = 'light',
      className = '',
      type = 'text',
      icon,
      required,
      min,
      max,
      step,
      maxLength,
    } = this.props;

    const componentProps = {
      className: classnames(styles.input, styles[`theme--${theme}`], styles[`size--${size}`], disabled && styles.disabled, className),
    };

    return (
      <div className={classnames(styles.group, componentProps.className)}>
        <input
          className={styles.customInput}
          ref={input => {
            this.textInput = input;
          }}
          type={type}
          min={min}
          max={max}
          step={step}
          required={required}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          disabled={disabled}
          maxLength={maxLength}
          placeholder={placeholder}
        />
        {icon && (
          <span className={styles.icon}>
            <i className={classnames('fa', icon)} aria-hidden="true" />
          </span>
        )}
        <span className={styles.highlight} />
        <span className={styles.bar} />
        {value && <label>{placeholder}</label>}
      </div>
    );
  }
}

export default Input;
