import request from 'request';
import Recorder from './recorder';
import DictateDefaults from './DictateDefaults';
import ErrorCodes from './ErrorCodes';
import EventCodes from './EventCodes';

export class DictateClass {
  constructor(config) {
    this.playerServer = config.playerServer;
    this.speakerServer = config.speakerServer;
    this.audioSourceId = config.audioSourceId;
    this.referenceHandler = config.referenceHandler || DictateDefaults.REFERENCE_HANDLER;
    this.contentType = config.contentType || DictateDefaults.CONTENT_TYPE;
    this.interval = config.interval || DictateDefaults.INTERVAL;
    this.onReadyForSpeech = config.onReadyForSpeech || function() {};
    this.onEndOfSpeech = config.onEndOfSpeech || function() {};
    this.onBookmark = config.onBookmark || function(data) {};
    this.onResults = config.onResults || function(data) {};
    this.onSpeechClientReady = config.onSpeechClientReady || function(data) {};
    this.onServerStatus = config.onServerStatus || function(data) {};
    this.onEndOfSession = config.onEndOfSession || function() {};
    this.onEvent = config.onEvent || function(e, data) {};
    this.onError = config.onError || function(e, data) {};
    this.rafCallback = config.rafCallback || function(time) {};

    // Initialized by init()
    this.audioContext = null;
    this.recorder = null;
    // Initialized by startListening()
    this.wsPlayerServer = null;
    this.wsSpeakerServer = null;
    this.intervalKey = null;

    this.postAudio = this.postAudio.bind(this);
  }

  init() {
    this.audioSourceConstraints = {};
    this.onEvent(EventCodes.MSG_WAITING_MICROPHONE, 'Waiting for approval to access your microphone ...');
    try {
      window.AudioContext = window.AudioContext || window.webkitAudioContext;
      navigator.mediaDevices.getUserMedia = navigator.mediaDevices.getUserMedia || navigator.getUserMedia;
      // navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
      window.URL = window.URL || window.webkitURL;
      this.audioContext = new AudioContext();
    } catch (e) {
      // Firefox 24: TypeError: AudioContext is not a constructor
      // Set media.webaudio.enabled = true (in about:config) to fix this.
      // this.onError(ErrorCodes.ERR_CLIENT, 'Error initializing Web Audio browser: ' + e);
    }

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      if (this.audioSourceId) {
        this.audioSourceConstraints.audio = {
          optional: [{ sourceId: this.audioSourceId }],
        };
      } else {
        this.audioSourceConstraints.audio = true;
      }
      navigator.mediaDevices
        .getUserMedia(this.audioSourceConstraints)
        .then(stream => {
          this.stream = stream;
          this._startUserMedia(stream);
        })
        .catch(err => {
          // this.onError(ErrorCodes.ERR_CLIENT, err);
        });
    } else {
      // this.onError(ErrorCodes.ERR_CLIENT, 'No user media support');
    }
  }

  createBookmarking(soundscape) {
    var setupData = {
      soundscape: soundscape,
    };
    if (!!this.wsPlayerServer) {
      if (this.wsPlayerServer.readyState !== WebSocket.OPEN) {
        this.wsPlayerServer.onopen = () => this.wsPlayerServer.send(JSON.stringify(setupData));
      } else {
        try {
          this.wsPlayerServer.send(JSON.stringify(setupData));
        } catch (err) {
          console.error(err);
        }
      }
    }
  }

  setBookmarkTextBlock(textBlockIndex) {
    var data = { textBlock: textBlockIndex };

    if (this.wsPlayerServer.readyState !== WebSocket.OPEN) {
      this.wsPlayerServer.onopen = () => this.wsPlayerServer.send(JSON.stringify(data));
    } else {
      try {
        this.wsPlayerServer.send(JSON.stringify(data));
      } catch (err) {
        this.onError(err);
      }
    }
  }

  mute() {
    if (this.wsPlayerServer && this.wsPlayerServer.readyState === this.wsPlayerServer.OPEN) {
      console.log('DictateClass - mute');
      this.wsPlayerServer.send(JSON.stringify({ muteRemoteMic: true }));
    }
  }

  muteLocalSpeaker() {
    // console.log('mute local speaker');

    const _this = this;
    console.log('mute', this.recorder);
    //this.recorder.stop();
    // this.stream.getTracks().forEach(track => track.stop());
    clearInterval(this.intervalKey);
    // Stop recording
    if (this.recorder) {
      this.recorder.stop();
      this.stream.getTracks().forEach(track => track.stop());
      this.onEvent(EventCodes.MSG_STOP, 'Stopped recording');
      this.recorder.export16kMono(function(blob) {
        _this.recorder.clear();
      }, 'audio/x-raw');
    }
  }

  unmute() {
    if (this.wsPlayerServer && this.wsPlayerServer.readyState === this.wsPlayerServer.OPEN) {
      console.log('DictateClass - unmute');
      this.wsPlayerServer.send(JSON.stringify({ muteRemoteMic: false }));
    } else {
      console.warn('unable to unmute', this.wsPlayerServer);
    }
  }

  unmuteLocalSpeaker() {
    // console.log('unmute local speaker');

    navigator.mediaDevices
      .getUserMedia(this.audioSourceConstraints)
      .then(stream => {
        this.stream = stream;
        this._startUserMedia(stream);
        this.handleWSOpen(null);
        this.recorder.record();
      })
      .catch(err => {
        this.onError(ErrorCodes.ERR_CLIENT, err);
      });
  }

  // Start recording and transcribing
  startListening() {
    console.log('DictateClass - startListening');
    if (!this.recorder) {
      this.onError(ErrorCodes.ERR_AUDIO, 'Recorder undefined');
      return;
    }

    if (!this.wsSpeakerServer) {
      try {
        // console.log('create websocket');
        this.setSpeakerServer();
      } catch (e) {
        this.onError(ErrorCodes.ERR_CLIENT, 'No web socket support in this browser!');
      }
    }
  }

  // Stop listening, i.e. recording and sending of new input.
  stopListening() {
    console.log('DictateClass - stopListening');
    // Stop the regular sending of audio
    const _this = this;
    clearInterval(this.intervalKey);
    // Stop recording
    if (this.recorder) {
      this.recorder.stop();
      this.onEvent(EventCodes.MSG_STOP, 'Stopped recording');
      // Push the remaining audio to the server
      this.recorder.export16kMono(function(blob) {
        _this._socketSend(blob);
        _this._socketSend(DictateDefaults.TAG_END_OF_SENTENCE);
        _this.recorder.clear();
      }, 'audio/x-raw');
      this.onEndOfSpeech();
    } else {
      this.onError(DictateDefaults.ERR_AUDIO, 'Recorder undefined');
    }
  }

  // Cancel everything without waiting on the server
  cancel() {
    console.log('DictateClass - cancel');
    // Stop the regular sending of audio (if present)
    clearInterval(this.intervalKey);
    if (this.recorder) {
      this.recorder.stop();
      this.recorder.clear();
      this.onEvent(EventCodes.MSG_STOP, 'Stopped recording');
    }
    if (this.wsPlayerServer) {
      this.wsPlayerServer.close();
      this.wsPlayerServer = null;
    }
  }

  // Sets the URL of the speech server
  setServer(playerServer, speakerServer, sessionID, isSTB = false) {
    this.playerServer = playerServer ? playerServer + '/' + sessionID : this.playerServer;
    this.speakerServer = speakerServer ? speakerServer + '/' + sessionID : this.speakerServer;
    try {
      this.wsPlayerServer = this._createPlayerWebSocket();
      if (!isSTB) this.setSpeakerServer();
    } catch (e) {
      this.onError(ErrorCodes.ERR_CLIENT, 'No web socket support in this browser!');
    }
    this.onEvent(EventCodes.MSG_SERVER_CHANGED, 'Server changed: ' + playerServer);
  }

  // simple update of speakerServer via external voice client
  setupExternalVoice(speakerServer, sessionID) {
    this.speakerServer = speakerServer && sessionID ? speakerServer + '/' + sessionID : this.speakerServer;
  }

  setSpeakerServer() {
    const _this = this;
    var url = this.speakerServer + '?' + this.contentType;
    this.wsSpeakerServer = new WebSocket(url);

    this.wsSpeakerServer.onmessage = function(e) {
      // handles mute/unmute events
      var data = e.data;
      var res = JSON.parse(data);
      if (res.hasOwnProperty('muteRemoteMic')) {
        var toMute = res['muteRemoteMic'];
        if (toMute) {
          _this.muteLocalSpeaker();
        } else {
          _this.unmuteLocalSpeaker();
        }
      }
    };

    // Start recording only if the socket becomes open
    this.wsSpeakerServer.onopen = e => this.handleWSOpen(e);

    this.wsSpeakerServer.onclose = function(e) {
      console.log('speaker closed.');
      _this.muteLocalSpeaker();
    };

    this.wsSpeakerServer.onerror = function(e) {
      var data = e.data;
      _this.onError(ErrorCodes.ERR_NETWORK, data);
    };
  }

  handleWSOpen(e) {
    // console.log('WebSocket is open now.');
    const _this = this;
    this.intervalKey = setInterval(function() {
      // TODO: Safari claims recorder is null
      if (_this.recorder) {
        _this.recorder.export16kMono(_this.postAudio, 'audio/x-raw');
      }
    }, _this.interval);
    // Start recording
    // recorder.record();
    this.onReadyForSpeech();
    this.onEvent(EventCodes.MSG_WEB_SOCKET_OPEN, e);
  }

  // Sends reference text to speech server
  submitReference(text, successCallback, errorCallback) {
    var headers = {};
    if (this['user_id']) {
      headers['User-Id'] = this['user_id'];
    }
    if (this['content_id']) {
      headers['Content-Id'] = this['content_id'];
    }

    request
      .headers(headers)
      .post(this.referenceHandler, { data: text })
      .on('response', successCallback)
      .on('error', errorCallback);
  }

  _startUserMedia(stream) {
    var input = this.audioContext.createMediaStreamSource(stream);
    this.onEvent(EventCodes.MSG_MEDIA_STREAM_CREATED, 'Media stream created');
    //Firefox loses the audio input stream every five seconds
    // To fix added the input to window.source
    window.source = input;

    // make the analyser available in window context
    window.userSpeechAnalyser = this.audioContext.createAnalyser();
    input.connect(window.userSpeechAnalyser);

    this.rafCallback();

    this.recorder = new Recorder(input);
    this.onEvent(EventCodes.MSG_INIT_RECORDER, 'Recorder initialized');
  }

  _socketSend(item) {
    if (this.wsSpeakerServer && this.wsSpeakerServer.readyState === WebSocket.OPEN) {
      var state = this.wsSpeakerServer.readyState;
      if (state === 1) {
        // If item is an audio blob
        if (item instanceof Blob) {
          console.log(item)
          if (item.size > 0) {
            this.wsSpeakerServer.send(item);
            this.onEvent(EventCodes.MSG_SEND, 'Send: blob: ' + item.type + ', ' + item.size);
            // console.log(MSG_SEND, 'Send: blob: ' + item.type + ', ' + item.size);
          } else {
            //this.onEvent(EventCodes.MSG_SEND_EMPTY, 'Send: blob: ' + item.type + ', EMPTY');
          }
          // Otherwise it's the EOS tag (string)
        } else {
          this.wsSpeakerServer.send(item);
          this.onEvent(EventCodes.MSG_SEND_EOS, 'Send tag: ' + item);
        }
      } else {
        this.onError(ErrorCodes.ERR_NETWORK, 'WebSocket: readyState!=1: ' + state + ': failed to send: ' + item);
      }
    } else {
      //this.onError(ErrorCodes.ERR_CLIENT, 'No web socket connection: failed to send: ' + item);
      console.log('speaker server already closed, skipping send');
    }
  }

  _createPlayerWebSocket() {
    const _this = this;
    var url = this.playerServer;

    let ws = new WebSocket(url);

    ws.onmessage = function(e) {
      var data = e.data;
      // console.log(e);
      // console.log('onmessage', EventCodes.MSG_WEB_SOCKET, data);
      _this.onEvent(EventCodes.MSG_WEB_SOCKET, data);
      var res = JSON.parse(data);

      if (res.hasOwnProperty('workerReady')) {
        _this.onServerStatus(res);
      }

      if (res.hasOwnProperty('bookmark')) {
        _this.onEvent(EventCodes.MSG_SEND, 'bookmark: ' + res['bookmark']);
        _this.onBookmark(res['bookmark']);
      }

      if (res.hasOwnProperty('speechClientReady')) {
        _this.onEvent(EventCodes.MSG_SEND, 'speechClientReady: ' + res['speechClientReady']);
        _this.onSpeechClientReady(res['speechClientReady']);
      }

      if (res.hasOwnProperty('error')) {
        _this.onError(ErrorCodes.ERR_CLIENT, 'bookmark error: ' + res['error']);
      }
    };

    ws.onclose = function(e) {
      // The server closes the connection (only?)
      // when its endpointer triggers.
      _this.onEndOfSession();
      _this.onEvent(EventCodes.MSG_WEB_SOCKET_CLOSE, e.code + '/' + e.reason + '/' + e.wasClean);
    };

    ws.onerror = function(e) {
      var data = e.data;
      _this.onError(ErrorCodes.ERR_NETWORK, data);
    };

    return ws;
  }

  postAudio(blob) {
    console.log('@@@@@ postAudio(blob) *****')
    console.log(blob)
    console.log('@@@@@')
    this._socketSend(blob);
    this.recorder.clear();
  }

  getDescription(code) {
    if (code in DictateDefaults.SERVER_STATUS_CODE) {
      return DictateDefaults.SERVER_STATUS_CODE[code];
    }
    return 'Unknown error';
  }
}
