import { createSelector } from 'reselect';

export const firebaseSelector = state => state.firebase;

export const dataSelector = createSelector(
  firebaseSelector,
  firebase => firebase.data
);

export const collectionSelector = createSelector(
  dataSelector,
  data => data.collection
);
