import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect, isLoaded } from 'react-redux-firebase';
import CarouselItem from './CarouselItem';
import CarouselDetails from './CarouselDetails';
import { Loading } from '..';
import { areImagesLoaded } from '../../selectors/ui-selectors';
import styles from './Carousel.module.scss';

class Carousel extends Component {
  constructor(props) {
    super(props);

    this.audioPlayer = React.createRef();
    this.resourceList = React.createRef();
    this.contentDiv = React.createRef();

    this.state = {
      currentIndex: 0,
      itemWidth: 0
    };

    this.idleTimer = null;
    this.idleWait = 2000;

    this.handleKey = this.handleKey.bind(this);

    this.VZUAL = global.VZUAL || {};

    this.defaultItemDisplay = 3;
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKey);

    if (
      this.VZUAL
      && this.VZUAL.VoiceClient
      && this.VZUAL.VoiceClient.stopNovelSession
    ) {
      this.VZUAL.VoiceClient.stopNovelSession();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKey);
  }

  // TODO: make this a central service
  // playAudio(file) {
  //   const audioPath = `https://d204mhn7hn8u4n.cloudfront.net/${file}`;
  //   if (this.VZUAL && this.VZUAL.TTS && this.VZUAL.TTS.play) {
  //     this.VZUAL.TTS.play(audioPath, 1);
  //   }

  //   if (!this.VZUAL) {
  //     if (this.audioPlayer) {
  //       this.audioPlayer.current.src = audioPath;
  //       this.audioPlayer.current.play();
  //     }
  //   }
  // }

  handleKey(e) {
    const { collection, history, useFirstPlay } = this.props;
    e.stopPropagation();
    e.preventDefault();

    let selectedItem = 0;

    const {
      currentIndex
    } = this.state;

    const section = useFirstPlay ? 'firstplay' : 'mediascape';

    switch (e.key) {
    case 'ArrowRight':
      if (currentIndex < this.maxSize) {
        selectedItem = currentIndex + 1;
      } else {
        selectedItem = 0;
      }
      this.setState({ currentIndex: selectedItem });
      break;
    case 'ArrowLeft':
      if (currentIndex > 0) {
        selectedItem = currentIndex - 1;
      } else {
        selectedItem = collection.length - 1;
      }
      this.setState({ currentIndex: selectedItem });
      break;
    case 'Enter':
      // eslint-disable-next-line no-console
      console.log(collection[currentIndex].id);
      history.push(`/${section}/${collection[currentIndex].id}`);
      break;
    default:
      break;
    }
  }

  handleItemSize(size) {
    const {
      itemWidth
    } = this.state;

    if (itemWidth === 0) {
      this.setState({ itemWidth: size });
    }
  }

  handleOutsideClick(index) {
    this.setState({ currentIndex: index });
  }

  render() {
    const {
      allImagesLoaded,
      collection,
      history,
      onBackgroundSet
    } = this.props;

    const {
      currentIndex
    } = this.state;

    if (isLoaded(collection)) {
      this.maxSize = collection.length - 1;

      return (
        <div>
          <div className={allImagesLoaded ? styles.Content : styles.ContentNotReady}>
            <div className={styles.resourceList} ref={this.resourceList}>
              {collection.map((item, index) => {
                const isActive = index === currentIndex;
                let position = 0;
                if (index === currentIndex - 1) {
                  position = -1;
                } else if (index === currentIndex + 1) {
                  position = 1;
                }

                if (currentIndex === collection.length - 1) {
                  if (index === 0) {
                    position = 1;
                  }
                }

                if (currentIndex === 0) {
                  if (index === collection.length - 1) {
                    position = -1;
                  }
                }

                return (
                  <CarouselItem
                    key={`carous-item-${index + 1}`}
                    id={item.id}
                    index={index}
                    currentId={currentIndex}
                    isActive={isActive}
                    position={position}
                    history={history}
                    onClick={() => this.handleOutsideClick(index)}
                  />
                );
              })}
            </div>

            <audio ref={this.audioPlayer} />
            <CarouselDetails
              id={collection[currentIndex].id}
              onChange={path => onBackgroundSet(path)}
            />
          </div>
        </div>
      );
    }
    return (
      <div className={styles.loadingWrap}>
        <Loading />
      </div>
    );
  }
}

const enhance = compose(
  firebaseConnect(props => [
    {
      path: `/collections/${props.collectionId}/items`,
      storeAs: 'collection'
    }
  ]),
  connect(
    // Map redux state to component props
    (state) => {
      const { firebase: { data } } = state;
      return {
        collection: data.collection,
        allImagesLoaded: areImagesLoaded(state)
      };
    },
  ),
);

export default enhance(Carousel);
