export const GENERATE_MEDIA_ITEMS = 'cue/GENERATE_MEDIA_ITEMS';
export const GENERATE_VIDEO_ITEMS = 'cue/GENERATE_VIDEO_ITEMS';
export const GENERATE_AUDIO_ITEMS = 'cue/GENERATE_AUDIO_ITEMS';
export const GENERATE_TEXTRANGE_ITEMS = 'cue/GENERATE_TEXTRANGE_ITEMS';

export const FETCH_CUEITEMS_FROM_STORAGE = 'cue/FETCH_CUEITEMS_FROM_STORAGE';
export const STORE_CUEITEMS_FROM_STORAGE = 'cue/STORE_CUEITEMS_FROM_STORAGE';

export const FETCH_CUEITEMS_FROM_FIREBASE = 'cue/FETCH_CUEITEMS_FROM_FIREBASE';
export const STORE_CUEITEMS_FROM_FIREBASE = 'cue/STORE_CUEITEMS_FROM_FIREBASE';

export const MEDIA_ITEM_UPDATE = 'cue/MEDIA_ITEM_UPDATE';
export const VIDEO_ITEM_UPDATE = 'cue/VIDEO_ITEM_UPDATE';
export const AUDIO_ITEM_UPDATE = 'cue/AUDIO_ITEM_UPDATE';
export const AUDIO_ITEMS_UPDATE = 'cue/AUDIO_ITEMS_UPDATE';

export const TEXTRANGE_ITEM_UPDATE = 'cue/TEXTRANGE_ITEM_UPDATE';

export const CUE_STATUS_UPDATE = 'cue/CUE_STATUS_UPDATE';

export const BOOKMARK_POSITION_UPDATE = 'cue/BOOKMARK_POSITION_UPDATE';
export const BOOKMARK_POSITION_RESET = 'cue/BOOKMARK_POSITION_RESET';

export const RESETTING_CUES = 'cue/RESETTING_CUES';
