import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { Loading } from '..';
import { imageLoaded } from '../../redux/modules/uiReducer';
import styles from './CarouselItem.module.scss';

class CarouselItem extends Component {
  static propTypes = {
    collectionItem: PropTypes.shape({
      images: PropTypes.shape({}),
      studioBookId: PropTypes.string,
      title: PropTypes.string
    }),
    device: PropTypes.shape({
      isVZSTB: PropTypes.bool,
      isFiretv: PropTypes.bool
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func
    }).isRequired,
    isActive: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    position: PropTypes.number.isRequired
  }

  static defaultProps = {
    collectionItem: {}
  }

  constructor(props) {
    super(props);
    this.imageContainer = React.createRef();
  }

  shouldComponentUpdate(nextProps) {
    const {
      isActive,
      position,
      collectionItem
    } = this.props;

    if (
      isActive !== nextProps.isActive || 
      position !== nextProps.position ||
      collectionItem !== nextProps.collectionItem
      ) {
      return true;
    }
    return false;
  }

  handleClick = (resourceId, isActive) => {
    const { history, onClick } = this.props;
    // this.props.history.push('/mediascape');
    if (isActive) {
      history.push(`/mediascape/${resourceId}`);
    } else {
      onClick();
    }
  }

  fetchCoverImage = (images) => {
    const { device, id } = this.props;
    const useCF = device.isVZSTB || device.isFiretv;

    let activeImagePath;

    Object.keys(images).forEach((key) => {
      if (useCF) {
        activeImagePath = `${process.env.PUBLIC_URL}/images/${id}.JPEG`;
      } else if (images[key].type === 'square' && images[key].isActive) {
        activeImagePath = images[key].downloadURL;
      }
    });
    return activeImagePath;
  }

  handleImageOnLoad = () => {
    const { imageLoaded } = this.props;
    imageLoaded()
  }

  render() {
    const {
      collectionItem, isActive, position
    } = this.props;

    if (isLoaded(collectionItem) && !isEmpty(collectionItem)) {
      const activeImage = this.fetchCoverImage(collectionItem.images);

      return (
        <div
          className={classNames(styles.contentItem, {
            [styles.selected]: isActive,
            [styles.left]: position === -1,
            [styles.right]: position === 1
          })}
          onClick={() => this.handleClick(collectionItem.studioBookId, isActive)}
          onKeyDown={() => this.handleClick(collectionItem.studioBookId, isActive)}
          role="presentation"
        >
          {/* <div className={styles.title}>{collectionItem.title}</div> */}

          <div className={styles.imageContainer} ref={this.imageContainer}>
            <img 
              className={styles.mediaImage}
              src={activeImage}
              alt={`book cover art for ${collectionItem.title}`}
              onLoad={this.handleImageOnLoad}
            />
          </div>
        </div>
      );
    }
    return (
      <div className={styles.loadingWrap}>
        <Loading />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      imageLoaded
    },
    dispatch,
  );

const enhance = compose(
  firebaseConnect(props => [
    {
      path: `nfxMedia/v1/en-us/${props.id}`,
      storeAs: `${props.id}`
    }
  ]),
  connect(
    // Map redux state to component props
    ({ firebase: { data }, device }, props) => ({
      collectionItem: data[props.id],
      device
    }),
    mapDispatchToProps
  ),
);

export default enhance(CarouselItem);
