import React, { Component } from 'react';
import classnames from 'classnames';
import Button from './Button';
import styles from './SplitButton.module.scss';

// type Props = {
//   children?: React$Element<any> | Array<React$Element<any>>,
//   className?: string,
//   disabled?: boolean,
//   raised?: boolean,
//   href?: string,
//   inverse?: boolean,
//   onClick?: Function,
//   size?: 'small' | 'normal' | 'large',
//   theme?: 'primary' | 'success' | 'warning' | 'danger' | 'dark' | 'link',
//   type?: string,
// };

class SplitButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownActive: false,
    };
  }

  render() {
    const { children, label = 'button', theme = 'primary', onClick } = this.props;

    return (
      <div className={styles.buttonGroup} onMouseLeave={() => this.setState({ isDropdownActive: false })}>
        <Button className={styles.button} theme={theme} onClick={onClick}>
          {label}
        </Button>
        <Button
          className={classnames(styles.dropdownToggle, styles.dropdownToggleSplit)}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          theme={theme}
          onClick={() => this.setState({ isDropdownActive: !this.state.isDropdownActive })}
        >
          <i className={classnames('fa', 'fa-angle-down')} aria-hidden="true" />
        </Button>
        <div className={classnames(styles.dropdownMenu, { [styles.active]: this.state.isDropdownActive })}>
          {children.map((item, index) => (
            <div key={index} className={styles.dropdownItem} {...item.props} />
          ))}
        </div>
      </div>
    );
  }
}

export default SplitButton;

/*
<a className="dropdown-item" href="#">Action</a>
<a className="dropdown-item" href="#">Another action</a>
<a className="dropdown-item" href="#">Something else here</a>
<div className="dropdown-divider"></div>
<a className="dropdown-item" href="#">Separated link</a>

*/
