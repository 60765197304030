import React from 'react';
import styles from './VolumeToggle.module.scss';

const VolumeToggle = ({ onClick, isMuted }: Props) => {
  return (
    <div className={styles.volumeButtom} onClick={onClick} title="Toggles the sound output on and off">
      {!isMuted && (
        <svg xmlns="http://www.w3.org/2000/svg" width="32.656" height="26.355" viewBox="0 0 32.656 26.355">
          <g id="volume_on" transform="translate(-865.344 -399.617)">
            <path
              id="Path_1322"
              d="M167.351 732a.786.786 0 0 0-.841.1l-6.424 5.252a.49.49 0 0 1-.308.11h-8.195a.793.793 0 0 0-.792.793v6.7l-.036.029a.15.15 0 0 0-.055.117.154.154 0 0 0 .055.118l.036.028v6.7a.792.792 0 0 0 .792.792h8.195a.489.489 0 0 1 .309.111l6.423 5.252a.784.784 0 0 0 .5.179.8.8 0 0 0 .342-.077.786.786 0 0 0 .452-.715V732.71a.785.785 0 0 0-.453-.71z"
              className={styles.speakerIcon}
              data-name="Path 1322"
              transform="translate(714.643 -332.301)"
            />
            <path
              id="Path_1323"
              d="M174.826 751.44a6.5 6.5 0 0 0 3.906-5.891l-.021-.239c-.016-.179-.031-.359-.056-.538a6.291 6.291 0 0 0-2.975-4.755 2.65 2.65 0 0 0-1.361-.526 1.341 1.341 0 0 0-.175.011 1.161 1.161 0 0 0-1.019.788 1 1 0 0 0 .218 1.2 6.156 6.156 0 0 0 1 .775 3.612 3.612 0 0 1 1.811 3.591 3.376 3.376 0 0 1-1.529 2.713c-.087.059-.174.115-.261.171a4.745 4.745 0 0 0-.651.47 2.361 2.361 0 0 0-.609.732 1.094 1.094 0 0 0 .32 1.255 1.221 1.221 0 0 0 1.402.243z"
              className={styles.speakerIcon}
              data-name="Path 1323"
              transform="translate(713.379 -332.73)"
            />
            <path
              id="Path_1324"
              d="M184.719 745.2a7.156 7.156 0 0 0-.05-.817v-.3c-.032-.2-.059-.393-.088-.59-.063-.445-.122-.867-.223-1.277a13.439 13.439 0 0 0-5.982-8.472 11.4 11.4 0 0 0-2.073-1 .912.912 0 0 0-.32-.058 1.259 1.259 0 0 0-1.042.656 1.247 1.247 0 0 0-.15.4 1.144 1.144 0 0 0 .3 1.1 3.419 3.419 0 0 0 .8.541 11.558 11.558 0 0 1 2.815 1.915 11.077 11.077 0 0 1 3.057 5.032l.021-.03a10.856 10.856 0 0 1 .223 4.39 12.03 12.03 0 0 1-.306 1.457 11.43 11.43 0 0 1-.576 1.597 10.617 10.617 0 0 1-4.575 4.843l-.374.21c-.309.172-.6.333-.881.514a1.031 1.031 0 0 0-.5.75 1.423 1.423 0 0 0-.026.382 1.212 1.212 0 0 0 .581.982 1.4 1.4 0 0 0 1.3.056 12.6 12.6 0 0 0 3.751-2.459 13.567 13.567 0 0 0 3.976-7.115v-.007c0-.017.009-.034.012-.051.079-.383.139-.771.2-1.159.026-.172.053-.343.081-.515v-.151a7.14 7.14 0 0 0 .049-.824z"
              className={styles.speakerIcon}
              data-name="Path 1324"
              transform="translate(713.281 -332.344)"
            />
          </g>
        </svg>
      )}
      {isMuted && (
        <svg xmlns="http://www.w3.org/2000/svg" width="34.852" height="30.518" viewBox="0 0 34.852 30.518">
          <g id="volume_off" transform="translate(-755.799 -395)">
            <path
              id="Path_1317"
              d="M296.7 730.3a1.583 1.583 0 0 0-1-.344 1.6 1.6 0 0 0-1.1.423l-29.459 27.9a1.2 1.2 0 0 0 .068 1.847 1.583 1.583 0 0 0 1 .344h.066a1.592 1.592 0 0 0 1.034-.422l29.459-27.9a1.2 1.2 0 0 0-.068-1.848z"
              className={styles.nopeBar}
              data-name="Path 1317"
              transform="translate(491.058 -334.954)"
            />
            <g id="Group_1674" data-name="Group 1674" transform="translate(758.191 397.249)">
              <path
                id="Path_1318"
                d="M267.387 745.573v6.644a.786.786 0 0 0 .785.785h.019l16.072-15.222v-4.637a.786.786 0 0 0-1.283-.608l-6.372 5.21a.489.489 0 0 1-.307.109h-8.129a.786.786 0 0 0-.785.785v6.644l-.035.029a.15.15 0 0 0 0 .232z"
                className={styles.mutedIcon}
                data-name="Path 1318"
                transform="translate(-267.297 -732.357)"
              />
              <path
                id="Path_1319"
                d="M278.053 754.747l5.617 4.593a.778.778 0 0 0 .495.178.79.79 0 0 0 .339-.077.778.778 0 0 0 .449-.709v-10.52z"
                className={styles.mutedIcon}
                data-name="Path 1319"
                transform="translate(-267.988 -733.375)"
              />
            </g>
            <path
              id="Path_1320"
              d="M295.189 745.138a6.255 6.255 0 0 0-2.337-4.281l-1.916 1.814a3.582 3.582 0 0 1 1.773 3.547 3.352 3.352 0 0 1-1.517 2.692c-.085.057-.172.113-.259.169a4.769 4.769 0 0 0-.646.465 2.366 2.366 0 0 0-.6.726 1.087 1.087 0 0 0 .317 1.246 1.212 1.212 0 0 0 1.391.238 6.442 6.442 0 0 0 3.873-5.843l-.021-.238c-.018-.179-.033-.358-.058-.535z"
              className={styles.mutedIcon}
              data-name="Path 1320"
              transform="translate(489.461 -335.654)"
            />
            <path
              id="Path_1321"
              d="M301.258 744.759v-.3a27.62 27.62 0 0 1-.086-.587c-.063-.442-.122-.859-.222-1.267a13.611 13.611 0 0 0-3.428-6.355l-1.888 1.788a10.85 10.85 0 0 1 2.743 4.672l.021-.031a10.754 10.754 0 0 1 .221 4.355 11.9 11.9 0 0 1-.3 1.446 11.82 11.82 0 0 1-.217.7q-.108.31-.234.609c-.039.094-.079.189-.12.282a10.532 10.532 0 0 1-4.539 4.8l-.371.209c-.306.169-.6.33-.875.509a1.027 1.027 0 0 0-.5.744 1.419 1.419 0 0 0-.025.38 1.2 1.2 0 0 0 .576.973 1.386 1.386 0 0 0 1.29.056 12.507 12.507 0 0 0 3.721-2.44 13.457 13.457 0 0 0 3.943-7.057v-.007c0-.017.009-.034.013-.05.078-.381.137-.765.2-1.149.026-.17.052-.341.08-.511v-.15a6.642 6.642 0 0 0 0-1.621z"
              className={styles.mutedIcon}
              data-name="Path 1321"
              transform="translate(489.344 -335.358)"
            />
          </g>
        </svg>
      )}
    </div>
  );
};

export default VolumeToggle;
