export const CLOUDASR_SERVER_PATH_SET = 'speech/CLOUDASR_SERVER_PATH_SET';
export const CLOUDASR_SERVER_SESSION_ID_SET = 'speech/CLOUDASR_SERVER_SESSION_ID_SET';
export const CLOUDASR_CONNECTION_UPDATE = 'speech/CLOUDASR_CONNECTION_UPDATE';
export const CLOUDASR_SPEECH_READY = 'speech/CLOUDASR_SPEECH_READY';
export const CLOUDASR_BT_SPEECH_READY = 'speech/CLOUDASR_BT_SPEECH_READY';
export const CLOUDASR_LISTENING_UPDATE = 'speech/CLOUDASR_LISTENING_UPDATE';
export const CLOUDASR_TEXT_RESULTS = 'speech/CLOUDASR_TEXT_RESULTS';
export const CLOUDASR_SERVER_STATUS = 'speech/CLOUDASR_SERVER_STATUS';
export const CLOUDASR_RESULTS = 'speech/CLOUDASR_RESULTS';
export const CLOUDASR_OUTPUT_RESULTS = 'speech/CLOUDASR_OUTPUT_RESULTS';
export const CLOUDASR_BOOKMARK_POSITION = 'speech/CLOUDASR_BOOKMARK_POSITION';
export const CLOUDASR_MEDIA_STREAM_STATUS = 'speech/CLOUDASR_MEDIA_STREAM_STATUS';
export const CLOUDASR_PROCESSED_MEDIASCAPE = 'speech/CLOUDASR_PROCESSED_MEDIASCAPE';
export const CLOUDASR_MUTED_UPDATE = 'speech/CLOUDASR_MUTED_UPDATE';
export const CLOUDASR_BOOKMARK_TEXT_BLOCK_UPDATE = 'speech/CLOUDASR_BOOKMARK_TEXT_BLOCK_UPDATE';

export const RESETTING_SPEECH = 'speech/RESETTING_SPEECH';
