import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { VIDEO_EVENTS } from '../../constants';

class VideoPreloader extends Component {
  constructor(props) {
    // amount of video items to preload before start is pressed.
    super(props);
    this.state = {
      audioItems: [],
      currentVideoId: -1,
      readyForScreenText: false,
      endWord: -1,
      isPreloaded: false,
    };

    this.preloadRange = 2;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { videoCues } = this.props;
    // const newResource = Object.keys(videoResources).length !== Object.keys(nextProps.videoResources).length;
    const newCues = videoCues.length !== nextProps.videoCues.length;
    if (newCues) {
      return true;
    }

    return false;
  }

  render() {
    const { videoResources, videoCues, videoData } = this.props;
    if (videoResources) {
      const videoCount = Object.keys(videoResources).length;
      const usesVideo = videoCount > 0;
      const isInSynce = Object.keys(videoResources).length === videoCues.length;
      let videoItemsArr = [];
      let preloadVideoItemsArr = [];
      if (usesVideo && videoData) {
        console.log('preload', videoResources, videoCues, isInSynce);
        videoCues.forEach((cue, index) => {
          const videoItem = {
            id: index,
            ani_url: videoResources.cutscene[cue.animation].downloadPath,
            loop_url: cue.idle ? videoResources.idle[cue.idle].downloadPath : null,
            status: cue.status,
          };
          if (videoItem.status === VIDEO_EVENTS.PENDING) {
            videoItemsArr.push(videoItem);
          }
        });

        for (let index = 0; index < this.preloadRange; index++) {
          const currentItem = videoItemsArr.shift();
          if (currentItem) {
            preloadVideoItemsArr.push(currentItem);
          }
        }
      }

      if (usesVideo && !videoData) {
        Object.keys(videoResources).forEach(key => {
          videoResources[key].id = key;
          videoItemsArr.push(videoResources[key]);
        });

        for (let index = 0; index < this.preloadRange; index++) {
          const currentItem = videoItemsArr.shift();
          if (currentItem) {
            preloadVideoItemsArr.push(currentItem);
          }
        }
      }

      return (
        <div>
          {usesVideo && (
            <Helmet>
              {preloadVideoItemsArr.map(video => (
                <link key={video.id} rel="preload" href={video.ani_url} as="video" type="video/mp4" />
              ))}
            </Helmet>
          )}
          {usesVideo && (
            <Helmet>
              {preloadVideoItemsArr.map(video => (
                <link key={video.id} rel="preload" href={video.loop_url} as="video" type="video/mp4" />
              ))}
            </Helmet>
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => ({
  videoResources: state.resources.videoItems,
  videoCues: state.cueItems.videoItems,
});

export default connect(mapStateToProps)(VideoPreloader);
