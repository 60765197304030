import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bookmarkPosSelector } from '../../selectors/cue-selectors';

class AudioItemPlayer extends Component {
  static propTypes ={
    audioItem: PropTypes.shape({}).isRequired,
    bookmarkPos: PropTypes.string.isRequired,
    type: PropTypes.string
  }

  static defaultProps = {
    type: 'audio/mp3'
  }

  state = {
    shouldRemove: false
  }

  constructor(props) {
    super(props);

    this.audioPlayer = React.createRef();
  }

  componentDidMount() {
    this.audioPlayer.current.play();
  }

  componentDidUpdate(prepProps) {
    const {
      audioItem,
      bookmarkPos
    } = this.props;

    if (bookmarkPos !== prepProps.bookmarkPos) {
      if (audioItem.start > bookmarkPos || audioItem.stop < bookmarkPos) {
        this.setState({
          shouldRemove: true
        });
      }
    }
  }

  renderAudio = () => {
    const {
      audioItem,
      type
    } = this.props;

    return (
      <audio ref={this.audioPlayer}>
        <source
          src={audioItem.path}
          type={type}
        />
      </audio>
    );
  }

  render() {
    const {
      shouldRemove
    } = this.state;
    return shouldRemove ? null : this.renderAudio();
  }
}

const mapStateToProps = state => ({
  bookmarkPos: bookmarkPosSelector(state)
});

export default connect(mapStateToProps, null)(AudioItemPlayer);
