import React, { Component } from 'react';
import { Input } from '../../components';
import logo from '../../assets/images/logo_full.svg';
import styles from './MobilePair.module.scss';

class MobilePair extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pairingCode: '',
    };
  }

  handlePairCode(value) {
    this.setState({ pairingCode: value });
    if (!isNaN(value) && value.length === 5) {
      window.location.href = `/voice/${value}`;
    }
  }
  render() {
    return (
      <div className={styles.Pairing}>
        <div className={styles.NFXlogoContainer}>
          <img src={logo} className={styles.NFXlogo} alt="logo" />

          <div className={styles.inputContainer}>
            <div className={styles.helpText}>Pairing code:</div>
            <div className={styles.inputBox}>
              <Input
                id="pairingCode"
                name="pairingCode"
                type="tel"
                value={this.state.pairingCode}
                maxLength={5}
                onChange={code => this.handlePairCode(code.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MobilePair;
