import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { resetSpeech } from '../../redux/modules/speechReducer';
import { resetCues } from '../../redux/modules/cueReducer';
import { resetResources } from '../../redux/modules/resourcesReducer';
import { resetUI } from '../../redux/modules/uiReducer';
import { Carousel, Branding } from '../../components';
import StbCollectionTitle from './StbCollectionTitle';
import { STORAGE } from '../../constants/index';
import styles from './StbCollection.module.scss';

class Collection extends Component {
  constructor(props) {
    super(props);

    this.userStorage = window.localStorage;
    this.splashPlayed = JSON.parse(this.userStorage.getItem('nfxSession:splash'));

    const OneDay = new Date().getTime() - 1 * 24 * 60 * 60 * 1000;

    if (this.splashPlayed && OneDay > this.splashPlayed.update) {
      this.userStorage.removeItem('nfxSession:splash');
      this.splashPlayed = null;
    }

    this.audioPlayer = React.createRef();
    this.resourceList = React.createRef();
    this.contentDiv = React.createRef();
    this.splashPlayer = React.createRef();

    this.state = {
      itemWidth: 0,
      playedSplash: this.splashPlayed ? this.splashPlayed.played : false
    };

    this.collectionId = '-LeDZ0Kb5my2ihUJ8YVu';
  }

  componentDidMount() {
    const {
      actions
    } = this.props;

    if (
      this.VZUAL
      && this.VZUAL.VoiceClient
      && this.VZUAL.VoiceClient.stopNovelSession
    ) {
      this.VZUAL.VoiceClient.stopNovelSession();
    }

    if (this.splashPlayer.current) {
      this.splashPlayer.current.addEventListener('ended', this.onSplashComplete);
      this.splashPlayer.current.addEventListener('canplay', this.vidReadyHandler);
    }

    actions.resetSpeech();
    actions.resetResources();
    actions.resetCues();
    actions.resetUI();
  }

  componentWillUnmount() {
    if (this.splashPlayer.current) {
      this.splashPlayer.current.removeEventListener('ended', this.onSplashComplete);
      this.splashPlayer.current.removeEventListener('canplay', this.vidReadyHandler);
    }
  }

  handleItemSize(size) {
    const {
      itemWidth
    } = this.state;

    if (itemWidth === 0) {
      this.setState({
        itemWidth: size
      });
    }
  }

  handleBackground(imagePath) {
    // set background on Content
    this.contentDiv.current.style.backgroundImage = `url(${imagePath})`;
  }

  onSplashComplete = () => {
    const {
      playedSplash
    } = this.state;

    if (!playedSplash) {
      this.setState({ playedSplash: true });
      const splashObj = { played: true, update: Date.now() };
      this.userStorage.setItem('nfxSession:splash', JSON.stringify(splashObj));
    }
  }

  vidReadyHandler = () => {
    const player = this.splashPlayer.current;
    if (player) {
      const playPromise = player.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            // Autoplay started!
            player.isPlaying = true;
          })
          .catch(() => {
            this.setState({ playedSplash: true });
            const splashObj = { played: true, update: Date.now() };
            this.userStorage.setItem('nfxSession:splash', JSON.stringify(splashObj));
            // Autoplay was prevented.
            // Show a "Play" button so that user can start playback.
          });
      }
    }
  }

  render() {
    const { history } = this.props;
    const {
      playedSplash
    } = this.state;

    return (
      <div>
        {!playedSplash && <video className={styles.splashVideo} src={`${STORAGE.cfPath}wordmark.mp4`} autoPlay ref={this.splashPlayer} />}
        {
          playedSplash && (
            <div>
              <div className={styles.imageBackground} ref={this.contentDiv} />
              <div className={styles.Content}>
                <Branding />
                <div className={classNames(styles.helpText, styles.helpTextAdditional)}>
                  <StbCollectionTitle
                    helpText="Read the words on the screen out loud and watch the story come to life!"
                  />
                </div>
                <div>
                  <Carousel
                    history={history}
                    collectionId={this.collectionId}
                    onBackgroundSet={path => this.handleBackground(path)}
                    useFirstPlay={false}
                  />
                </div>
              </div>
            </div>
            )
          }
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => (
  {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          resetResources,
          resetCues,
          resetUI,
          resetSpeech
        }
      ),
      dispatch
    )
  }
);

export default connect(
  null,
  mapDispatchToProps,
)(Collection);
