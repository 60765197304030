import * as types from './actionTypes';

// Set or update the value of the device type
export const updateDevice = payload => (dispatch) => {
  dispatch({
    type: types.UPDATE_DEVICE,
    payload
  });
};

export const updateAppASR = payload => (dispatch) => {
  // eslint-disable-next-line no-console
  console.log('updateAppASR', payload);
  dispatch({
    type: types.UPDATE_APP_ASR,
    payload
  });
};
