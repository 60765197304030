import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import 'url-search-params-polyfill';
import { VideoController, ScreenText, BrowserSizeManager } from '../index';
import { updateAudioItem } from '../../redux/modules/resourcesReducer';
// import { STORAGE } from '../../constants/index';
import styles from './VideoManager.module.scss';

class VideoManager extends Component {
  constructor(props) {
    let urlParams = new URLSearchParams(window.location.search);
    let myParam = urlParams.get('media');

    // amount of video items to preload before start is pressed.
    super(props);
    this.state = {
      audioItems: [],
      currentVideoId: 0,
      readyForScreenText: false,
      endWord: -1,
      myParam: myParam,
      isPreloaded: false,
    };

    this.mediascapeItemsFromBookmark = this.mediascapeItemsFromBookmark.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { cueItems, shouldListen } = this.props;
    if (cueItems.bookmarkPos !== nextProps.cueItems.bookmarkPos && shouldListen) {
      this.mediascapeItemsFromBookmark(nextProps.cueItems);
    }
  }

  mediascapeItemsFromBookmark(cueItems) {
    let mediaItems = cueItems.mediaItems;
    let textRanges = cueItems.textRanges;
    let bookmarkPos = cueItems.bookmarkPos;

    mediaItems.forEach(item => {
      if (parseInt(item.start) <= bookmarkPos && parseInt(item.stop) > bookmarkPos) {
        let intId = parseInt(item.event_data, 10);
        this.setState({ currentVideoId: intId });
      }
    });

    textRanges.forEach(item => {
      if (parseInt(item.start) <= bookmarkPos && parseInt(item.stop) > bookmarkPos) {
        this.setState({ endWord: parseInt(item.stop) });
      }
    });
  }

  handleVideoEnd() {
    this.setState({ readyForScreenText: true });
    // console.log('readyForScreenText :', true);
  }

  handleVideoPlaying() {
    this.setState({ readyForScreenText: false });
    // console.log('readyForScreenText :', false);
  }

  handleSizeChange(size) {
    this.setState({ readyForScreenText: false });
  }

  render() {
    const { resources, shouldListen, corpus, cueItems } = this.props;

    const videoCount = Object.keys(resources.videoItems).length;
    const usesVideo = videoCount > 0;
    const usesImages = resources.imageItems.length > 0;
    const hasTextRanges = cueItems.textRanges.length > 0;

    return (
      <div className={styles.videoManager}>
        {usesVideo && shouldListen && (
          <div className={styles.videoMediaScape}>
            {hasTextRanges && (
              <ScreenText
                displayText={corpus}
                isVideoReady={this.state.readyForScreenText}
                endWord={this.state.endWord}
                resourceId={resources.resourceId}
                cueStatus={cueItems.status}
              />
            )}
            <VideoController
              videoId={this.state.currentVideoId}
              resourceId={resources.resourceId}
              onVideoEnd={() => this.handleVideoEnd()}
              onVideoStart={() => this.handleVideoPlaying()}
              onSizeChange={size => this.handleSizeChange(size)}
              mediaType={this.state.myParam}
              videos={resources.videoItems}
              isMuted={resources.isMuted}
            />
            <BrowserSizeManager />
          </div>
        )}
        {usesImages && <div>IMAGES GO HERE</div>}
        {false && (
          <div>
            <div>videoId={this.state.currentVideoId}</div>
            <div>resourceId={resources.resourceId}</div>
            <div>mediaType={this.state.myParam}</div>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateAudioItem,
    },
    dispatch,
  );

const mapStateToProps = state => ({
  resources: state.resources,
  cueItems: state.cueItems,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VideoManager);
