import { createSelector } from 'reselect';

export const deviceSelector = state => state.device;

export const currentDeviceSelector = createSelector(
  deviceSelector,
  device => device.currentDevice
);


export const usesPairingSelector = createSelector(
  deviceSelector,
  device => device.usesPairing
);

export const usesCloudASRSelector = createSelector(
  deviceSelector,
  device => device.usesCloudASR
);

export const usesAppASRSelector = createSelector(
  deviceSelector,
  device => device.usesAppASR
);

export const isVZSTBSelector = createSelector(
  deviceSelector,
  device => device.isVZSTB
);

export const isFiretvSelector = createSelector(
  deviceSelector,
  device => device.isFiretv
);
