import { createSelector } from 'reselect';

export const resourceSelector = state => state.resources;

export const asrWordsSelector = createSelector(
  resourceSelector,
  resources => resources.asrWords
);

export const resourcesAudioItemsSelector = createSelector(
  resourceSelector,
  resources => resources.audioItems
);

export const audioStatusSelector = createSelector(
  resourceSelector,
  resources => resources.audioStatus
);

export const bookIdSelector = createSelector(
  resourceSelector,
  resources => resources.bookId
);

export const codecSelector = createSelector(
  resourceSelector,
  resources => resources.codec
);

export const corpusSelector = createSelector(
  resourceSelector,
  resources => resources.corpus
);

export const descriptionSelector = createSelector(
  resourceSelector,
  resources => resources.description
);

export const displayTitleSelector = createSelector(
  resourceSelector,
  resources => resources.displayTitle
);

export const displayWordsSelector = createSelector(
  resourceSelector,
  resources => resources.displayWords
);

export const resourcesImageItemsSelector = createSelector(
  resourceSelector,
  resources => resources.imageItems
);

export const imageStatusSelector = createSelector(
  resourceSelector,
  resources => resources.imageStatus
);

export const isLoopingSelector = createSelector(
  resourceSelector,
  resources => resources.isLooping
);

export const isMutedSelector = createSelector(
  resourceSelector,
  resources => resources.isMuted
);

export const lastVideoSelector = createSelector(
  resourceSelector,
  resources => resources.lastVideo
);

export const mediaCompleteSelector = createSelector(
  resourceSelector,
  resources => resources.mediaComplete
);

export const resourceIdSelector = createSelector(
  resourceSelector,
  resources => resources.resourceId
);

export const resourceImagePathSelector = createSelector(
  resourceSelector,
  resources => resources.resourceImagePath
);

export const sessionSelector = createSelector(
  resourceSelector,
  resources => resources.session
);

export const statusSelector = createSelector(
  resourceSelector,
  resources => resources.status
);

export const resoucesVideoItemsSelector = createSelector(
  resourceSelector,
  resources => resources.videoItems
);

export const videoStatusSelector = createSelector(
  resourceSelector,
  resources => resources.videoStatus
);
