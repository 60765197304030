import React, { Component } from 'react';
import {
  compose,
  bindActionCreators
} from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  firebaseConnect,
  isLoaded
} from 'react-redux-firebase';
import {
  generatePairCode,
  saveUserId,
  fetchUserId,
  logStatus
} from '../../redux/modules/userReducer';
import { STATUS } from '../../constants/status';
import styles from './RandomCode.module.css';

class RandomCode extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      fetchUserId: PropTypes.func.isRequired,
      generatePairCode: PropTypes.func.isRequired,
      logStatus: PropTypes.func.isRequired,
      saveUserId: PropTypes.func.isRequired
    }).isRequired,
    pairCode: PropTypes.string.isRequired,
    pairItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    pair: PropTypes.shape({
      pairCode: PropTypes.string.isRequired
    }).isRequired,
    session: PropTypes.string.isRequired
  }

  componentDidMount() {
    const {
      actions,
      pairCode,
      pair,
      session
    } = this.props;

    actions.fetchUserId();

    if (!pair.pairCode) {
      if (isLoaded(pairCode)) {
        const payload = {
          pairCode,
          session
        };
        actions.generatePairCode(payload);
      }
    }
  }

  componentDidUpdate() {
    const {
      actions,
      pairItems,
      pair
    } = this.props;
    const currentPairStatus = pairItems[pair.pairCode] ? pairItems[pair.pairCode] : false;
    // if it doesn't match pending it should contain the current users ID
    if (currentPairStatus && currentPairStatus.userId !== 'pending') {
      const payload = {
        id: currentPairStatus.userId,
        pairCode: pair.pairCode
      };

      actions.saveUserId(payload);
      const status = STATUS.PAIRED;
      actions.logStatus(status);
    }
  }

  render() {
    const { pairCode, pair } = this.props;

    if (isLoaded(pairCode) && pair.pairCode) {
      return <div className={styles.Large}>{pair.pairCode}</div>;
    }
    return <div className={styles.Large}>. . . . .</div>;
  }
}

const mapDispatchToProps = dispatch => (
  {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          generatePairCode,
          saveUserId,
          fetchUserId,
          logStatus
        }
      ),
      dispatch
    )
  }
);


const mapStateToProps = state => ({
  pair: state.pair
});

const enhance = compose(
  firebaseConnect(props => [
    {
      path: 'webclient/pairCode',
      storeAs: 'pairCode'
    },
    {
      path: `webclient/session/${props.pair.userId}`,
      storeAs: 'session'
    }
  ]),
  connect(
    // Map redux state to component props
    ({ firebase: { ordered, data } }) => ({
      pairCode: ordered.pairCode,
      pairItems: data.pairCode,
      session: data.session
    }),
    mapDispatchToProps,
  ),
);

export default connect(mapStateToProps)(enhance(RandomCode));
