import React, { Component } from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect, isLoaded } from 'react-redux-firebase';
import { resetSpeech } from '../../redux/modules/speechReducer';
import { resetCues } from '../../redux/modules/cueReducer';
import { resetResources } from '../../redux/modules/resourcesReducer';
import { resetUI } from '../../redux/modules/uiReducer';
import { Loading, CollectionItem } from '../../components';
import styles from './Content.module.scss';

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentKey: null,
      currentIndex: 0,
      isActive: false,
      itemWidth: 0,
      scrollPos: 0,
    };
  }

  componentDidMount() {
    const { resetResources, resetCues, resetUI, resetSpeech } = this.props;

    // reset all the mediascape redux
    resetSpeech();
    resetResources();
    resetCues();
    resetUI();
  }

  render() {
    const { profile, collection, history } = this.props;
    let myShelf = [];
    let defaultCollection = [];

    if (isLoaded(collection)) {
      if (profile.packagedMedia) {
        Object.keys(profile.packagedMedia).forEach(key => {
          myShelf.push({ id: key });
        });
      }
      Object.keys(collection).forEach(key => {
        collection[key].key = key;
        defaultCollection.push(collection[key]);
      });

      return (
        <div className={styles.Content}>
          <h3>Work in progress</h3>
          <ul className={styles.resourceList}>
            {myShelf.map((title, index) => {
              return <CollectionItem key={index} id={title.id} index={index} history={history} isWip={true} />;
            })}
          </ul>
          <h3>Mediascape Collection</h3>
          <ul className={styles.resourceList} ref={this.resourceList}>
            {defaultCollection.map((item, index) => {
              return <CollectionItem key={index} id={item.id} index={index} history={history} collectionName="public" />;
            })}
          </ul>
        </div>
      );
    } else {
      return (
        <div className={styles.loadingWrap}>
          <Loading />
        </div>
      );
    }
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetResources,
      resetCues,
      resetUI,
      resetSpeech,
    },
    dispatch,
  );

const enhance = compose(
  firebaseConnect(props => [
    {
      path: `/collections/-LeOBsLCokhFXEpYKZZZ/items`,
      storeAs: 'mediaCollection',
    },
  ]),
  connect(
    // Map redux state to component props
    ({ firebase: { auth, profile, data } }) => ({
      collection: data.mediaCollection,

      auth,
      profile,
    }),
    mapDispatchToProps,
  ),
);

export default enhance(Content);
