import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateVideoSize } from '../../redux/modules/uiReducer';
import styles from './BrowserSizeManager.module.scss';

class BrowserSizeManager extends Component {
  constructor(props) {
    super(props);
    this.vcWapper = React.createRef();

    this.state = {
      width: 0,
      height: 0,
    };

    this.updateDimensions = this.updateDimensions.bind(this);
    this.findDisplaySize = this.findDisplaySize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);

    if (this.vcWapper.current) {
      this.findDisplaySize(this.vcWapper.current);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  findDisplaySize(element) {
    const { updateVideoSize } = this.props;
    const currentWidth = element.getBoundingClientRect().width;
    const currentHeight = element.getBoundingClientRect().height;

    let vidWidth = currentWidth;
    let vidHeight = currentHeight;

    if (currentHeight / currentWidth < 0.5625) {
      vidWidth = currentHeight * 1.77777;
      vidHeight = currentHeight;
    } else {
      vidWidth = currentWidth;
      vidHeight = currentWidth * 0.5625;
    }

    const browserResolution = { width: vidWidth, height: vidHeight };
    updateVideoSize(browserResolution);
    return browserResolution;
  }

  updateDimensions() {
    const { updateVideoSize } = this.props;

    let vidSize = this.findDisplaySize(this.vcWapper.current);
    if (this.state.width !== vidSize.width || this.state.height !== vidSize.height) {
      const newSize = { width: vidSize.width, height: vidSize.height };
      updateVideoSize(newSize);
    }
  }

  render() {
    return <div ref={this.vcWapper} className={styles.videoSizeEval} />;
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateVideoSize,
    },
    dispatch,
  );

const mapStateToProps = state => ({
  ui: state.ui,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BrowserSizeManager);
