import React, { Component } from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setActiveResource, hydrateResourceDataFromFirebase, hydrateCorpusTextFromFirebase } from '../../redux/modules/resourcesReducer';

class TitleID extends Component {
  componentDidMount() {
    const {
      images,
      resourceId,
      resources,
      setActiveResource,
      title,
      resourceDescription,
      match,
      hydrateResourceDataFromFirebase,
      hydrateCorpusTextFromFirebase,
    } = this.props;

    if (resources.resourceId !== resourceId) {
      let resourceObj = {
        id: resourceId,
        title: title,
        resourceImagePath: images,
        description: resourceDescription,
      };

      hydrateResourceDataFromFirebase(match.params, []);
      hydrateCorpusTextFromFirebase(match.params, []);
      setActiveResource(resourceObj);

      // fetchImageList(resourceId);
    }
  }

  componentDidUpdate() {
    const { match, mediaItems, resources, hydrateResourceDataFromFirebase, hydrateCorpusTextFromFirebase } = this.props;

    if (!resources.mediaComplete && mediaItems.length > 0) {
      hydrateResourceDataFromFirebase(match.params, mediaItems);
      hydrateCorpusTextFromFirebase(match.params, []);
    }
  }

  render() {
    return <div />;
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setActiveResource,
      hydrateResourceDataFromFirebase,
      hydrateCorpusTextFromFirebase,
    },
    dispatch,
  );

const mapStateToProps = state => ({
  resources: state.resources,
  mediaItems: state.cueItems.mediaItems,
  device: state.device,
});

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(TitleID);
