export const BOOKID_SET = 'resources/BOOKID_SET';
export const BOOKID_ERROR = 'resources/BOOKID_ERROR';

export const RESOURCE_ACTIVE_SET = 'resources/RESOURCE_ACTIVE_SET';
export const RESOURCE_ACTIVE_ERROR = 'resources/RESOURCE_ACTIVE_ERROR';

export const LOOPING_SET = 'resources/LOOPING_SET';
export const LOOPING_ERROR = 'resources/LOOPING_ERROR';

export const AUDIO_LIST_FETCH = 'resources/AUDIO_LIST_FETCH';
export const AUDIO_LIST_SET = 'resources/AUDIO_LIST_SET';
export const AUDIO_LIST_ERROR = 'resources/AUDIO_LIST_ERROR';

export const AUDIO_SET_MUTE = 'resources/AUDIO_SET_MUTE';

export const AUDIO_ITEM_UPDATING = 'resources/AUDIO_ITEM_UPDATING';
export const AUDIO_ITEM_UPDATED = 'resources/AUDIO_ITEM_UPDATED';

export const AUDIO_ITEMS_UPDATING = 'resources/AUDIO_ITEMS_UPDATING';
export const AUDIO_ITEMS_UPDATED = 'resources/AUDIO_ITEMS_UPDATED';

export const VIDEO_LIST_FETCH = 'resources/VIDEO_LIST_FETCH';
export const VIDEO_LIST_SET = 'resources/VIDEO_LIST_SET';
export const VIDEO_LIST_ERROR = 'resources/VIDEO_LIST_ERROR';
export const VIDEO_LASTVIDEO_SET = 'resources/VIDEO_LASTVIDEO_SET';
export const VIDEO_CODEC_SUPPORT = 'resources/VIDEO_CODEC_SUPPORT';
export const VIDEO_CACHE_START = 'resources/VIDEO_CACHE_START';

export const VIDEO_DOWNLOAD_ANI_START = 'resources/VIDEO_DOWNLOAD_ANI_START';
export const VIDEO_DOWNLOAD_ANI_COMPLETED = 'resources/VIDEO_DOWNLOAD_ANI_COMPLETED';

export const VIDEO_DOWNLOAD_LOOP_START = 'resources/VIDEO_DOWNLOAD_LOOP_START';
export const VIDEO_DOWNLOAD_LOOP_COMPLETED = 'resources/VIDEO_DOWNLOAD_LOOP_COMPLETED';

export const VIDEO_DOWNLOAD_START = 'resources/VIDEO_DOWNLOAD_START';
export const VIDEO_DOWNLOAD_COMPLETED = 'resources/VIDEO_DOWNLOAD_COMPLETED';

export const IMAGE_LIST_FETCH = 'resources/IMAGE_LIST_FETCH';
export const IMAGE_LIST_SET = 'resources/IMAGE_LIST_SET';
export const IMAGE_LIST_ERROR = 'resources/IMAGE_LIST_ERROR';

export const TEST_EVENT_UPDATE = 'resources/TEST_EVENT_UPDATE';
export const TEST_EVENT_COMPLETE = 'resources/TEST_EVENT_COMPLETE';
export const TEST_EVENT_ERROR = 'resources/TEST_EVENT_ERROR';

export const SESSION_STATUS_UPDATE = 'resources/SESSION_STATUS_UPDATE';
export const SESSION_STATUS_COMPLETE = 'resources/SESSION_STATUS_COMPLETE';
export const SESSION_STATUS_ERROR = 'resources/SESSION_STATUS_ERROR';

export const RESETTING_RESOURCES = 'resources/RESETTING_RESOURCES';

export const FETCH_RESOURCEITEMS_FROM_STORAGE = 'cue/FETCH_RESOURCEITEMS_FROM_STORAGE';
export const STORE_RESOURCEITEMS_FROM_STORAGE = 'cue/STORE_RESOURCEITEMS_FROM_STORAGE';

export const FETCH_RESOURCEITEMS_FROM_FIREBASE = 'cue/FETCH_RESOURCEITEMS_FROM_FIREBASE';
export const STORE_RESOURCEITEMS_FROM_FIREBASE = 'cue/STORE_RESOURCEITEMS_FROM_FIREBASE';

export const FETCH_CORPUS_FROM_FIREBASE = 'cue/FETCH_CORPUS_FROM_FIREBASE';
export const STORE_CORPUS_FROM_FIREBASE = 'cue/STORE_CORPUS_FROM_FIREBASE';
