import React, { Component } from 'react';
import classNames from 'classnames';
import { Rating } from '../index';
// import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import './customProgressbar.scss';

import styles from './EndEvent.module.scss';
import logo from '../../assets/images/logo_full.svg';

class EndEvent extends Component {
  constructor(props) {
    super(props);

    this.timeoutTime = 15;

    this.state = {
      currentButton: 0,
      seconds: this.timeoutTime,
      isRated: false,
    };

    this.maxSize = 2;
    this.intervalHandle = null;
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKey);
    this.startCountDown();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKey);
  }

  tick = () => {
    const sec = this.state.seconds - 0.1;
    if (sec > 0) {
      this.setState({
        seconds: sec,
      });
    }

    if (sec <= 0) {
      clearInterval(this.intervalHandle);
    }
  };

  startCountDown = () => {
    this.intervalHandle = setInterval(this.tick, 100);
  };

  handleRated(value) {
    if (!this.state.isRated) {
      this.setState({ isRated: true });
    }
  }

  handleKey = e => {
    let selectedItem = 0;

    switch (e.key) {
      case 'ArrowDown':
        if (this.state.currentButton < this.maxSize) {
          selectedItem = this.state.currentButton + 1;
        } else {
          selectedItem = 0;
        }
        this.setState({ currentButton: selectedItem });
        break;
      case 'ArrowUp':
        if (this.state.currentButton > 0) {
          selectedItem = this.state.currentButton - 1;
        } else {
          selectedItem = this.maxSize;
        }
        this.setState({ currentButton: selectedItem });
        break;
      case 'Enter':
        if (this.state.currentButton === 1) {
          // this.reloadScape();
        }

        if (this.state.currentButton === 2) {
          // TODO: play next mediascape
        }

        break;
      case 'Delete':
        this.backToHome();
        break;
      default:
        break;
    }
  };

  backToHome = () => {
    this.props.history.push('/');
  };

  reloadScape = () => {
    const currentUri = window.location.href;
    const myPath = process.env.PUBLIC_URL;
    window.location.href = `${myPath}/`;
    window.location.href = currentUri;
  };

  // TODO: holding off on auto progress to next item until we actally solve where the next item is coming from
  // be it from the active collection that was used to launch the title or some sibling to the playing item defined
  // in the studio
  render() {
    const { userId, resourceId } = this.props;
    // const percentage = (this.state.seconds / this.timeoutTime) * 100;

    const ratingActive = this.state.currentButton === 0;

    return (
      <div className={styles.endEvent}>
        <div className={styles.logo}>
          <img className={styles.Img} src={logo} alt="Novel Effect" />
        </div>
        <Rating userId={userId} resourceId={resourceId} isActive={ratingActive} onRated={() => this.handleRated()} />

        <div className={styles.thanksText}>Thanks for reading along!</div>
        <div
          className={classNames(styles.completedButton, {
            [styles.active]: this.state.currentButton === 1,
          })}
          onClick={() => this.reloadScape()}
        >
          Play Again
        </div>
        {/* <div className={styles.countdown}>
          <CircularProgressbar value={percentage} />
        </div>
        <div
          className={classNames(styles.completedButton, {
            [styles.active]: this.state.currentButton === 2,
          })}
          onClick={() => this.reloadScape()}
        >
          Play Next
        </div> */}
      </div>
    );
  }
}

export default EndEvent;
