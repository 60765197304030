import { createSelector } from 'reselect';
import { collectionSelector } from './firebase-selectors';

export const uiSelector = state => state.ui;

export const drawerItemSelector = createSelector(
  uiSelector,
  ui => ui.drawerItem
);

export const inputDeviceIdSelector = createSelector(
  uiSelector,
  ui => ui.inputDeviceId
);

export const inputDeviceStatusSelector = createSelector(
  uiSelector,
  ui => ui.inputDeviceStatus
);

export const isDebugModeSelector = createSelector(
  uiSelector,
  ui => ui.isDebugMode
);

export const isFullScreenSelector = createSelector(
  uiSelector,
  ui => ui.isFullScreen
);

export const isMicrophoneActiveSelector = createSelector(
  uiSelector,
  ui => ui.isMicrophoneActive
);

export const isVerizonBTSelector = createSelector(
  uiSelector,
  ui => ui.isVerizonBT
);

export const isPausedSelector = createSelector(
  uiSelector,
  ui => ui.isPaused
);

export const isShelfActiveSelector = createSelector(
  uiSelector,
  ui => ui.isShelfActive
);

export const isSkippingBackSelector = createSelector(
  uiSelector,
  ui => ui.isSkippingBack
);

export const notificationsSelector = createSelector(
  uiSelector,
  ui => ui.notifications
);

export const outputDeviceIdSelector = createSelector(
  uiSelector,
  ui => ui.outputDeviceId
);

export const videoSizeSelector = createSelector(
  uiSelector,
  ui => ui.videoSize
);

export const volumeLevelSelector = createSelector(
  uiSelector,
  ui => ui.volumeLevel
);

export const imagesLoadedSelector = createSelector(
  uiSelector,
  ui => ui.imagesLoaded
);

export const areImagesLoaded = createSelector(
  collectionSelector,
  imagesLoadedSelector,
  (collection, imagesLoaded) => collection && collection.length === imagesLoaded
);
