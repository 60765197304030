import React from 'react';
import classnames from 'classnames';
import styles from './Button.module.scss';

type Props = {
  children?: React$Element<any> | Array<React$Element<any>>,
  className?: string,
  disabled?: boolean,
  raised?: boolean,
  href?: string,
  inverse?: boolean,
  wide?: boolean,
  onClick?: Function,
  color?: string,
  size?: 'small' | 'normal' | 'large',
  theme?: 'primary' | 'success' | 'warning' | 'danger' | 'dark' | 'link',
  type?: string,
};
const element = 'button';

const Button = ({
  children,
  className,
  disabled = false,
  raised,
  inverse = false,
  wide = false,
  size = 'normal',
  theme = 'primary',
  type = 'button',
  text,
  color,
  ...others
}: Props) => {
  const componentProps = {
    ...others,
    className: classnames(
      styles.button,
      styles[`theme--${theme}`],
      styles[`size--${size}`],
      raised && styles.raised,
      inverse && styles.inverse,
      wide && styles.wide,
      className,
    ),
    style: { backgroundColor: color },
    text,
    disabled,
  };

  const childComponent = children && <span className={styles.children}>{children}</span>;
  return React.createElement(element, componentProps, childComponent);
};

export default Button;
