import React, { Component } from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { updateDevice } from '../../redux/modules/deviceReducer';


class DeviceDetect extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      updateDevice: PropTypes.func
    }).isRequired,
    auth: PropTypes.shape({
      isLoaded: PropTypes.bool,
      isEmpty: PropTypes.bool
    }).isRequired,
    firebase: PropTypes.shape({
      logout: PropTypes.func
    }).isRequired
  }

  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(window.location.search);
    this.enableFakeSTB = urlParams.get('stb') === 'true';
    this.disableFakeSTB = urlParams.get('stb') === 'false';

    this.showPairing = false;
    this.device = 'web';
    this.cloudASR = false;

    this.connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    this.connectionType = this.connection ? this.connection.effectiveType : 'unknown';
    this.downLink = this.connection ? this.connection.downlink : 'unknown';

    this.memory = navigator.deviceMemory;

    // eslint-disable-next-line no-console
    console.log('****************', navigator.userAgent);
    if (window.location.hostname === 'verizon.noveleffect.com' ||
      window.location.hostname === 'firetv.noveleffect.com' ||
      window.location.hostname === 'firetv-gapless.noveleffect.com' ||
      window.location.hostname === 'zhi.noveleffect.com' ||
      window.location.hostname === 'zhi-gapless.noveleffect.com' ||
      window.location.hostname === 'sky.noveleffect.com' ||
      window.location.hostname === 'sky-gapless.noveleffect.com') {
      this.device = 'web based ipc4100 (STB)';
      this.showPairing = false;
    }
    const validBox = navigator.userAgent.toLowerCase().includes('vms4100') || navigator.userAgent.toLowerCase().includes('ipc4100');

    // eslint-disable-next-line no-console
    console.log('****************', validBox);
    if (validBox) {
      // eslint-disable-next-line no-console
      console.log('Device Detect: **** Set Top Box ****');
      let deviceLabel = 'vms4100';
      if (navigator.userAgent.toLowerCase().includes('ipc4100')) deviceLabel = 'ipc4100';
      this.device = `${deviceLabel} (STB)`;
      this.showPairing = true;
    }

    // eslint-disable-next-line max-len
    // if (navigator.platform.toLowerCase() === 'macintel' && navigator.userAgent.toLowerCase().includes('firefox')) {
    //   console.log('**** Virtual Verizon Set Top Box ****');
    //   this.device = 'vms4100 (STB)';
    //   this.showPairing = true;
    // }
    if (this.disableFakeSTB) {
      this.saveUserStorage(false);
    }

    this.currentFakeSTB = this.loadStorage();
    if (this.enableFakeSTB || this.currentFakeSTB) {
      this.setupFakeSTB();
    }
  }

  componentDidMount() {
    const { actions } = this.props;

    if (this.connection) {
      this.connection.addEventListener('change', this.updateConnectionStatus);
      // eslint-disable-next-line no-console
      console.log(`Connection type ${this.connectionType}`, this.connection);
      // eslint-disable-next-line no-console
      console.log(`effective bandwidth estimate ${this.downLink}Mbps`);
    }

    // eslint-disable-next-line no-console
    console.log(`This device has at least ${this.memory}GiB of RAM.`);

    /*
    currentDevice: action.payload.device,
    usesPairing: action.payload.usesPairing,
    usesCloudASR: action.payload.usesCloudASR,
    */
    const devicePackage = {
      device: this.device,
      usesPairing: this.showPairing,
      usesCloudASR: this.cloudASR,
      isFiretv: window.location.hostname === 'firetv.noveleffect.com' ||
        window.location.hostname === 'zhi.noveleffect.com' ||
        window.location.hostname === 'firetv-gapless.noveleffect.com' ||
        window.location.hostname === 'zhi-gapless.noveleffect.com' ||
        window.location.hostname === 'sky.noveleffect.com' ||
        window.location.hostname === 'sky-gapless.noveleffect.com'
    };

    actions.updateDevice(devicePackage);
  }

  componentDidUpdate() {
    const { auth, firebase } = this.props;

    if (auth.isLoaded && !auth.isEmpty && this.isSTB) {
      firebase.logout();
    }
  }

  setupFakeSTB() {
    this.device = 'fake set top box vms4100 (STB)';
    this.showPairing = true;
    this.cloudASR = false;
    this.saveUserStorage(true);
  }

  loadStorage = () => {
    const userStorage = window.localStorage;
    const localState = JSON.parse(userStorage.getItem('nfxSession:fakeSTB'));
    return localState;
  }

  saveUserStorage = (value) => {
    const userStorage = window.localStorage;
    const storeItem = JSON.stringify(value);
    userStorage.setItem('nfxSession:fakeSTB', storeItem);
    return storeItem;
  }

  updateConnectionStatus() {
    this.connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    // if (this.connection) {
    // eslint-disable-next-line no-console
    console.log(`Connection type changed from ${this.connectionType} to ${this.connection.effectiveType}`);
    // }
  }

  render() {
    return <div />;
  }
}

const mapDispatchToProps = dispatch => (
  {
    actions: bindActionCreators(
      {
        updateDevice
      },
      dispatch,
    )
  }

);
const enhance = compose(
  connect(
    // Map redux state to component props
    ({ firebase: { auth }, ui }) => ({
      auth,
      ui
    }),
    mapDispatchToProps,
  ),
);

export default enhance(DeviceDetect);
