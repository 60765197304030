import { MetronomeWorker } from './metronomeworker';
import WebWorker from './workerSetup';

const useSessionStorage = () => {
  try {
    var mod = new Date().valueOf() + '';
    localStorage.setItem(mod, mod);
    localStorage.removeItem(mod);
    return true;
  } catch (e) {
    console.log('No sessionStorage');
    return false;
  }
};

export class Buffer {
  constructor() {
    this.chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    this.lookup = new Uint8Array(256);

    for (let i = 0; i < this.chars.length; i++) {
      this.lookup[this.chars.charCodeAt(i)] = i;
    }
  }

  encode(arraybuffer) {
    const bytes = new Uint8Array(arraybuffer);
    const len = bytes.length;
    let base64 = '';

    for (let i = 0; i < len; i += 3) {
      base64 += this.chars[bytes[i] >> 2];
      base64 += this.chars[((bytes[i] & 3) << 4) | (bytes[i + 1] >> 4)];
      base64 += this.chars[((bytes[i + 1] & 15) << 2) | (bytes[i + 2] >> 6)];
      base64 += this.chars[bytes[i + 2] & 63];
    }

    if (len % 3 === 2) {
      base64 = base64.substring(0, base64.length - 1) + '=';
    } else if (len % 3 === 1) {
      base64 = base64.substring(0, base64.length - 2) + '==';
    }

    return base64;
  }

  decode(base64) {
    const len = base64.length;
    let bufferLength = base64.length * 0.75;
    let p = 0;
    let encoded1, encoded2, encoded3, encoded4;

    if (base64[base64.length - 1] === '=') {
      bufferLength--;
      if (base64[base64.length - 2] === '=') {
        bufferLength--;
      }
    }

    const arraybuffer = new ArrayBuffer(bufferLength);
    const bytes = new Uint8Array(arraybuffer);

    for (let i = 0; i < len; i += 4) {
      encoded1 = this.lookup[base64.charCodeAt(i)];
      encoded2 = this.lookup[base64.charCodeAt(i + 1)];
      encoded3 = this.lookup[base64.charCodeAt(i + 2)];
      encoded4 = this.lookup[base64.charCodeAt(i + 3)];

      bytes[p++] = (encoded1 << 2) | (encoded2 >> 4);
      bytes[p++] = ((encoded2 & 15) << 4) | (encoded3 >> 2);
      bytes[p++] = ((encoded3 & 3) << 6) | (encoded4 & 63);
    }

    return arraybuffer;
  }
}

export class AudioObj {
  constructor(track) {
    this.track = track;
    if (!window.AudioContext) {
      if (!window.webkitAudioContext) {
        console.log('Web Audio API is not supported');
        return;
      }
      window.AudioContext = window.webkitAudioContext;
    }
    this.audioContext = new AudioContext();
    this.TO = {};
    this.buffer = new Buffer();
    this.unlocked = false;
    this.isPlaying = false; // Are we currently playing?
    this.current16thNote = 0; // What note is currently last scheduled?
    this.tempo = 120.0; // tempo (in beats per minute)
    this.lookahead = 25.0; // How frequently to call scheduling function
    //(in milliseconds)
    this.scheduleAheadTime = 0.1; // How far ahead to schedule audio (sec)
    // This is calculated from lookahead, and overlaps
    // with next interval (in case the timer is late)
    this.nextNoteTime = 0.0; // when the next note is due.
    this.noteResolution = 0; // 0 == 16th, 1 == 8th, 2 == quarter note
    this.noteLength = 0.05; // length of "beep" (in seconds)

    this.last16thNoteDrawn = -1; // the last "box" we drew on the screen
    this.notesInQueue = []; // the notes that have been put into the web audio,
    // and may or may not have played yet. {note, time}
    this.timerWorker = new WebWorker(MetronomeWorker); // The Web Worker used to fire timer messages

    this.timerWorker.onmessage = function(e) {
      if (e.data === 'tick') {
        // console.log("tick!");
        this.scheduler();
      } else console.log('message: ' + e.data);
    };

    this.timerWorker.postMessage({ interval: this.lookahead });
  }

  nextNote() {
    // Advance current note and time by a 16th note...
    var secondsPerBeat = 60.0 / this.tempo; // Notice this picks up the CURRENT
    // tempo value to calculate beat length.
    this.nextNoteTime += 0.25 * secondsPerBeat; // Add beat length to last beat time

    this.current16thNote++; // Advance the beat number, wrap to zero
    if (this.current16thNote === 16) {
      this.current16thNote = 0;
    }
  }

  scheduleNote(beatNumber, time) {
    // push the note on the queue, even if we're not playing.
    this.notesInQueue.push({ note: beatNumber, time: time });

    if (this.noteResolution === 1 && beatNumber % 2) return; // we're not playing non-8th 16th notes
    if (this.noteResolution === 2 && beatNumber % 4) return; // we're not playing non-quarter 8th notes

    // create an oscillator
    var osc = this.audioContext.createOscillator();
    osc.connect(this.audioContext.destination);
    if (beatNumber % 16 === 0)
      // beat 0 == high pitch
      osc.frequency.value = 880.0;
    else if (beatNumber % 4 === 0)
      // quarter notes = medium pitch
      osc.frequency.value = 440.0;
    // other 16th notes = low pitch
    else osc.frequency.value = 220.0;

    osc.start(time);
    osc.stop(time + this.noteLength);
  }

  scheduler() {
    // while there are notes that will need to play before the next interval,
    // schedule them and advance the pointer.
    while (this.nextNoteTime < this.audioContext.currentTime + this.scheduleAheadTime) {
      this.scheduleNote(this.current16thNote, this.nextNoteTime);
      this.nextNote();
    }
  }

  buildTrack(track) {
    track.audioSource = this.audioContext.createBufferSource();
    track.audioSource.ended = function() {
      // stopAudio(track);
      console.log('Ended: ', track.path);
    };
    track.gainNode = this.audioContext.createGain();
    track.audioSource.connect(track.gainNode);
    track.gainNode.connect(this.audioContext.destination);
    track.canFade = true; // used to prevent fadeOut firing twice
  }

  stopAudio(track) {
    if (track.audioSource) {
      clearTimeout(this.TO);

      // Using disconnect() as replacement for stop() to try to support Safari
      // https://stackoverflow.com/questions/32563298/audiocontext-issue-with-safari
      // track.audioSource.stop();
      track.audioSource.disconnect();
    }
    sessionStorage.removeItem(track.path);
  }

  stopAllAudio() {
    this.audioContext.close().then(() => {
      console.log('audio context closed');
    });
  }

  fadeOutAudio(track) {
    if (track.canFade && track.fadeOut && track.fadeOut > 0) {
      track.isFading = true;
      track.gainNode.gain.setValueAtTime(track.volume, this.audioContext.currentTime);
      track.gainNode.gain.linearRampToValueAtTime(0, this.audioContext.currentTime + track.fadeOut);

      track.canFade = false;

      this.TO = setTimeout(() => {
        this.stopAudio(track);
      }, track.fadeOut * 1000);
    } else if (track.canFade) {
      this.stopAudio(track);
    } else {
      sessionStorage.removeItem(track.path);
    }
  }

  fetchAudioResource(track) {
    return new Promise((resolve, reject) => {
      fetch(track.downloadPath)
        .then(response => response.arrayBuffer())
        .then(buffer => {
          resolve(buffer);
        })
        .catch(err => console.log('Fetch ' + err, track.path));
    });
  }

  fetchAudio(track) {
    fetch(track.path)
      .then(response => response.arrayBuffer())
      .then(buffer => {
        if (useSessionStorage) {
          // Convert to Base64 and stuff into sessionStorage for reuse.
          // No check made for available space! Keep samples small, or do a check.

          sessionStorage.setItem(track.path, this.buffer.encode(buffer));
          // console.log('Fetched & stored in sessionStorage: ', track.path);
        }

        return buffer;
      })
      .then(buffer => this.populateAudioContext(track, buffer))
      .catch(err => console.log('Fetch ' + err, track.path));
  }

  getAudio(track) {
    if (useSessionStorage) {
      const b64 = sessionStorage.getItem(track.path);
      if (b64 !== null) {
        const buffer = this.buffer.decode(b64);
        // console.log('From sessionStorage: ', track.path);
        this.populateAudioContext(track, buffer);
        return;
      }
    }

    this.fetchAudio(track);
  }

  preLoadAllAudio(audioResources) {
    return new Promise((resolve, reject) => {
      let updatedResources = Object.keys(audioResources).map(key => {
        return this.fetchAudioResource(audioResources[key]).then(buffer => {
          audioResources[key].buffer = buffer;
          //console.log('audioResources[key] :', audioResources[key]);
          return audioResources[key];
        });
      });
      resolve(updatedResources);
    });
  }

  populateAudioContext(track, audioData) {
    if (track.audioSource) {
      this.audioContext.decodeAudioData(audioData).then(decodedData => {
        if (decodedData) {
          track.audioSource.buffer = decodedData;
        }
      });
    }
  }

  setGain(track) {
    track.volume = track.volume >= 0 ? track.volume : 0.5;
    if (track.gainNode) {
      if (track.fadeIn && track.fadeIn > 0) {
        track.gainNode.gain.setValueAtTime(0, this.audioContext.currentTime);
        track.gainNode.gain.linearRampToValueAtTime(track.volume, this.audioContext.currentTime + track.fadeIn);
      } else {
        track.gainNode.gain.value = track.volume;
      }
    }
  }

  setVolume(track, volumeLevel) {
    if (track.gainNode) {
      track.gainNode.gain.value = volumeLevel;
    }
  }

  playTrack(track) {
    var d = Date.now();

    console.log('play :', track, d);
    this.stopAudio(track);
    this.buildTrack(track);
    this.getAudio(track);
    this.setGain(track);
    track.audioSource.loop = (track.loop && track.loop === true) || false;
    track.audioSource.start(); // Possibly add sprite positions?
    // console.log("playing: ", track.audioSource);
  }

  // TODO: get an actual immutable object to not wipe out the pre-loaded buffer
  playPreBufferedTrack(track, buffer) {
    this.stopAudio(track);
    this.buildTrack(track);
    if (buffer.byteLength === 0) {
      this.getAudio(track);
    } else {
      this.populateAudioContext(track, buffer);
    }
    this.setGain(track);
    track.audioSource.loop = (track.isLoop && track.isLoop === true) || false;
    track.audioSource.start(); // Possibly add sprite positions?
  }

  playAudio() {
    this.stopAudio(this.track);
    this.buildTrack(this.track);
    this.getAudio(this.track);
    this.setGain(this.track);
    this.track.audioSource.loop = (this.track.isLoop && this.track.isLoop === true) || false;
    this.track.audioSource.start(); // Possibly add sprite positions?
  }
}
