const initialState = {
  currentDevice: null,
  usesPairing: false,
  usesCloudASR: false,
  usesAppASR: false,
  isVZSTB: false,
  isFiretv: false
};

export default initialState;
