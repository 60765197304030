// Event codes
export default class EventCodes {
  static get MSG_WAITING_MICROPHONE() {
    return 1;
  }

  static get MSG_MEDIA_STREAM_CREATED() {
    return 2;
  }

  static get MSG_INIT_RECORDER() {
    return 3;
  }

  static get MSG_SEND() {
    return 5;
  }

  static get MSG_SEND_EMPTY() {
    return 6;
  }

  static get MSG_SEND_EOS() {
    return 7;
  }

  static get MSG_WEB_SOCKET() {
    return 8;
  }

  static get MSG_WEB_SOCKET_OPEN() {
    return 9;
  }

  static get MSG_WEB_SOCKET_CLOSE() {
    return 10;
  }

  static get MSG_STOP() {
    return 11;
  }

  static get MSG_SERVER_CHANGED() {
    return 12;
  }
}
