import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import classNames from 'classnames';
import styles from './Menu.module.scss';

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuExpanded: false,
    };
  }

  handleLogout() {
    // logout and remove profile and auth from state
    this.props.firebase.logout();
  }

  handleLogin() {
    this.props.history.push('/login');
  }

  handleHome() {
    this.props.history.push('/');
  }

  render() {
    const { isHidden = false, auth } = this.props;

    if (!isHidden) {
      return (
        <div
          className={styles.menuContainer}
          onMouseEnter={() => this.setState({ isMenuExpanded: true })}
          onMouseLeave={() => this.setState({ isMenuExpanded: false })}
          onClick={() => this.setState({ isMenuExpanded: !this.state.isMenuExpanded })}
        >
          <div
            className={classNames(styles.burgerContainer, {
              [styles.change]: this.state.isMenuExpanded,
            })}
          >
            <div className={styles.bar1} />
            <div className={styles.bar2} />
            <div className={styles.bar3} />
          </div>
          {this.state.isMenuExpanded && (
            <div className={styles.menuItems}>
              <ul>
                <li onClick={() => this.handleHome()}>home</li>
                {!auth.isAnonymous && <li onClick={() => this.handleLogout()}>log out</li>}
                {auth.isAnonymous && <li onClick={() => this.handleLogin()}>log in</li>}
              </ul>
            </div>
          )}
        </div>
      );
    }

    return null;
  }
}

const enhance = compose(
  firebaseConnect(props => []),
  connect(
    // Map redux state to component props
    ({ firebase: { auth } }) => ({
      auth,
    }),
    null,
  ),
);

export default enhance(Menu);
