import React, { Component } from 'react';
import { connect } from 'react-redux';
import { VideoPlayerData, StbVideoPlayer } from '../index';
import { STORAGE } from '../../constants/index';
import styles from './VideoController.module.scss';

class VideoController extends Component {
  constructor(props) {
    super(props);
    this.vcWapper = React.createRef();
    this.state = {
      isPlaying: false,
      isLooping: false,
      duration: '00:00',
      disabled: true,
      currentVideoId: props.videoId,
      videoItems: [],
      videoSize: props.device.usesPairing ? 2 : 4,
    };
  }

  componentDidMount() {
    const { videos, videoCues } = this.props;
    let logoVideoPath = STORAGE.cfPath + 'wordmark.mp4';
    let vidArr = [];
    let splash = {
      path: logoVideoPath,
      name: 'splash',
    };
    if (videoCues.length > 0) {
      videoCues.forEach((cue, index) => {
        let videoObj = {
          id: index,
          anim: { path: videos.cutscene[cue.animation].downloadPath, name: cue.animation },
          idle: { path: cue.idle ? videos.idle[cue.idle].downloadPath : null, name: cue.idle },
          splash: index === 0 ? splash : null,
        };
        vidArr.push(videoObj);
      });
    }

    this.setState({
      videoItems: vidArr,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { videoId, videos, videoCues, resourceId } = this.props;

    if (videoId !== nextProps.videoId || videoId === -1) {
      return true;
    }

    if (videoCues.length !== nextProps.videoCues.length) {
      return true;
    }

    if (Object.keys(videos).length !== Object.keys(nextProps.videos).length) {
      return true;
    }

    if (resourceId !== nextProps.resourceId) {
      return true;
    }

    if (videoId === 0 && nextProps.videoId === 0) {
      return true;
    }

    return false;
  }

  render() {
    const { videoId, onVideoEnd, onVideoStart, ui, device, resources, isFinalVideo, onFinalVideo, history } = this.props;

    // const currentAnimId = this.state.currentVideoId ? this.state.currentVideoId : videoId;
    //const currentAnimId = videoId;

    const currentVideoKey = videoId;

    let activeVideos = [];

    if (this.state.videoItems.length > 0) {
      // activeVideos = this.state.videoItems;

      // we want to created a limited set of active videos to render to help with performance
      // can't just use the videoId because on startup it can have the value of -1 and that wouldn't work
      // so we check for that and re-assign to zero if needed
      const startingIndexValue = videoId !== -1 ? videoId : 0;

      let futureVideoAmount = this.state.videoItems.length;
      if (this.state.videoSize < this.state.videoItems.length) {
        futureVideoAmount = this.state.videoSize + startingIndexValue;
      }

      for (let index = startingIndexValue; index < futureVideoAmount; index++) {
        if (this.state.videoItems[index]) {
          activeVideos.push(this.state.videoItems[index]);
        }
      }
    }

    let currentSize = {
      width: ui.videoSize.width,
      height: ui.videoSize.height,
    };

    if (device.usesPairing && device.isVZSTB && activeVideos.length > 0) {
      // return <div>{foo}</div>;
      const currentVal = currentVideoKey ? currentVideoKey : -1;
      return (
        <div ref={this.vcWapper} className={styles.videoWrapper}>
          <StbVideoPlayer
            size={currentSize}
            videoItems={this.state.videoItems}
            currentVideoKey={currentVideoKey}
            videoId={currentVal}
            bookId={resources.resourceId}
            onVideoEnd={onVideoEnd}
            onVideoStart={onVideoStart}
            onFinalVideo={onFinalVideo}
            isFinalVideo={isFinalVideo}
            history={history}
          />
        </div>
      );
    } else {
      return (
        <div ref={this.vcWapper} className={styles.videoWrapper}>
          {activeVideos.map((video, index) => {
            const idVal = video ? video.id : -1;
            const currentVal = currentVideoKey ? currentVideoKey : -1;
            if (currentVal <= idVal || idVal >= 0) {
              return (
                <VideoPlayerData
                  key={video.id}
                  currentVideoKey={currentVideoKey}
                  paths={video}
                  isPlaying={video.id === currentVideoKey}
                  onVideoEnd={onVideoEnd}
                  onVideoStart={onVideoStart}
                  onFinalVideo={onFinalVideo}
                  size={currentSize}
                  isFinalVideo={isFinalVideo}
                  history={history}
                />
              );
            } else {
              return null;
            }
          })}
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  ui: state.ui,
  device: state.device,
  resources: state.resources,
});

export default connect(mapStateToProps)(VideoController);
