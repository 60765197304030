import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { QRCode } from 'react-qrcode-logo';
import styles from './QrCode.module.css';

const portVal = window.location.port !== 80 ? `:${window.location.port}` : null;

class QrCode extends Component {
  static propTypes = {
    className: PropTypes.string,
    size: PropTypes.number,
    showRemoteUrl: PropTypes.bool,
    uid: PropTypes.string.isRequired
  };

  static defaultProps = {
    className: '',
    size: 200,
    showRemoteUrl: false
  };

  getTitle = () => 'Scan the QR below to launch the microphone in your mobile browser'

  render() {
    const {
      className,
      size,
      showRemoteUrl,
      uid
    } = this.props;

    const mobileAudioPath = `${window.location.protocol}//${window.location.hostname}${portVal}/voice/${uid}`;

    return (
      <div className={classNames(styles.qrcodeWrapper, className)}>
        <div className={classNames(styles.qrcodeTitle, styles.qrcodeTitleAdditional)}>{this.getTitle()}</div>
        <div className={styles.qrcodeWrap}>
          <QRCode value={mobileAudioPath} size={size} />
        </div>
        {showRemoteUrl && <div className={styles.remoteUrl}>{mobileAudioPath}</div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { firebase: { auth } } = state;

  return {
    uid: auth.uid
  };
};

export default connect(mapStateToProps, null)(QrCode);
