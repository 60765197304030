import { initialState } from './initialState';
import * as types from './actionTypes';

let audioItemUpdateState = {};

export default (state = initialState, action) => {
  switch (action.type) {
  case types.GENERATE_VIDEO_ITEMS:
    return {
      ...state,
      videoItems: action.payload
    };
  case types.GENERATE_MEDIA_ITEMS:
    return {
      ...state,
      mediaItems: action.payload
    };
  case types.GENERATE_AUDIO_ITEMS:
    return {
      ...state,
      audioItems: action.payload
    };
  case types.GENERATE_TEXTRANGE_ITEMS:
    return {
      ...state,
      textRanges: action.payload
    };
  case types.MEDIA_ITEM_UPDATE:
    return {
      ...state,
      mediaItems: action.payload.mediaItems
    };
  case types.VIDEO_ITEM_UPDATE:
    return {
      ...state,
      videoItems: action.payload.videoItems
    };
  case types.AUDIO_ITEMS_UPDATE:
    return {
      ...state,
      audioItems: action.payload.audioItems
    };
  case types.AUDIO_ITEM_UPDATE:
    audioItemUpdateState = state;
    audioItemUpdateState.audioItems[action.payload.id] = action.payload.audioItem;

    return {
      ...audioItemUpdateState
    };
  case types.BOOKMARK_POSITION_UPDATE:
    return {
      ...state,
      bookmarkPos: action.payload
    };
  case types.CUE_STATUS_UPDATE:
    return {
      ...state,
      status: action.payload
    };
  case types.STORE_CUEITEMS_FROM_STORAGE:
    return {
      ...state,
      mediaItems: action.payload ? action.payload.mediaItems : [],
      videoItems: action.payload ? action.payload.video : [],
      audioItems: action.payload ? action.payload.audio : [],
      textRanges: action.payload ? action.payload.textRanges : [],
      jsonItems: action.payload ? action.payload.json : []
    };
  case types.STORE_CUEITEMS_FROM_FIREBASE:
    return {
      ...state,
      mediaItems: action.payload ? action.payload.mediaItems : [],
      videoItems: action.payload ? action.payload.video : [],
      audioItems: action.payload ? action.payload.audio : [],
      textRanges: action.payload ? action.payload.textRanges : [],
      jsonItems: action.payload ? action.payload.json : []
    };

  case types.RESETTING_CUES:
    return {
      ...initialState
    };
  default:
    return state;
  }
};
