import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { STORAGE } from '../../constants/index';
import { padStart } from '../../helpers';
import styles from './VideoPlayerSimple.module.scss';

class VideoPlayerSimple extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlaying: true,
      isLooping: false,
      duration: '00:00',
      disabled: true,
      currentVideoId: props.videoId,
    };

    this.videoPlayer = React.createRef();
    this.idlePlayer = React.createRef();

    this.videoReady = this.videoReady.bind(this);
    this.videoStarted = this.videoStarted.bind(this);
    this.videoEnded = this.videoEnded.bind(this);
    this.videoTimeUpdate = this.videoTimeUpdate.bind(this);
    this.idleEnded = this.idleEnded.bind(this);

    this.videoPaths = {};

    this.isIdle = false;
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.videoId !== nextProps.videoId) {
      this.setState({ isLooping: false }, () => {
        this.setState({ isPlaying: true });
      });
      //
      let player = this.videoPlayer.current;
      this.isIdle = false;

      const playPromise = player.play();
      if (playPromise !== undefined) {
        playPromise
          .then(_ => {
            player.isPlaying = true;
          })
          .catch(error => {
            // Auto-play was prevented
            // Show paused UI.
          });
      }
    }
  }

  componentDidMount() {
    this.videoPlayer.current.addEventListener('ended', this.videoEnded);
    this.videoPlayer.current.addEventListener('timeupdate', this.videoTimeUpdate);
    this.videoPlayer.current.addEventListener('canplay', this.videoReady);
    this.videoPlayer.current.addEventListener('play', this.videoStarted);
    this.idlePlayer.current.addEventListener('play', this.idleEnded);
  }

  componentWillUnmount() {
    this.videoPlayer.current.removeEventListener('timeupdate', this.videoTimeUpdate);
    this.videoPlayer.current.removeEventListener('ended', this.videoEnded);
    this.videoPlayer.current.removeEventListener('canplay', this.videoReady);
    this.videoPlayer.current.removeEventListener('play', this.videoStarted);
    this.idlePlayer.current.removeEventListener('play', this.idleEnded);
  }

  videoReady(e) {
    console.log('video is ready');
  }

  videoStarted() {
    const { onVideoStart } = this.props;
    // console.log('video has started playing');
    // let playerAnim = this.videoPlayer.current;
    let player = this.videoPlayer.current;

    //idleplayer.load();

    if (!this.isIdle) {
      player.loop = false;
      onVideoStart();
    }
  }

  videoTimeUpdate() {
    const { onVideoEnd } = this.props;
    if (this.videoPlayer && this.videoPlayer.current) {
      const currentTime = this.videoPlayer.current.currentTime;
      const duration = this.videoPlayer.current.duration;
      const timeLeft = duration - currentTime;
      if (timeLeft < 1) {
        onVideoEnd();
      }
    }
  }

  videoEnded() {
    const { onVideoEnd } = this.props;

    onVideoEnd();
    this.setState({ isPlaying: false }, () => {
      this.setState({ isLooping: true });
    });
    this.isIdle = true;
    this.idlePlayer.current.src = this.videoPaths.idlePath;

    this.idlePlayer.current.load();
    this.idlePlayer.current.play();
  }

  idleEnded(event) {
    const { isFinalVideo, onFinalVideo } = this.props;
    console.log('idle video ended', isFinalVideo);
    if (isFinalVideo) {
      onFinalVideo();
    }
  }

  getVideoPaths(index) {
    const { bookId } = this.props;

    let paddedAnimIndex = padStart(index, 3); // String(currentAnimId).padStart(3, 0);
    let animName = paddedAnimIndex;

    let paddedIdleIndex = padStart(index, 3); //String(videoId).padStart(3, 0);
    let idleName = paddedIdleIndex;

    let mediaExt = STORAGE.cfFile;

    let videoPath = 'video/verizon-mix';

    // default path loads file from our s3 bucket via cloudfront
    let currentAnimPath = STORAGE.s3Path + bookId + '/' + videoPath + '/' + animName + '_ani' + mediaExt;
    let currentIdlePath = STORAGE.s3Path + bookId + '/' + videoPath + '/' + idleName + '_idle' + mediaExt;

    let posterPath = STORAGE.fullPath + bookId + STORAGE.delimiter + 'Images' + STORAGE.delimiter + animName + '_ani.png?alt=media';

    const results = { animPath: currentAnimPath, idlePath: currentIdlePath, posterPath: posterPath };
    return results;
  }

  render() {
    const { videoId, videoSize } = this.props;

    const currentVidId = parseInt(videoId, 10);
    this.videoPaths = this.getVideoPaths(currentVidId);

    const nextVidId = parseInt(videoId, 10) + 1;
    const preloadNextVid = this.getVideoPaths(nextVidId);

    // }

    return (
      <div>
        <Helmet>
          <link rel="preload" href={preloadNextVid.animPath} as="video" type="video/mp4" />
          <link rel="preload" href={preloadNextVid.idlePath} as="video" type="video/mp4" />
        </Helmet>
        <div
          className={classNames(styles.videoPlayer, {
            [styles.hidden]: this.state.isLooping,
          })}
        >
          <video src={this.videoPaths.animPath} autoPlay={true} width={videoSize.width} height={videoSize.height} ref={this.videoPlayer} />
        </div>
        <div
          className={classNames(styles.videoPlayer, {
            [styles.hidden]: this.state.isPlaying,
          })}
        >
          <video loop={true} ref={this.idlePlayer} width={videoSize.width} height={videoSize.height} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  videoSize: state.ui.videoSize,
});
export default connect(mapStateToProps)(VideoPlayerSimple);
