import initialState from './initialState';
import * as types from './actionTypes';

export default (state = initialState, action) => {
  switch (action.type) {
  case types.PAIR_GENERATE_CODE:
    return {
      ...state,
      pairCode: action.payload
    };
  case types.PAIR_LOCALSTORAGE_FETCH:
    return {
      ...state,
      userId: action.payload
    };
  case types.PAIR_LOCALSTORAGE_SAVE:
    return {
      ...state,
      pairCode: null,
      userId: action.payload.id,
      isSaved: true
    };
  case types.PAIR_LOCALSTORAGE_ERROR:
    return {
      ...state
    };
  case types.STATUS_UPDATING:
  case types.STATUS_SAVED:
  case types.STATUS_ERROR:
    return {
      ...state,
      status: action.payload
    };
  case types.EVENT_UPDATING:
  case types.EVENT_SET:
  case types.EVENT_ERROR:
    return {
      ...state
    };
  case types.SET_SESSION_STATUS:
    return {
      ...state,
      status: action.payload.status
    };
  default:
    return state;
  }
};
