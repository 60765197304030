import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { updateMediaItem } from '../../redux/modules/cueReducer';
import { Loading, Modal } from '../../components';
import { VIDEO_EVENTS } from '../../constants';
import styles from './VideoPlayer.module.scss';

class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.videoContainer = React.createRef();
    this.animPlayer = React.createRef();
    this.idlePlayer = React.createRef();
    this.state = {
      isPlaying: false,
      isIdle: false,
      duration: '00:00',
      disabled: true,
      currentVideoId: props.videoId,
      isLoading: true,
    };

    this.animReady = this.animReady.bind(this);
    this.animStarted = this.animStarted.bind(this);
    this.animEnded = this.animEnded.bind(this);
  }

  componentDidMount() {
    this.animPlayer.current.addEventListener('ended', this.animEnded);
    this.animPlayer.current.addEventListener('play', this.animStarted);
    this.animPlayer.current.addEventListener('canplay', this.animReady);
  }

  componentWillUnmount() {
    this.animPlayer.current.removeEventListener('ended', this.animEnded);
    this.animPlayer.current.removeEventListener('play', this.animStarted);
    this.animPlayer.current.removeEventListener('canplay', this.animReady);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { paths, currentVideoKey, volumeLevel, isPaused } = this.props;
    // console.log('update', currentVideoKey, prevProps.currentVideoKey, paths.id);
    if (currentVideoKey !== prevProps.currentVideoKey && currentVideoKey === paths.id) {
      const player = this.animPlayer.current;

      this.vidReadyHandler(player);
    } else if (currentVideoKey !== paths.id) {
      if (this.refs && this.idlePlayer.current) {
        // pause playback of both video items if it's not the current active video
        this.idlePlayer.current.pause();
        this.animPlayer.current.pause();
      }
    }

    if (volumeLevel !== prevProps.volumeLevel) {
      this.animPlayer.current.volume = volumeLevel * 0.01;
      this.idlePlayer.current.volume = volumeLevel * 0.01;
    }

    if (isPaused !== prevProps.isPaused && isPaused) {
      if (this.isVideoPlaying(this.animPlayer.current)) {
        this.animPlayer.current.pause();
        this.localAnimPaused = true;
      }
      if (this.isVideoPlaying(this.idlePlayer.current)) {
        this.idlePlayer.current.pause();
        this.localIdlePaused = true;
      }
    }

    if (isPaused !== prevProps.isPaused && !isPaused) {
      if (this.localAnimPaused) {
        this.animPlayer.current.play();
        this.localAnimPaused = false;
      }
      if (this.localIdlePaused) {
        this.idlePlayer.current.play();
        this.localIdlePaused = false;
      }
    }
  }

  isVideoPlaying(player) {
    return !!(player.currentTime > 0 && !player.ended && !player.paused && player.readyState > 2);
  }

  animReady(e) {
    const { currentVideoKey, updateMediaItem, cueItems } = this.props;

    const index = cueItems.mediaItems.findIndex(x => x.event_data === currentVideoKey);
    if (cueItems.mediaItems[index]) {
      cueItems.mediaItems[index].status = VIDEO_EVENTS.READY;
      const payload = {
        mediaItems: cueItems.mediaItems,
      };

      updateMediaItem(payload);
    }
    if (this.refs && !this.state.isIdle) {
      const player = this.animPlayer.current;
      this.vidReadyHandler(player);
      this.setState({ isLoading: false });
    }
  }

  animStarted() {
    const { onVideoStart, updateMediaItem, cueItems, currentVideoKey } = this.props;

    const index = cueItems.mediaItems.findIndex(x => x.event_data === currentVideoKey);
    if (cueItems.mediaItems[index]) {
      cueItems.mediaItems[index].status = VIDEO_EVENTS.PLAYING;
      const payload = {
        mediaItems: cueItems.mediaItems,
      };

      updateMediaItem(payload);
    }
    onVideoStart();
  }

  animEnded(event) {
    const { onVideoEnd, updateMediaItem, cueItems, currentVideoKey } = this.props;

    const filename = event.target.currentSrc.split('/').pop();
    const filenameId = filename.substring(0, 3);
    const index = cueItems.mediaItems.findIndex(x => x.event_data === currentVideoKey);
    if (cueItems.mediaItems[index]) {
      cueItems.mediaItems[index].status = VIDEO_EVENTS.IDLE;
      const payload = {
        mediaItems: cueItems.mediaItems,
      };

      updateMediaItem(payload);
    }
    this.setState({ isIdle: true });
    const player = this.idlePlayer.current;
    this.vidReadyHandler(player);
    if (currentVideoKey === filenameId) {
      onVideoEnd();
    }
  }

  vidReadyHandler(player) {
    const { isPlaying } = this.props;
    if (player && isPlaying) {
      const playPromise = player.play();

      if (playPromise !== undefined) {
        playPromise
          .then(_ => {
            // Autoplay started!
            player.isPlaying = true;
          })
          .catch(error => {
            // Autoplay was prevented.
            // Show a "Play" button so that user can start playback.
          });
      }
    }
  }

  render() {
    const { paths, currentVideoKey, size, volumeLevel } = this.props;
    const showControls = false;

    const fixedVolume = volumeLevel * 0.01;
    return (
      <div
        className={classNames(styles.videoWrapper, {
          [styles.hidden]: currentVideoKey !== paths.id,
        })}
      >
        {this.state.isLoading && (
          <Modal>
            <div className={styles.downloadingVideo}>
              <div>
                <Loading />
              </div>
              <div>Preparing your experience</div>
            </div>
          </Modal>
        )}
        <div
          ref={this.videoContainer}
          className={classNames(styles.videoContainer, {
            [styles.hidden]: this.state.isIdle,
          })}
        >
          <video
            src={paths.animPath}
            ref={this.animPlayer}
            width={size.width}
            height={size.height}
            loop={false}
            controls={showControls}
            playsInline={true}
            preload="auto"
            volume={fixedVolume}
          />
        </div>
        <div
          className={classNames(styles.videoContainer, {
            [styles.hidden]: !this.state.isIdle,
          })}
        >
          <video
            src={paths.idlePath}
            ref={this.idlePlayer}
            width={size.width}
            height={size.height}
            loop={true}
            controls={showControls}
            playsInline={true}
            preload="auto"
            volume={fixedVolume}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateMediaItem,
    },
    dispatch,
  );

const mapStateToProps = state => ({
  cueItems: state.cueItems,
  volumeLevel: state.ui.volumeLevel,
  isPaused: state.ui.isPaused,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VideoPlayer);
