import initialState from './initialState';
import * as types from './actionTypes';

export default (state = initialState, action) => {
  switch (action.type) {
  case types.CLOUDASR_SERVER_PATH_SET:
    return {
      ...state,
      dictateServer: action.payload.dictateServer,
      bookmarkingServer: action.payload.bookmarkingServer
    };
  case types.CLOUDASR_SERVER_SESSION_ID_SET:
    return {
      ...state,
      sessionId: action.payload
    };
  case types.CLOUDASR_CONNECTION_UPDATE:
    return {
      ...state,
      isCloudASRConnected: action.payload
    };
  case types.CLOUDASR_BT_SPEECH_READY:
    return {
      ...state,
      isBTReadyForSpeech: action.payload
    };
  case types.CLOUDASR_SPEECH_READY:
    return {
      ...state,
      isReadyForSpeech: action.payload
    };
  case types.CLOUDASR_LISTENING_UPDATE:
    return {
      ...state,
      isListening: action.payload
    };
  case types.CLOUDASR_SERVER_STATUS:
    return {
      ...state,
      currentServerStatus: action.payload
    };
  case types.CLOUDASR_RESULTS:
    return {
      ...state,
      asrResults: action.payload
    };
  case types.CLOUDASR_OUTPUT_RESULTS:
    return {
      ...state,
      outputResults: action.payload
    };
  case types.CLOUDASR_BOOKMARK_POSITION:
    return {
      ...state,
      bookmarkPos: action.payload
    };
  case types.CLOUDASR_MEDIA_STREAM_STATUS:
    return {
      ...state,
      mediaStreamCreated: action.payload
    };
  case types.CLOUDASR_PROCESSED_MEDIASCAPE:
    return {
      ...state,
      processedMediascape: action.payload
    };
  case types.CLOUDASR_MUTED_UPDATE:
    return {
      ...state,
      isMuted: action.payload
    };
  case types.CLOUDASR_BOOKMARK_TEXT_BLOCK_UPDATE:
    return {
      ...state,
      bookmarkTextBlock: action.payload
    };
  case types.RESETTING_SPEECH:
    return {
      ...initialState
    };
  default:
    return state;
  }
};
