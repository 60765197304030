import * as types from './actionTypes';

// system notification
export const updateNotification = payload => (dispatch, getState) => {
  dispatch({
    type: types.UPDATE_NOTIFICATIONS,
    payload: payload.message,
  });
};

// stores the display resolution the video should be in.
export const updateVideoSize = payload => (dispatch, getState) => {
  dispatch({
    type: types.VIDEO_SIZE_UPDATE,
    payload: payload,
  });
};

// the current state of listening based on mediascape actions of user interaction
export const updateMicrophone = payload => (dispatch, getState) => {
  dispatch({
    type: types.UPDATE_MICROPHONE,
    payload: payload,
  });
};

// stores the actual state of the audio device
export const updateInputDeviceStatus = payload => (dispatch, getState) => {
  dispatch({
    type: types.UPDATE_INPUTDEVICE_STATUS,
    payload: payload,
  });
};

// stores the id of the audio input device
export const updateInputDevice = payload => (dispatch, getState) => {
  dispatch({
    type: types.UPDATE_INPUTDEVICE,
    payload: payload,
  });
};

// stores the id of the audio output device
export const updateOutputDevice = payload => (dispatch, getState) => {
  dispatch({
    type: types.UPDATE_OUTPUTDEVICE,
    payload: payload,
  });
};

// toggles the active mediascape to full screen
export const updateFullScreen = payload => (dispatch, getState) => {
  dispatch({
    type: types.UPDATE_FULLSCREEN,
    payload: payload,
  });
};

// toggles the display of the debugging screen
export const updateDebugMode = payload => (dispatch, getState) => {
  dispatch({
    type: types.UPDATE_DEBUGMODE,
    payload: payload,
  });
};

// TODO: rename isPlaying to isMediascapeStarted
// toggles the current play state
export const updatePlaying = payload => (dispatch, getState) => {
  dispatch({
    type: types.UPDATE_PLAYING,
    payload: payload,
  });
};

// toggles the current pause state
export const updatePause = payload => (dispatch, getState) => {
  dispatch({
    type: types.UPDATE_PAUSE,
    payload: payload,
  });
};

// toggles the current pause state
export const updateSkipBack = payload => (dispatch, getState) => {
  dispatch({
    type: types.UPDATE_SKIP_BACK,
    payload: payload,
  });
};

// updates the state of the drawer to control if the drawer is open or closed and which drawer is shown when open
export const updateDrawer = payload => (dispatch, getState) => {
  dispatch({
    type: types.UPDATE_DRAWER,
    payload: payload,
  });
};

// toggles the display of the top and bottom menu shelves
export const updateShelfStatus = payload => (dispatch, getState) => {
  dispatch({
    type: types.UPDATE_SHELF_STATUS,
    payload: payload,
  });
};

// updates the master volume level for video and audio
export const updateVolumeValue = payload => (dispatch, getState) => {
  dispatch({
    type: types.UPDATE_AUDIO_VOLUME,
    payload: payload,
  });
};

export const resetUI = payload => (dispatch, getState) => {
  dispatch({
    type: types.RESETTING_UI,
  });
};

export const rateMediascape = payload => (dispatch, getState, getFirebase) => {
  const firebase = getFirebase();
  firebase
    .update(`mediascapeRatings/byUser/${payload.userId}`, {
      [payload.resourceId]: payload.rating,
    })
    .then(response => {
      dispatch({
        type: types.RATE_MEDIASCAPE,
      });
    });
  firebase.update(`mediascapeRatings/byResource/${payload.resourceId}/${payload.userId}`, {
    rating: payload.rating,
    date: Date.now(),
  });
};

export const imageLoaded = payload => (dispatch, getState) => {
  const state = getState();
  const imagesLoaded = state.ui.imagesLoaded || 0;

  dispatch({
    type: types.ALL_IMAGES_LOADED,
    payload: imagesLoaded + 1
  })
}
