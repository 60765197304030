import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { updateMutedStatus } from '../../redux/modules/speechReducer';
import { logStatus } from '../../redux/modules/userReducer';
import styles from './ScreenText.module.scss';

class ScreenText extends Component {
  state = {
    isMuted: false,
    activeStart: -1,
    activeStop: -1,
    activeText: [],
    activeIndex: -1,
    currentIdleId: -1,
    showText: false
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);

    const {
      textRanges
    } = this.props;

    if (this.shouldInitializeActiveState()) {
      this.setState({
        activeStart: parseInt(textRanges[0].start),
        activeStop: parseInt(textRanges[0].stop),
        activeText: textRanges[0].text,
        activeIndex: 0
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  shouldInitializeActiveState = () => {
    const {
      currentWord,
      textRanges
    } = this.props;
    const {
      activeStart,
      activeStop
    } = this.state;

    if (
      currentWord === -1
      && (
        activeStart !== parseInt(textRanges[0].start)
        || activeStop !== parseInt(textRanges[0].stop)
      )
    ) {
      return true;
    }

    return false;
  }

  updateDimensions = () => {
    this.scrollIntoView(styles.nextWord);
  }

  scrollIntoView = (selector, timeout = 0) => {
    const behavior = true ? 'smooth' : 'instant';
    const el = document.querySelector(`.${selector}`);
    if (el) {
      setTimeout(() => {
        el.scrollIntoView({ behavior });
      }, timeout);
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      currentWord,
      isVideoReady,
      logStatus,
      textRanges,
      ui,
      updateMutedStatus
    } = this.props;
    if (currentWord > -1) {
      console.log(this.props.asrWords[currentWord])
    }
    // console.log(currentWord);

    const timeout = 0;
    const payload = {
      code: ui.isPlaying ? 'playBackStarted' : 'playBackStopped',
      shouldListen: false
    };

    if (!this.state.showText && isVideoReady && currentWord < this.state.activeStop - 1) {
      this.setState({
        showText: true
      });
    }

    if (this.state.showText && currentWord >= this.state.activeStop - 1) {
      this.setState({
        showText: false
      });
    }

    if (currentWord >= this.state.activeStop - 1) {
      const newIndex = this.state.activeIndex + 1;

      if (newIndex <= textRanges.length - 1) {
        setTimeout(() => {
          this.setState({
            activeStart: parseInt(textRanges[newIndex].start),
            activeStop: parseInt(textRanges[newIndex].stop),
            activeText: textRanges[newIndex].text,
            activeIndex: newIndex
          });
        }, 2000);
      }
    }

    if (this.state.showText) {
      this.scrollIntoView(styles.nextWord, timeout);

      if (this.state.isMuted) {
        this.setState({
          isMuted: false
        },
        updateMutedStatus(false)); // dictate.unmute()

        payload.shouldListen = true;

        logStatus(payload);
      }
    } else if (!this.state.isMuted) {
      this.setState({
        isMuted: true
      },
      updateMutedStatus(true)); // dictate.mute()

      payload.shouldListen = false;

      logStatus(payload);
    }
  }

  render() {
    const { currentWord, resourceId, ui } = this.props;

    const parsedText = this.state.activeText;
    let wordPosition = -1;

    if (parsedText) {
      // style={ui.videoSize}
      return (
        <div className={styles.textPlacement}>
          <div
            className={classNames(styles.TextContainer, {
              [styles.visible]: this.state.showText,
              [styles.enchant]: resourceId === '-LM8fcOpGz5KAdEIrjgi' || resourceId === '-LSqRIL6qK00d21pyuYi',
              [styles.shelvesVisible]: ui.isShelfActive
            })}
          >
            <div
              className={classNames(styles.readingWords, {
                [styles.shelvesVisible]: ui.isShelfActive
              })}
            >
              {parsedText.map((word, wordIndex) => {
                wordPosition = wordIndex + this.state.activeStart;
                const isReadWord = wordPosition < currentWord;
                const isActiveWord = wordPosition === currentWord;
                const nextWordToRead = wordPosition === currentWord + 1;
                const useSeparator = !!word.separator;
                return (
                  <div
                    key={wordIndex}
                    className={classNames(styles.wordItem, {
                      [styles.readWord]: isReadWord,
                      [styles.activeWord]: isActiveWord,
                      [styles.nextWord]: nextWordToRead,
                      [styles.usesSeparator]: useSeparator
                    })}
                  >
                    {word.displayWord}
                    {word.separator}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    updateMutedStatus,
    logStatus
  },
  dispatch,
);

const mapStateToProps = state => ({
  ui: state.ui,
  currentWord: state.cueItems.bookmarkPos,
  isSTB: state.device.isVZSTB,
  textRanges: state.cueItems.textRanges,
  asrWords: state.resources.asrWords
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScreenText);
