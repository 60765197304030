import React, { Component } from 'react';
import { connect } from 'react-redux';
import Drawer from './Drawer';
import Settings from './Settings';
import About from './About';
import Share from './Share';
import styles from './DrawerManager.module.scss';

class DrawerManager extends Component {
  render() {
    const { ui, match } = this.props;

    const isVisible = !!ui.drawerItem;

    return (
      <div className={styles.drawerManager}>
        <Drawer visible={isVisible}>
          {ui.drawerItem === 'settings' && <Settings />}
          {ui.drawerItem === 'about' && <About />}
          {ui.drawerItem === 'share' && <Share match={match} />}
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ui: state.ui,
});

export default connect(mapStateToProps)(DrawerManager);
