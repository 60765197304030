import React, { Component } from 'react';
import { compose, bindActionCreators } from 'redux';
import { firebaseConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { updateBookmarkPos } from '../../redux/modules/cueReducer';
import { updateAppASR } from '../../redux/modules/deviceReducer';

class BookmarkManager extends Component {
  componentWillReceiveProps(nextProps) {
    const { speech, updateBookmarkPos, bookmarkPos, pair, updateAppASR, device } = this.props;

    const isVerizon = device.isVZSTB;
    if (speech.bookmarkPos !== nextProps.speech.bookmarkPos) {
      updateBookmarkPos(nextProps.speech.bookmarkPos);
    }
    if (pair.userId) {
      if (bookmarkPos !== nextProps.bookmarkPos) {
        if (!isVerizon) {
          updateAppASR(true);
        }
        updateBookmarkPos(nextProps.bookmarkPos);
      }
    }
  }

  render() {
    return <div />;
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateBookmarkPos,
      updateAppASR,
    },
    dispatch,
  );

const mapStateToProps = state => ({
  speech: state.speech,
  pair: state.pair,
  device: state.device,
});

const enhance = compose(
  firebaseConnect(props => [
    {
      path: `webclient/session/${props.pair.userId}/bookmarkPosition`,
      storeAs: 'bookmarkPos',
    },
  ]),
  connect(
    // Map redux state to component props
    ({ firebase: { data, profile } }) => ({
      bookmarkPos: data.bookmarkPos,
      profile,
    }),
    mapDispatchToProps,
  ),
);

export default connect(mapStateToProps)(enhance(BookmarkManager));
