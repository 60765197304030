import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { QRCode } from 'react-qrcode-logo';
import Select from '../Select/Select';
import { updateInputDevice, updateOutputDevice } from '../../redux/modules/uiReducer';
import styles from './Settings.module.scss';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputDevices: [],
      outputDevices: [],
      activeInput: '',
      activeOutput: '',
      isSampleActive: false,
      showQR: false,
    };
    this.audioPlayer = React.createRef();
    this.deviceErrors = null;
    this.sampleAudioPath = 'https://s3.amazonaws.com/storage.noveleffect.com/mediascapes/nfx_startup_chime.mp3';

    this.gotDevices = this.gotDevices.bind(this);
    this.sampleEnded = this.sampleEnded.bind(this);
    this.sampleStarted = this.sampleStarted.bind(this);
  }

  componentDidMount() {
    navigator.mediaDevices
      .enumerateDevices()
      .then(this.gotDevices)
      .catch(this.errorCallback);

    this.audioPlayer.current.addEventListener('ended', this.sampleEnded);
    this.audioPlayer.current.addEventListener('play', this.sampleStarted);
  }

  componentWillUnmount() {
    this.audioPlayer.current.removeEventListener('ended', this.sampleEnded);
    this.audioPlayer.current.removeEventListener('play', this.sampleStarted);
  }
  gotDevices(deviceInfos) {
    const { updateInputDevice, updateOutputDevice } = this.props;
    // console.log('deviceInfos', deviceInfos);

    let inputDevices = [];
    let outputDevices = [];
    let activeInput;
    let activeOutput;
    deviceInfos.forEach(item => {
      let myItem = item;
      if (item.kind === 'audioinput') {
        if (item.label.startsWith('Default')) {
          myItem.default = true;
          activeInput = item.deviceId;
        } else {
          myItem.default = false;
        }
        inputDevices.push(myItem);
      }

      if (item.kind === 'audiooutput') {
        if (item.label.startsWith('Default')) {
          myItem.default = true;
          activeOutput = item.deviceId;
        } else {
          myItem.default = false;
        }
        outputDevices.push(myItem);
      }
    });

    // add external mobile device as an option
    const mobileItem = {
      deviceId: 'mobile',
      label: 'Connect with mobile device',
    };

    inputDevices.push(mobileItem);

    this.setState({ inputDevices, outputDevices });
    updateInputDevice(activeInput);
    updateOutputDevice(activeOutput);
  }

  handleInputChange(value) {
    const { updateInputDevice } = this.props;
    if (value === 'mobile') {
      this.setState({ showQR: true });
    } else {
      this.setState({ showQR: false });
    }
    updateInputDevice(value);
  }

  handleOutputChange(value) {
    const { updateOutputDevice } = this.props;
    updateOutputDevice(value);
    this.audioPlayer.current.setSinkId(value);
  }

  errorCallback(err) {
    console.log('err', err);
  }

  playSampleAudio() {
    this.audioPlayer.current.play();
  }

  sampleEnded() {
    this.setState({ isSampleActive: false });
  }

  sampleStarted() {
    this.setState({ isSampleActive: true });
  }

  render() {
    const { ui, uid } = this.props;
    let currentOutputLabel = ui.outputDeviceId;
    this.state.outputDevices.forEach(device => {
      if (device.default) {
        currentOutputLabel = device.label;
      }
    });

    const portVal = window.location.port !== 80 ? ':' + window.location.port : null;
    //${window.location.hostname}
    const mobileAudioPath = `${window.location.protocol}//${window.location.hostname}${portVal}/voice/${uid}`;
    return (
      <div className={styles.settingsDrawer}>
        <h3>Microphone - Input</h3>
        <div>
          {/* {this.state.inputDevices.map(device => (
            <div key={device.deviceId}>{device.label}</div>
          ))} */}

          <Select
            onChange={input => this.handleInputChange(input.target.value)}
            value={ui.inputDeviceId}
            name="device-input"
            clearable={true}
            label="Audio Input Source"
          >
            {this.state.inputDevices.map(device => (
              <option key={device.deviceId} value={device.deviceId}>
                {device.label}
              </option>
            ))}
          </Select>
        </div>
        {this.state.showQR && (
          <div>
            <div>Scan the QR code to pair with a microphone enabled device.</div>
            <div className={styles.qrcodeWrap}>
              <QRCode value={mobileAudioPath} size={200} />
            </div>
            <div className={styles.remoteUrl}>{mobileAudioPath}</div>
          </div>
        )}
        <h3>Audio - Output</h3>
        <div>
          currently set to {currentOutputLabel}
          {/* <Select
            onChange={input => this.handleOutputChange(input.target.value)}
            value={ui.outputDeviceId}
            name="device-input"
            clearable={true}
            label="Audio Input Source"
          >
            {this.state.outputDevices.map(device => (
              <option key={device.deviceId} value={device.deviceId}>
                {device.label}
              </option>
            ))}
          </Select> */}
        </div>
        <div>
          <audio ref={this.audioPlayer} controls={false}>
            <source src={this.sampleAudioPath} />
          </audio>
        </div>
        <div className={styles.playText} onClick={() => this.playSampleAudio()}>
          <div className={styles.playIconContainer}>
            <svg xmlns="http://www.w3.org/2000/svg" width="22.571" height="25.096" viewBox="0 0 22.571 25.096">
              <g id="play2" transform="translate(-170.201 -602.31)">
                <g id="Group_1687" data-name="Group 1687" transform="translate(93.673 -106.983)">
                  <path
                    id="Path_1281"
                    d="M47.371 11.587L27.8.5v22.1z"
                    className={classNames(styles.playIcon, {
                      [styles.active]: this.state.isSampleActive,
                    })}
                    data-name="Path 1281"
                    transform="translate(50.228 710.293)"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div
            className={classNames(styles.playLabel, {
              [styles.active]: this.state.isSampleActive,
            })}
          >
            Test sound
          </div>
        </div>
        <h3>Contact us</h3>
        <div>
          If you run into any issues, please <a href="mailto:info@noveleffect.com">send us an email</a>.
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateInputDevice,
      updateOutputDevice,
    },
    dispatch,
  );

// const mapStateToProps = (state, ownProps) => ({
//   ui: state.ui,
//   sesstionId: state.speech.sessionId,
// });

export default connect(
  ({ firebase: { auth }, ui, speech }) => ({
    uid: auth.uid,
    ui: ui,
    sessionId: speech.sessionId,
  }),
  mapDispatchToProps,
)(Settings);
