import request from 'request';
import { STORAGE } from '../../../constants/index';
import { padStart } from '../../../helpers';
import * as types from './actionTypes';
import DB_CONFIG from '../../../config/firebase';

const downloadData = url => {
  return new Promise(function(resolve) {
    var xhr = new XMLHttpRequest();
    xhr.open('get', url);
    xhr.timeout = 15000;
    xhr.responseType = 'arraybuffer';
    xhr.onload = function() {
      // const buff = new Uint8Array(xhr.response);
      // if (buff && buff.length === 0) {
      //   console.log('empty buffer');
      // }
      resolve(new Uint8Array(xhr.response));
    };
    xhr.onerror = err => {
      console.warn(err);
    };
    xhr.ontimeout = e => {
      // XMLHttpRequest timed out try again.
      // console.log('timed out trying again.');
      return downloadData(url);
    };
    xhr.send();
  });
};

const loadSources = (sources, videoId) => {
  return new Promise((resolve, reject) => {
    const downloadPromises = [
      Promise.all(
        Object.keys(sources).map(key => {
          // const dataLoaded = !sources[key].ani_data;
          const validRange = parseInt(key) >= videoId - 1 && parseInt(key) <= videoId + 2;
          const alreadyDownloaded = !!sources[key].ani_data;
          if (validRange) {
            if (!alreadyDownloaded) {
              // console.log(`downloading animation data for ${key}`);
              return downloadData(sources[key].ani_url).then(function(data) {
                sources[key].ani_data = data;
                return sources[key];
              });
            } else {
              console.log(`returning previously downloaded animation data for ${key}`);
              return sources[key];
            }
          } else {
            sources[key].ani_data = false;
            return sources[key];
          }
        }),
      ),
      Promise.all(
        Object.keys(sources).map(key => {
          // const dataLoaded = !sources[key].loop_data;
          const validRange = parseInt(key) >= videoId - 1 && parseInt(key) <= videoId + 2;
          const alreadyDownloaded = !!sources[key].loop_data;
          if (validRange) {
            if (!alreadyDownloaded) {
              return downloadData(sources[key].loop_url).then(function(data) {
                console.log(`downloading loop data for ${key}`);
                sources[key].loop_data = data;
                return sources[key];
              });
            } else {
              console.log(`returning previously downloaded loop data for ${key}`);
              return sources[key];
            }
          } else {
            sources[key].loop_data = false;
            return sources[key];
          }
        }),
      ),
    ];

    Promise.all(downloadPromises).then(data => {
      resolve(data[0]);
    });
  });
};

export const hydrateCorpusTextFromFirebase = (payload, mediaItems = [], isVerizon = false) => (dispatch, getState, getFirebase) => {
  const firebase = getFirebase();
  const mediaPath = payload.soundscapeid ? '/packagedMedia/v1/en-us' : '/nfxMedia/v1/en-us';
  const whichKey = payload.soundscapeid ? payload.soundscapeid : payload.resourceid;

  dispatch({
    type: types.FETCH_CORPUS_FROM_FIREBASE,
  });

  const refPath = `${mediaPath}/${whichKey}/onlinePackageData/corpus`;

  return firebase
    .database()
    .ref(refPath)
    .once('value')
    .then(function(snapshot) {
      const corpusItems = snapshot.val();

      if (corpusItems) {
        let asrItems = [];
        let displayItems = [];

        corpusItems.words.forEach(item => {
          if (item.asrWord) {
            asrItems.push(item.asrWord);
          }
          if (item.displayWord) {
            displayItems.push({ displayWord: item.displayWord, separator: item.separator });
          }
        });
        const payload = {
          corpus: corpusItems.display,
          asrWords: asrItems,
          displayWords: displayItems,
        };

        dispatch({
          type: types.STORE_CORPUS_FROM_FIREBASE,
          payload: payload,
        });
      }
    });
};

export const hydrateResourceDataFromFirebase = (payload, mediaItems = []) => (dispatch, getState, getFirebase) => {
  const firebase = getFirebase();
  const mediaPath = payload.soundscapeid ? '/packagedMedia/v1/en-us' : '/nfxMedia/v1/en-us';
  const whichKey = payload.soundscapeid ? payload.soundscapeid : payload.resourceid;

  dispatch({
    type: types.FETCH_RESOURCEITEMS_FROM_FIREBASE,
  });

  const refPath = `${mediaPath}/${whichKey}/onlinePackageData/artifacts`;

  return firebase
    .database()
    .ref(refPath)
    .once('value')
    .then(function(snapshot) {
      const artifacts = snapshot.val();

      // parse audio resources
      let audioItems = {};
      if (artifacts && artifacts.audio) {
        let dlPath = payload.soundscapeid ? `${STORAGE.packagedMediaPath}${payload.soundscapeid}%2Fartifacts` : `${STORAGE.s3Path}${payload.resourceid}`;
        Object.keys(artifacts.audio).forEach(key => {
          const shortKey = artifacts.audio[key];
          const finalDLPath = payload.soundscapeid ? `${dlPath}%2Faudio%2F${shortKey}.mp3?alt=media` : `${dlPath}/audio/${shortKey}.mp3`;
          audioItems[shortKey] = {
            filename: shortKey,
            status: 'stopped',
            downloadPath: finalDLPath,
          };
        });
      }

      // parse video cutscenes resources
      let cutscene = {};

      if (artifacts && artifacts.video && artifacts.video.cutscene) {
        let dlPath = payload.soundscapeid ? `${STORAGE.packagedMediaPath}${payload.soundscapeid}%2Fartifacts` : `${STORAGE.s3Path}${payload.resourceid}`;
        Object.keys(artifacts.video.cutscene).forEach(key => {
          const shortKey = artifacts.video.cutscene[key];
          const finalDLPath = payload.soundscapeid ? `${dlPath}%2Fvideo%2Fcutscene%2F${shortKey}.mp4?alt=media` : `${dlPath}/video/cutscene/${shortKey}.mp4`;
          cutscene[shortKey] = {
            key: shortKey,
            name: shortKey,
            downloadPath: finalDLPath,
          };
        });
      }

      // parse video idle resources
      let idle = {};

      if (artifacts && artifacts.video && artifacts.video.idle) {
        let dlPath = payload.soundscapeid ? `${STORAGE.packagedMediaPath}${payload.soundscapeid}%2Fartifacts` : `${STORAGE.s3Path}${payload.resourceid}`;
        Object.keys(artifacts.video.idle).forEach(key => {
          const shortKey = artifacts.video.idle[key];
          const finalDLPath = payload.soundscapeid ? `${dlPath}%2Fvideo%2Fidle%2F${shortKey}.mp4?alt=media` : `${dlPath}/video/idle/${shortKey}.mp4`;
          idle[shortKey] = {
            key: shortKey,
            name: shortKey,
            downloadPath: finalDLPath,
          };
        });
      }

      const parsedResource = {
        audioItems,
        videoItems: { cutscene, idle },
        mediaComplete: Object.keys(cutscene).length > 0,
      };

      if (true) {
        dispatch({
          type: types.STORE_RESOURCEITEMS_FROM_FIREBASE,
          payload: parsedResource,
        });
      }
    });
};

export const getResourceDataFromStorage = (payload, isWip = false, mediaItems = [], isVerizon = false) => (dispatch, getState) => {
  const mediaPath = isWip ? STORAGE.packagedMediaPath : STORAGE.nfxMediaPath;
  const whichKey = isWip ? payload.soundscapeid : payload.resourceid;
  let nfxMediaURL = `${mediaPath}${whichKey}${STORAGE.delimiter}${whichKey}-NFXData.json?alt=media`;
  if (isVerizon) {
    nfxMediaURL = `${STORAGE.cfPath}${payload.resourceid}/json/${payload.soundscapeid}-NFXData.json`;
  }

  dispatch({
    type: types.FETCH_RESOURCEITEMS_FROM_STORAGE,
  });

  request.get(nfxMediaURL, (err, httpResponse, body) => {
    if (httpResponse) {
      const parsed = JSON.parse(httpResponse.body);
      const resources = parsed.artifacts;

      // parse audio resources
      let audioItems = {};
      if (resources && resources.audio) {
        Object.keys(resources.audio).forEach(key => {
          const shortKey = key.replace(/\.[^/.]+$/, '');
          audioItems[shortKey] = {
            filename: shortKey,
            status: 'stopped',
            downloadPath: `${STORAGE.cfPath}${payload.resourceid}/audio/${key}`,
          };
        });
      }
      // parse video resources
      let videoItems = {};
      if (resources && resources.video) {
        Object.keys(resources.video).forEach(key => {
          const shortKey = key.replace(/\.[^/.]+$/, '');
          videoItems[shortKey] = {
            key: shortKey,
            name: shortKey,
            downloadPath: `${STORAGE.cfPath}${payload.resourceid}/video/${key}`,
          };
        });
      }

      if (mediaItems.length > 0) {
        mediaItems.forEach(cue => {
          const animkey = `${cue.event_data}_ani`;
          videoItems[animkey] = {
            key: animkey,
            name: animkey,
            downloadPath: `${STORAGE.cfPath}${payload.resourceid}/video/${animkey}.mp4`,
          };
          const idlekey = `${cue.event_data}_idle`;
          videoItems[idlekey] = {
            key: idlekey,
            name: idlekey,
            downloadPath: `${STORAGE.cfPath}${payload.resourceid}/video/${idlekey}.mp4`,
          };
        });
      }

      const parsedResource = {
        audioItems,
        videoItems,
        corpus: parsed.displayText,
        mediaComplete: Object.keys(videoItems).length > 0,
      };

      if (resources || mediaItems.length > 0) {
        dispatch({
          type: types.STORE_RESOURCEITEMS_FROM_STORAGE,
          payload: parsedResource,
        });
      }
    }
  });
};

export const prepareVideoData = (sources, videoId) => (dispatch, getState) => {
  dispatch({
    type: types.VIDEO_DOWNLOAD_START,
  });

  loadSources(sources, videoId).then(results => {
    // console.log(results);
    dispatch({
      type: types.VIDEO_DOWNLOAD_COMPLETED,
      payload: results,
    });
  });
};

const getAudioPaths = audioPath => {
  let urlParams = new URLSearchParams(window.location.search);
  let myParam = urlParams.get('media');
  const fixedPath = audioPath.replace(/\//g, STORAGE.delimiter);
  let downloadPath = `${STORAGE.cfPath}${audioPath}`;

  if (myParam === 'firebase') {
    downloadPath = STORAGE.partialPath + 'unity%2F' + fixedPath + '?alt=media';
  }
  return downloadPath;
};

const getVideoPaths = (videoPath, isAdmin = false) => {
  let urlParams = new URLSearchParams(window.location.search);
  let myParam = urlParams.get('media');

  const fixedVidePath = videoPath.replace(/\//g, STORAGE.delimiter);
  let downloadPath = `${STORAGE.cfPath}${videoPath}`;

  if (myParam === 'firebase') {
    downloadPath = STORAGE.partialPath + 'unity%2F' + fixedVidePath + '?alt=media';
  }
  if (isAdmin) {
    downloadPath = `${STORAGE.s3Path}${videoPath}`;
  }
  return downloadPath;
};

const getVideoDataPaths = (filename, resourceId, isIdle = false) => {
  let downloadPath = `${STORAGE.cfPath}${resourceId}/video/${filename}`;
  const isDev = false; //process.env.NODE_ENV === 'development';

  if (isDev) {
    const folder = isIdle ? '%2Fartifacts%2Fidle%2F' : '%2Fartifacts%2Fvideo%2F';
    downloadPath = STORAGE.partialPath + 'studio%2Fsoundscape%2F' + resourceId + folder + filename + '?alt=media';
  }
  return downloadPath;
};

export const setActiveResource = payload => (dispatch, getState) => {
  dispatch({
    type: types.RESOURCE_ACTIVE_SET,
    payload: payload,
  });
};

export const setLooping = payload => (dispatch, getState) => {
  dispatch({
    type: types.LOOPING_SET,
    payload: payload,
  });
};

export const updateAudioItem = payload => (dispatch, getState) => {
  // console.log('updateAudioItem', payload);
  dispatch({
    type: types.AUDIO_ITEM_UPDATING,
    payload: payload,
  });
};

export const updateAudioItems = payload => (dispatch, getState) => {
  console.log('updateAudioItem', payload);
  dispatch({
    type: types.AUDIO_ITEMS_UPDATING,
    payload: payload,
  });
};

export const setMutedValue = payload => (dispatch, getState) => {
  dispatch({
    type: types.AUDIO_SET_MUTE,
    payload: payload,
  });
};

export const fetchAudioListFromData = payload => (dispatch, getState) => {
  dispatch({
    type: types.AUDIO_LIST_FETCH,
  });
  let parsedObject = {};

  Object.keys(payload).forEach(key => {
    if (key) {
      let audioItem = payload[key];
      if (audioItem.name) {
        const removedExt = audioItem.name.replace(/\.[^/.]+$/, '');
        // console.log('key :', key, removedExt);

        const audioObj = {
          filename: removedExt,
          status: 'stopped',
          eventTime: 0,
          isLoop: false,
          downloadPath: audioItem.downloadURL,
        };
        parsedObject[removedExt] = audioObj;
      }
    }
  });
  dispatch({
    type: types.AUDIO_LIST_SET,
    payload: parsedObject,
  });
};

export const fetchAudioList = payload => (dispatch, getState) => {
  dispatch({
    type: types.AUDIO_LIST_FETCH,
  });
  console.log('fetchAudioList');

  const transaction = {
    settings: {
      resourceId: payload.resourceId,
      ext: payload.ext ? payload.ext : 'mp3',
      mediaType: payload.mediaType ? payload.mediaType : 'audio',
    },
  };

  const reqPath = `${process.env.REACT_APP_FIREBASE_FUNCTIONS_PATH}/listFiles?srcBucketName=${DB_CONFIG.storageBucket}&prefix=unity/${
    transaction.settings.resourceId
  }/${transaction.settings.mediaType}/`;

  request.get(reqPath, (err, httpResponse, body) => {
    if (httpResponse) {
      const parsed = JSON.parse(httpResponse.body);
      const files = parsed.files;
      files.shift();
      let removedPath = {};
      files.forEach(item => {
        const fixedPath = item.replace(`unity/${transaction.settings.resourceId}/${transaction.settings.mediaType}/`, '');
        const resourceUri = getAudioPaths(`${transaction.settings.resourceId}/audio/${fixedPath}`);
        const fileExt = fixedPath.split('.').pop();
        if (fileExt === transaction.settings.ext) {
          const removedExt = fixedPath.replace(/\.[^/.]+$/, '');
          const audioObj = {
            filename: removedExt,
            status: 'stopped',
            eventTime: 0,
            isLoop: false,
            downloadPath: resourceUri,
          };
          removedPath[removedExt] = audioObj;
        }
      });

      dispatch({
        type: types.AUDIO_LIST_SET,
        payload: removedPath,
      });
    }
  });
};

export const fetchVideoListFromData = payload => (dispatch, getState) => {
  dispatch({
    type: types.AUDIO_LIST_FETCH,
  });
  let parsedObject = {};

  Object.keys(payload.items).forEach(key => {
    if (key) {
      let videoItem = payload.items[key];
      if (videoItem.name) {
        const removedExt = videoItem.name.replace(/\.[^/.]+$/, '');
        // console.log('key :', key, removedExt);

        const videoObj = {
          key: removedExt,
          name: removedExt,
          isIdle: videoItem.isIdle,
          isInitial: videoItem.isInitial,
          downloadPath: getVideoDataPaths(videoItem.name, payload.resourceId, videoItem.isIdle),
        };

        parsedObject[removedExt] = videoObj;
      }
    }
  });
  dispatch({
    type: types.VIDEO_LIST_SET,
    payload: parsedObject,
  });
};

export const fetchVideoList = payload => (dispatch, getState) => {
  const userAgent = window.navigator.userAgent;
  dispatch({
    type: types.VIDEO_LIST_FETCH,
  });

  if ('MediaSource' in window && MediaSource.isTypeSupported('video/mp4; codecs="avc1.640032, mp4a.40.2"')) {
    dispatch({
      type: types.VIDEO_CODEC_SUPPORT,
      payload: 'mp4 codec supported',
    });
  } else if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
    dispatch({
      type: types.VIDEO_CODEC_SUPPORT,
      payload: 'mp4 codec supported',
    });
  }

  const transaction = {
    settings: {
      resourceId: payload,
    },
  };

  let reqPath = `${process.env.REACT_APP_FIREBASE_FUNCTIONS_PATH}/listFiles?srcBucketName=${DB_CONFIG.storageBucket}&prefix=unity/${
    transaction.settings.resourceId
  }/video/`;

  request.get(reqPath, (err, httpResponse, body) => {
    if (httpResponse) {
      const parsed = JSON.parse(httpResponse.body);
      const files = parsed.files;
      let removedPath = {};

      files.shift();
      const numberOfVids = files.length / 2;
      for (let index = 0; index < numberOfVids; index++) {
        const vidItem = padStart(index, 3);

        const fixedAniPath = `${transaction.settings.resourceId}/video/` + vidItem + '_ani.mp4';
        const fixedIdlePath = `${transaction.settings.resourceId}/video/` + vidItem + '_idle.mp4';

        removedPath[vidItem] = {
          name: vidItem,
          ani_url: getVideoPaths(fixedAniPath),
          loop_url: getVideoPaths(fixedIdlePath),
        };
      }

      dispatch({
        type: types.VIDEO_LIST_SET,
        payload: removedPath,
      });
      const videoCount = Object.keys(removedPath).length;
      if (videoCount > 0) {
        const lastVideoId = videoCount / 2 - 1;

        dispatch({
          type: types.VIDEO_LASTVIDEO_SET,
          payload: lastVideoId,
        });
      }
    }
  });
};

export const fetchImageList = payload => (dispatch, getState) => {
  dispatch({
    type: types.IMAGE_LIST_FETCH,
  });

  const transaction = {
    settings: {
      resourceId: payload,
    },
  };

  const reqPath = `${process.env.REACT_APP_FIREBASE_FUNCTIONS_PATH}/listFiles?srcBucketName=${DB_CONFIG.storageBucket}&prefix=unity/${
    transaction.settings.resourceId
  }/images/`;

  request.get(reqPath, (err, httpResponse, body) => {
    if (httpResponse) {
      const parsed = JSON.parse(httpResponse.body);
      const files = parsed.files;
      let removedPath = [];
      files.forEach(item => {
        const fixedPath = item.replace(`unity/${transaction.settings.resourceId}/images/`, '');
        const fileExt = fixedPath.split('.').pop();
        if (fileExt === 'png') {
          const removedExt = fixedPath.replace(/\.[^/.]+$/, '');
          removedPath.push(removedExt);
        }
      });

      dispatch({
        type: types.IMAGE_LIST_SET,
        payload: removedPath,
      });
    }
  });
};
/* Manually update the video index for testing without reading on the device */
export const updateIndex = payload => (dispatch, getState, getFirebase) => {
  const firebase = getFirebase();
  let userId = payload.userId;
  let eventData = payload.eventData;
  if (userId) {
    dispatch({
      type: types.TEST_EVENT_UPDATE,
    });

    firebase
      .update(`webclient/session/${userId}/event`, {
        ...eventData,
      })
      .then(response => {
        dispatch({
          type: types.TEST_EVENT_COMPLETE,
          payload: response,
        });
      })
      .catch(err => {
        dispatch({
          type: types.TEST_EVENT_ERROR,
          payload: err,
        });
      });
  }
};

export const setSessionStatus = payload => (dispatch, getState) => {
  // console.log(payload);
  dispatch({
    type: types.SESSION_STATUS_UPDATE,
    payload: payload.status,
  });

  dispatch({
    type: types.SESSION_STATUS_COMPLETE,
  });
};

export const resetResources = payload => (dispatch, getState) => {
  dispatch({
    type: types.RESETTING_RESOURCES,
  });
};
