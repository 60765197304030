import React, { Component } from 'react';
import classnames from 'classnames';
import styles from './Select.module.scss';

class Select extends Component {
  render() {
    const { label, disabled = false, onChange, size = 'normal', theme = 'normal', value = '', children, className } = this.props;

    const componentStyle = {
      className: classnames(styles.mdlSelectfield, styles[`theme--${theme}`], styles[`size--${size}`], className),
    };

    return (
      <div {...componentStyle}>
        <label>{label}</label>
        <select className={styles.nfxSelect} value={value} disabled={disabled} onChange={onChange}>
          <option value="" disabled>
            Choose a {label}
          </option>
          {children}
        </select>
      </div>
    );
  }
}

export default Select;
